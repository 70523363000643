import React from "react";

function ArrowDownIcon({ size = 18, fill }) {
  const pathProps = fill ? { fill } : {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 42 42"
    >
      <path
        d="M42,18.375H9.975l14.7-14.7L21,0,0,21,21,42l3.675-3.675-14.7-14.7H42Z"
        transform="translate(0 42) rotate(-90)"
        className="fill"
        {...pathProps}
      />
    </svg>
  );
}

export default ArrowDownIcon;
