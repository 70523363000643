import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { themeStageContainer } from "../utils/theme";

const Comp = styled.div(({ styles }) => {
  return {
    ...styles,
    width: "100%",
    "@media print": {
      display: "none"
    }
  };
});

function StageContainer({ containerStyles, children }) {
  const { resolution } = useViewerClient();

  return (
    <Comp
      styles={{
        ...containerStyles,
        ...themeStageContainer[resolution]
      }}
    >
      {children}
    </Comp>
  );
}

export default StageContainer;
