import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { containerWidthsDefault } from "../utils/theme";

const Comp = styled.div(
  ({ flexBase = "100%", paddingLeft = 0, paddingRight = 0, ...styles }) => ({
    flex: `0 1 ${flexBase}`,
    paddingLeft,
    paddingRight,
    ...styles
  })
);

const ContentWrapper = ({
  styles,
  containerSpacings = containerWidthsDefault,
  children
}) => {
  const { resolution } = useViewerClient();
  const contentProps = containerSpacings[resolution];
  return (
    <Comp {...styles} {...contentProps}>
      {children}
    </Comp>
  );
};

export default ContentWrapper;
