import BlogPost from "../components/BlogPost";
import BlogPostList from "../components/BlogPostList";
import BlogPostHeader from "../components/BlogPostHeader";
import ContentFilter from "../components/ContentFilter";
import Contact from "../components/Contact";
import Process from "../components/Process";

import BlogOverviewHeader from "../containers/BlogOverviewHeader";

import Blog from "../pages/Blog";
import DataProtection from "../pages/DataProtection";
import Faq from "../pages/Faq";
import Home from "../pages/Home";
import Legal from "../pages/Legal";
import SimpleContent from "../pages/SimpleContent";
import Subsidy from "../pages/Subsidy";

export const useComponents = () => ({
  Blog,
  BlogOverviewHeader,
  BlogPost,
  BlogPostList,
  BlogPostHeader,
  ContentFilter,
  Contact,
  DataProtection,
  Faq,
  Home,
  Legal,
  Process,
  SimpleContent,
  Subsidy
});
