import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontStyleH3 } from "../utils/theme";

const Comp = styled.h3(({ resolutionCoarse }) => {
  return {
    fontFamily: fontFamily.normal,
    fontWeight: 600,
    marginTop: "0.5em",
    marginBottom: "0.5em",
    ...fontStyleH3[resolutionCoarse]
  };
});

function H3({ children }) {
  const { resolutionCoarse } = useViewerClient();
  return <Comp resolutionCoarse={resolutionCoarse}>{children}</Comp>;
}

export default H3;
