import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { metaNavLinks } from "../utils/routes";
import {
  colors,
  containerWidthsText,
  contentSpacing,
  fontSizeFooter,
  themePageBar,
  themePageBarText
} from "../utils/theme";
import BundIcon from "./BundIcon";
import Container from "./Container";
import FHHIcon from "./FHHIcon";
import NavLinkItem from "./NavLinkItem";
import SponsorLogoWrapper from "./SponsorLogoWrapper";

const Comp = styled.div({
  "@media print": {
    display: "none"
  }
});
const Title = styled.div({
  paddingTop: "30px",
  paddingBottom: "30px"
});
const ContentContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  paddingBottom: "40px"
});
const SponsorLogoContainer = styled.div(({ isMobile }) => ({
  flex: "0 1 auto",
  display: "flex",
  paddingBottom: "20px",
  marginRight: isMobile ? 0 : "50px"
}));
const MetaNavContainer = styled.ul({
  flex: "0 1 auto",
  display: "flex",
  flexWrap: "wrap",
  padding: "20px 0 0 0",
  margin: 0,
  li: {
    marginRight: "20px"
  },
  "li:last-child": {
    marginRight: 0
  }
});

function Footer({ fwRef }) {
  const { t } = useTranslation();
  const { resolutionCoarse, resolution, isGTETabletP } = useViewerClient();
  return (
    <Comp ref={fwRef}>
      <Container
        theme="medium"
        contentSpacings={containerWidthsText}
        contentStyles={{
          fontSize: fontSizeFooter[resolutionCoarse],
          lineHeight: "24px",
          color: colors.WHITE,
          ".fill": {
            fill: colors.WHITE
          }
        }}
        containerStyles={{ zIndex: 2 }}
      >
        <Title>{t("footer.title")}</Title>
        <ContentContainer>
          <SponsorLogoContainer isMobile={!isGTETabletP}>
            <SponsorLogoWrapper isFirst>
              <BundIcon />
            </SponsorLogoWrapper>
            <SponsorLogoWrapper>
              <FHHIcon />
            </SponsorLogoWrapper>
          </SponsorLogoContainer>
          <MetaNavContainer>
            {metaNavLinks.map(({ key, path, hash }) => (
              <NavLinkItem key={`b_nav_${key}`}>
                <Link to={`/${path}${hash ? `/#${hash}` : ""}`}>
                  {t(`nav.${key}`)}
                </Link>
              </NavLinkItem>
            ))}
          </MetaNavContainer>
        </ContentContainer>
      </Container>
      <Container
        theme="white"
        contentStyles={{
          ...themePageBar,
          ...themePageBarText,
          textAlign: "center",
          paddingLeft: contentSpacing[resolution],
          paddingRight: contentSpacing[resolution],
          zIndex: 2
        }}
        fullWidth
      >
        {t("footer.copyright")}
      </Container>
    </Comp>
  );
}
export default Footer;
