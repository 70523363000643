import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import {
  blogSpacing,
  contentSpacing,
  fontStyleCaption,
  transitionSpeed
} from "../utils/theme";
import PlayIcon from "./PlayIcon";

const getRatio = (picture, { layout, isGTETabletP, isGTETabletL }) => {
  if (layout === "teaser") {
    // fixed ratio
    if (!isGTETabletP) {
      return 119;
    }
    return 53;
  }
  if (layout === "preview") {
    // fixed ratio
    if (isGTETabletL) {
      return 119;
    }
    return 53;
  }
  if (layout === "opener") {
    // fixed ratio
    return 143;
  }

  if (picture) {
    // determine ratio
    return Math.min((picture.height * 100) / picture.width, 133);
  }
  // fallback, should not happen
  return 53;
};

const getPictureUrl = (
  picture,
  { layout, isGTEMobileL, isGTETabletP, isGTETabletL, isGTEDesktop }
) => {
  if (!picture) {
    return null;
  }

  let preferredFormat;
  if (layout === "teaser") {
    if (isGTETabletP) {
      preferredFormat = "xLarge";
    } else if (isGTEMobileL) {
      preferredFormat = "large";
    } else {
      preferredFormat = "small";
    }
  } else if (layout === "preview") {
    if (isGTETabletL) {
      preferredFormat = "medium";
    } else {
      preferredFormat = "small";
    }
  } else if (layout === "opener") {
    preferredFormat = "large";
  } else if (isGTEDesktop) {
    preferredFormat = "large";
  } else if (isGTETabletP) {
    preferredFormat = "xLarge";
  } else if (isGTEMobileL) {
    preferredFormat = "medium";
  } else {
    preferredFormat = "small";
  }

  if (picture.formats[preferredFormat]) {
    return picture.formats[preferredFormat].url;
  }
  return picture.url;
};

const getSizeStyles = ({ isLandscape, hover }) => {
  if (isLandscape) {
    return {
      height: hover ? "105%" : "100%"
    };
  }
  return {
    width: hover ? "105%" : "100%"
  };
};

const Comp = styled.div(({ resolutionCoarse, layout }) => {
  if (layout === "paragraph") {
    return {
      marginBottom: blogSpacing.postVerticalM[resolutionCoarse]
    };
  }
  return {};
});

const PictureFormatWrapper = styled.div(({ ratio, layout, resolution }) => {
  const spacing = {};

  if (resolution === "tabletP") {
    if (layout === "post") {
      spacing.marginLeft = `-${contentSpacing[resolution]}`;
      spacing.marginRight = `-${contentSpacing[resolution]}`;
    }
  } else if (resolution === "mobileP" || resolution === "mobileL") {
    if (layout === "post" || layout === "paragraph") {
      spacing.marginLeft = `-${contentSpacing[resolution]}`;
      spacing.marginRight = `-${contentSpacing[resolution]}`;
    }
  }

  return {
    position: "relative",
    overflow: "hidden",
    paddingTop: `${ratio}%`,
    ...spacing
  };
});

const PictureOuterWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  bottom: 0,
  width: "100%",
  left: 0
}));

const PictureInnerWrapper = styled.div(({ isLandscape, hover, gradient }) => {
  const styles = gradient
    ? {
        background: gradient,
        height: "100%"
      }
    : {};

  const sizeStyles = getSizeStyles({ isLandscape, hover });
  return {
    transition: `${transitionSpeed}`,
    marginLeft: hover ? "-2.5%" : 0,
    height: "100%",
    ...styles,
    ...sizeStyles
  };
});

const Img = styled.img(({ isLandscape, hover, style = {} }) => {
  const sizeStyles = getSizeStyles({ isLandscape, hover });
  return {
    ...style,
    transition: `${transitionSpeed}`,
    ...sizeStyles
  };
});

const Caption = styled.div(({ resolutionCoarse, layout }) => ({
  ...fontStyleCaption[resolutionCoarse],
  paddingRight:
    layout === "post" &&
    resolutionCoarse !== "mobile" &&
    blogSpacing.postHeadlineIndent[resolutionCoarse],
  fontStyle: "italic",
  textAlign: "right"
}));

const PlayIconWrapper = styled.div({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  height: "auto",
  "svg.play-icon": {
    width: "auto",
    height: "auto",
    margin: "-50% 0 0 -50%",
    maxHeight: "50%"
  }
});

const VideoCaption = ({ url }) => {
  let platform;
  if (url) {
    const urlObj = new URL(url);
    if (urlObj && urlObj.hostname) {
      if (urlObj.hostname.match(/youtu/)) {
        platform = "YouTube";
      } else if (urlObj.hostname.match(/vimeo/)) {
        platform = "vimeo";
      } else {
        platform = urlObj.hostname;
      }
    }
  }
  if (!platform) {
    platform = "einer externen Seite";
  }

  return (
    <Trans i18nKey="blog.videoCaption" platform={platform}>
      Spielen Sie dieses Video direkt auf {{ platform }} ab. Eventuell werden
      dort weitere persönliche Daten erhoben.
    </Trans>
  );
};

function CroppedPicture({ layout, hover, picture, link, imgStyle = {} }) {
  const { gradient } = useStyles();
  const {
    resolution,
    resolutionCoarse,
    isGTEMobileL,
    isGTETabletP,
    isGTETabletL,
    isGTEDesktop
  } = useViewerClient();
  const usePlaceholder = layout === "preview" || layout === "opener";
  if (!picture && !usePlaceholder) {
    return null;
  }

  const ratio = getRatio(picture, { layout, isGTETabletP, isGTETabletL });
  const pictureUrl = getPictureUrl(picture, {
    layout,
    isGTEMobileL,
    isGTETabletP,
    isGTETabletL,
    isGTEDesktop
  });
  const isLandscape = ratio > 100 && picture && picture.isLandscape;
  let caption;
  if (link) {
    caption = <VideoCaption url={link} />;
  } else if (
    picture &&
    picture.caption &&
    layout !== "preview" &&
    layout !== "teaser" &&
    layout !== "opener"
  ) {
    caption = picture.caption;
  }

  return (
    <Comp resolutionCoarse={resolutionCoarse} layout={layout}>
      <PictureFormatWrapper
        ratio={ratio}
        layout={layout}
        resolution={resolution}
      >
        <PictureOuterWrapper>
          <PictureInnerWrapper
            hover={hover}
            gradient={pictureUrl ? null : gradient}
            isLandscape={isLandscape}
          >
            {pictureUrl && (
              <>
                {link ? (
                  <a href={link} target="_blank" rel="noreferrer">
                    <Img
                      src={pictureUrl}
                      alt={picture.alternativeText}
                      hover={hover}
                      isLandscape={isLandscape}
                      style={imgStyle}
                    />
                    <PlayIconWrapper>
                      <PlayIcon width={isGTETabletP ? 120 : 60} />
                    </PlayIconWrapper>
                  </a>
                ) : (
                  <Img
                    src={pictureUrl}
                    alt={picture.alternativeText}
                    hover={hover}
                    isLandscape={isLandscape}
                    style={imgStyle}
                  />
                )}
              </>
            )}
          </PictureInnerWrapper>
        </PictureOuterWrapper>
      </PictureFormatWrapper>
      {caption && (
        <Caption resolutionCoarse={resolutionCoarse} layout={layout}>
          {caption}
        </Caption>
      )}
    </Comp>
  );
}

export default CroppedPicture;
