import React from "react";

function FacebookIcon({ fill }) {
  const pathProps = fill ? { fill } : {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.139"
      height="32"
      viewBox="0 0 17.139 32"
    >
      <path
        d="M17.625,18l.889-5.791H12.957V8.451c0-1.584.776-3.129,3.265-3.129h2.526V.391A30.807,30.807,0,0,0,14.264,0C9.688,0,6.7,2.774,6.7,7.8v4.414H1.609V18H6.7V32h6.261V18Z"
        transform="translate(-1.609)"
        {...pathProps}
      />
    </svg>
  );
}

export default FacebookIcon;
