import { filter } from "lodash";
import React from "react";
import styled from "styled-components";
import { useContent } from "../context/content";
import { useViewerClient } from "../context/viewerClient";
import { blogSpacing } from "../utils/theme";
import BlogHeadline from "./BlogHeadline";
import BlogPostTeaser from "./BlogPostTeaser";

const cols = {
  mobile: 1,
  tablet: 2,
  desktop: 3
};

const Comp = styled.div(() => {
  return {
    "a:hover": {
      textDecoration: "none"
    }
  };
});

const ContentWrapper = styled.div(({ resolutionCoarse }) => {
  return {
    display: "grid",
    gridTemplateColumns: `repeat(${cols[resolutionCoarse]}, 1fr)`,
    gridGap: blogSpacing.grid,
    paddingTop: resolutionCoarse === "mobile" ? 0 : "32px"
  };
});

function BlogPostList({ contentPath, layout, postFilter = { type: "blog" } }) {
  const { resolutionCoarse } = useViewerClient();
  const { contentLists } = useContent();

  const contentlist = contentLists ? contentLists[contentPath] : null;
  const posts = contentlist ? filter(contentlist.posts, postFilter) : [];

  return (
    <Comp>
      <BlogHeadline type={postFilter.type} />
      <ContentWrapper resolutionCoarse={resolutionCoarse}>
        {posts.map((post, index) => (
          <BlogPostTeaser
            key={`post_${post.slug || index}`}
            layout={layout}
            {...post}
          />
        ))}
      </ContentWrapper>
    </Comp>
  );
}

export default BlogPostList;
