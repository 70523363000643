import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../utils/theme";
import ArrowDownIcon from "./ArrowDownIcon";

const move = keyframes`
  0% { transform:translateY(0) }
  50% { transform:translateY(-15px) }
`;

const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  animation: ${move} 2.5s linear infinite;
`;

function ArrowDownButton({ onClick = () => {} }) {
  return (
    <Button
      aria-label="Öffnet Seiteninhalt und führt zum Einführungstext"
      onClick={() => onClick()}
    >
      <ArrowDownIcon size={42} fill={colors.WHITE} />
    </Button>
  );
}

export default ArrowDownButton;
