const content = {
  blogIntro: [
    {
      pageHeadline: "Erfahren Sie mehr",
      abstract:
        "Lesen Sie hier Neuigkeiten zum Förderprogramm und interessante Artikel rund um geförderte Projekte, Veranstaltungsorte und die Personen dahinter."
    }
  ],
  blogOverview: [
    {
      component: "BlogOverviewHeader",
      params: {
        contentPath: "blog",
        postIndex: 0,
        postFilter: {
          type: "blog",
          isFeatured: true
        },
        layout: "teaser"
      }
    },
    // {
    //   component: "BlogPostList",
    //   params: {
    //     contentPath: "blog",
    //     layout: "slider",
    //     postFilter: {
    //       type: "update"
    //     }
    //   }
    // },
    {
      component: "BlogPostList",
      params: {
        contentPath: "blog",
        layout: "preview",
        postFilter: {
          type: "blog"
        }
      }
    }
  ],
  contact: [
    {
      component: "Contact"
    }
  ],
  contactFaq: [
    {
      component: "Contact",
      params: {
        hideFaqLink: true
      }
    }
  ],
  dataProtection: [
    {
      headline:
        "Informationen zum Datenschutz nach der EU Datenschutz-Grundverordnung (DS-GVO)",
      columns: [
        {
          headline: "Datenschutzerklärung",
          paragraphs: [
            {
              text: "<p>Die gesundheitspolitisch notwendigen Maßnahmen zur Bekämpfung der Corona-Pandemie haben zu schweren Einschränkungen des kulturellen Lebens in Deutschland geführt. Kulturveranstaltungen konnten über Monate hinweg gar nicht oder nur stark eingeschränkt durchgeführt werden. Auch wenn nach graduellen Lockerungen Kulturveranstaltungen wieder stattfinden können, wird dies zunächst nur mit Hygieneauflagen und verminderten Teilnehmerzahlen möglich sein.</p>"
            },
            {
              text: "<p>Ziel des Sonderfonds des Bundes für Kulturveranstaltungen ist es daher, durch die Corona-Pandemie verursachte Härten für Kulturveranstalter auszugleichen. In einem Verwaltungsverfahren sind Daten personenbezogen, wenn sie einer natürlichen Person zugeordnet werden können. Keine personenbezogenen Daten sind anonymisierte Daten.</p>"
            },
            {
              text: "<p>Die Daten werden nur für den Zweck verwendet, für den sie erhoben wurden. Eine Weitergabe an Dritte erfolgt nicht.</p>"
            },
            {
              text: "<p>Im Folgenden informieren wir Sie darüber, welche personenbezogenen Daten wir erheben, bei wem wir sie erheben und was wir mit diesen Daten machen. Außerdem informieren wir Sie über Ihre Rechte in Datenschutzfragen und an wen Sie sich diesbezüglich wenden können.</p>"
            }
          ]
        }
      ]
    },
    {
      type: "accordion",
      sections: [
        {
          headline: "Wer sind wir?",
          anchor: "wer",
          paragraphs: [
            {
              text: "<p>Das Portal Sonderfonds des Bundes für Kulturveranstaltungen wird von der Freien und Hansestadt Hamburg betrieben und bietet einen sicheren Zugang zur Beantragung von Fördermaßnahmen von Kulturveranstaltungen.</p>"
            },
            {
              text: "<p>Damit wir die gewünschten Leistungen für Sie erbringen können, ist es wie bei vielen Online-Diensten, erforderlich, dass Sie Ihre Daten zur Verfügung stellen. Wir verarbeiten diese Daten nur im Rahmen Ihres Auftrags und verwenden sie nur unter Beachtung der gesetzlichen Bestimmungen (Onlinezugangsgesetz (OZG), Hamburgisches Datenschutzgesetz, EU Datenschutzgrundverordnung (DSGVO), Telekommunikationsgesetz, Telemediengesetz sowie einschlägige Fachgesetze wie beispielsweise das Meldegesetz).</p>"
            }
          ]
        },
        {
          headline: "Wer sind Ihre Ansprechpartner?",
          anchor: "ansprechpartner",
          paragraphs: [
            {
              text: '<p>Fragen in datenschutzrechtlichen Angelegenheiten können Sie an das Amt Behördenmanagement der Behörde für Kultur und Medien, vertreten durch die Amtsleitung, richten:</p><ul><li>Behörde für Kultur und Medien<br/>Amt Behördenmanagement<br/>Große Bleichen 30<br/>20354 Hamburg</li><li>Telefon: (040) 428 24 – 238</li><li>E-Mail: <a href="mailto:Birthe.becker@bkm.hamburg.de">Birthe.becker@bkm.hamburg.de</a></li></ul>'
            },
            {
              text: '<p>Darüber hinaus können Sie sich an den für das Portal zuständigen Datenschutzbeauftragten der Behörde richten:</p><ul><li>Datenschutzbeauftragter der Behörde für Kultur und Medien<br/>Hohe Bleichen 22<br/>20354 Hamburg</li><li>Telefon: (040) 428 24 – 342</li><li>E-Mail: <a href="mailto:datenschutz@bkm.hamburg.de">datenschutz@bkm.hamburg.de</a></li></ul>'
            },
            {
              text: '<p>Falls Sie Fragen zur Registrierung oder Anmeldung haben oder uns Ihre Anregungen oder Hinweise mitteilen möchten, nehmen Sie bitte unter der E-Mail-Adresse <a href="mailto:service@sonderfonds-kulturveranstaltungen.de">service@sonderfonds-kulturveranstaltungen.de</a> Kontakt zu uns auf.</p><ul><li>E-Mail: <a href="mailto:service@sonderfonds-kulturveranstaltungen.de">service@sonderfonds-kulturveranstaltungen.de</a></li></ul>'
            }
          ]
        },
        {
          headline:
            "Warum und zu welchem Zweck verarbeiten wir Ihre personenbezogenen Daten?",
          anchor: "zweck",
          paragraphs: [
            {
              text: "<p>Wir verarbeiten Ihre personenbezogenen Daten, damit Sie über das Portal die mögliche Förderung von Kulturveranstaltungen online in Anspruch nehmen können (Verarbeitungszweck).</p>"
            },
            {
              text: "<p>Im Portal werden Ihre Daten zunächst zur Einrichtung eines Benutzerkontos verarbeitet. Ein Benutzerkonto ist zur Nutzung der Plattform erforderlich. Ihre einmalige Einwilligung ist grundlegend für die Verarbeitung Ihrer Daten.</p>"
            }
          ]
        },
        {
          headline: "Welche personenbezogenen Daten verarbeiten wir?",
          anchor: "welche",
          paragraphs: [
            {
              text: "<p>Ihre personenbezogenen Daten verifizieren Sie anhand Ihres ELSTER Zuganges und übernehmen diese auf die Plattform. Wir verarbeiten insbesondere folgende personenbezogene Daten für Ihr Benutzerkonto und Ihre beantragte Förderung:</p><ol><li>Vorname</li><li>Nachname</li><li>E-Mail-Adresse</li><li>Passwort</li><li>Adresse</li><li>Bankverbindung</li><ol>"
            },
            {
              headline: "Wichtiger Hinweis zum Passwort",
              text: "<p>Halten Sie Ihr Passwort geheim, damit keine unberechtigten Personen Zugang zu Ihren Daten erlangen. Geben Sie Ihr Passwort nicht auf Nachfrage bekannt. Auch die technischen Betreuer des Sonderfonds Kulturförderung sind nicht berechtigt, Ihr Passwort zu erfahren.</p>"
            }
          ]
        },
        {
          headline: "Wie verarbeiten wir diese Daten?",
          anchor: "wie",
          paragraphs: [
            {
              text: "<p>Ihre personenbezogenen Daten werden gespeichert. Sofern Sie an der Plattform angemeldet sind, werden die Daten aus Ihrem Benutzerkonto dazu genutzt, Formularfelder vorauszufüllen, so dass Sie diese Daten nicht erneut eingeben müssen.</p>"
            },
            {
              text: "<p>Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre personenbezogenen Daten gegen unbeabsichtigte oder unrechtmäßige Vernichtung, Verlust oder Veränderung sowie gegen unbefugte Offenlegung oder unbefugten Zugang zu schützen. Unsere Sicherheitsstandards entsprechen stets den aktuellen technologischen Entwicklungen.</p>"
            }
          ]
        },
        {
          headline:
            "Unter welchen Voraussetzungen dürfen wir Ihre Daten an Dritte weitergeben?",
          anchor: "voraussetzungen",
          paragraphs: [
            {
              text: "<p>Ihre persönlichen Daten werden nicht an Dritte weitergegeben.</p>"
            }
          ]
        },
        {
          headline: "Wie lange speichern wir Ihre Daten?",
          anchor: "dauer",
          paragraphs: [
            {
              headline: "Benutzerkonto",
              text: "<p>Wir speichern Ihre Daten in Ihrem Benutzerkonto, solange Sie es nutzen. Nach Ablauf der Fördermaßname Sonderfonds Kulturveranstaltung wird die Plattform stillgelegt und Ihre Daten gelöscht.</p>"
            }
          ]
        },
        {
          headline:
            "Welche Rechte (Auskunftsrecht, Widerspruchsrecht usw.) haben Sie?",
          anchor: "rechte",
          paragraphs: [
            {
              text: "<p>Sie haben nach der Datenschutz-Grundverordnung verschiedene Rechte. Einzelheiten ergeben sich insbesondere aus Artikel 15 bis 18 und 21 der Datenschutz-Grundverordnung.</p>"
            },
            {
              headline: "Recht auf Auskunft",
              text: "<p>Sie können Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen. In Ihrem Auskunftsantrag sollten Sie Ihr Anliegen präzisieren, um uns das Zusammenstellen der erforderlichen Daten zu erleichtern. Daher sollten in dem Antrag möglichst Angaben zum konkreten Verwaltungsverfahren und zum Verfahrensabschnitt gemacht werden.</p>"
            },
            {
              headline: "Recht auf Berichtigung",
              text: "<p>Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, können Sie eine Berichtigung verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine Vervollständigung verlangen.</p>"
            },
            {
              headline: "Recht auf Löschung",
              text: "<p>Sie können die Löschung Ihrer personenbezogenen Daten verlangen. Ihr Anspruch auf Löschung hängt u. a. davon ab, ob die Sie betreffenden Daten von uns zur Erfüllung unserer gesetzlichen Aufgaben noch benötigt werden.</p>"
            },
            {
              text: "<p>Ist dies nicht erforderlich, erfolgt die Löschung Ihrer Daten unverzüglich.</p>"
            },
            {
              headline: "Recht auf Einschränkung der Verarbeitung",
              text: "<p>Sie haben das Recht, eine Einschränkung der Verarbeitung der Sie betreffenden Daten zu verlangen. Die Einschränkung steht einer Verarbeitung nicht entgegen, soweit an der Verarbeitung ein wichtiges öffentliches Interesse besteht.</p>"
            },
            {
              headline: "Recht auf Widerspruch",
              text: "<p>Sofern Ihre Daten nicht auf der Grundlage Ihrer Einwilligung, sondern aus Gründen eines überwiegenden öffentlichen Interesses oder einer Rechtsvorschrift verarbeitet werden, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit der Verarbeitung der Sie betreffenden Daten zu widersprechen.</p>"
            },
            {
              headline: "Recht auf Beschwerde",
              text: '<p>Wenn Sie der Auffassung sind, dass wir Ihrem Anliegen nicht oder nicht in vollem Umfang nachgekommen sind, können Sie bei der zuständigen Datenschutzaufsichtsbehörde Beschwerde einlegen:</p><ul><li>Der Hamburgische Beauftragte für Datenschutz und Informationsfreiheit<br/>Ludwig-Erhard-Str 22, 7. OG<br/>20459 Hamburg</li><li>Telefon: (040) 428 54 - 4040</li><li>E-Fax: (040) 4279 – 11811</li><li>E-Mail: <a href="mailto:mailbox@datenschutz.hamburg.de">mailbox@datenschutz.hamburg.de</a></li></ul>'
            },
            {
              headline: "Allgemeine Hinweise zu diesen Rechten",
              text: "<p>In einigen Fällen können oder dürfen wir Ihrem Anliegen nicht entsprechen. Sofern dies gesetzlich zulässig ist, teilen wir Ihnen in diesem Fall immer den Grund für die Verweigerung mit.</p>"
            },
            {
              text: "<p>Wir werden Ihnen aber grundsätzlich innerhalb eines Monats nach Eingang Ihres Anliegens antworten. Sollten wir länger als einen Monat für eine abschließende Klärung brauchen, erhalten Sie eine Zwischennachricht.</p>"
            }
          ]
        },
        {
          headline:
            "Informationen zu Cookies und Messverfahren zur Nutzung der Antragsplattform Sonderfonds des Bundes für Kulturveranstaltungen",
          anchor: "cookies",
          paragraphs: [
            {
              headline: "Cookies",
              text: '<p>Cookies sind Datenelemente, die eine Website an Ihren Browser schickt, um sie auf Ihrem System zu speichern. Wir benutzen technisch notwendige "Sitzungs-Cookies", um Informationen aus der Website an Ihre Bedürfnisse anpassen zu können. Sobald Sie sich abmelden, werden die Cookies gelöscht.</p>'
            },
            {
              text: "<p>Die meisten Browser können Sie so einstellen, dass Sie benachrichtigt werden, wenn Sie Cookies erhalten. Sie können dann selbst entscheiden, ob Sie das akzeptieren wollen oder nicht. Um die Plattform nutzen zu können, müssen Sie diese Sitzungs-Cookies zulassen.</p>"
            }
          ]
        }
      ]
    },
    {
      columns: [
        {
          paragraphs: [
            {
              text: "<p>Stand 1. Juni 2021</p>"
            }
          ]
        }
      ]
    }
  ],
  faqIntro: [
    {
      pageHeadline: "Häufig gestellte Fragen",
      abstract:
        "Hier finden Sie detaillierte Informationen zum Sonderfonds des Bundes für Kulturveranstaltungen, sowie zu den beiden Modulen Wirtschaftlichkeitshilfe und Ausfallabsicherung."
    }
  ],
  faqFilter: [
    {
      component: "ContentFilter",
      params: {
        id: "faq"
      }
    }
  ],
  faqAccordion: [
    {
      headline: "FAQ",
      type: "accordion",
      decorator: "ArrowRightUp",
      id: "faq"
    }
  ],
  implementation: [
    {
      pageHeadline: "Vollzugshinweise",
      id: "implementation"
      // component: "ColumnsWithAPIContent",
      // params: {
      //   contentPath: "implementation",
      //   postIndex: 0,
      //   postFilter: {
      //     type: "implementation"
      //   }
      // }
    }
  ],
  intro: [
    {
      pageHeadline: "Es geht wieder los!",
      abstract:
        "Kunst und Kultur sind essenziell. Wir wollen, dass wir alle bald wieder die kulturelle Vielfalt in unserem Land auch live erleben können. Konzerte, Festivals, Opern, Tanz, Film, Theater, Musicals, Comedy, Lesungen, Ausstellungen und andere Kulturveranstaltungen – das alles hat uns sehr gefehlt. Mit dem Sonderfonds des Bundes für Kulturveranstaltungen geben wir nun eine Perspektive.<br/> Wir stellen zur Unterstützung von Kulturveranstaltungen bis zu 2,5 Milliarden Euro zur Verfügung, damit der Neustart gelingt. Der Sonderfonds ist dadurch für Künstlerinnen und Künstler und für die gesamte Kreativszene eine wichtige Ergänzung zu den bestehenden Hilfen der Bundesregierung, der Länder und der Kommunen.",
      columns: [
        {
          paragraphs: [
            {
              text: "<p>Der Sonderfonds des Bundes für Kulturveranstaltungen hilft mit zwei Modulen:</p>"
            }
          ]
        }
      ]
    },
    {
      columns: [
        {
          paragraphs: [
            {
              text: "<p>Eine <a href='foerderung#wirtschaftlichkeitshilfe'>Wirtschaftlichkeitshilfe</a> gewährleistet, dass Veranstaltungen auch dann durchgeführt werden können, wenn aus Gründen des Infektionsschutzes weniger Besucherinnen und Besucher zugelassen sind und somit weniger Tickets verkauft werden können. Deshalb gibt es einen Zuschuss auf die Einnahmen aus Ticketverkäufen, um die Finanzierungslücke solcher Veranstaltungen zu schließen.</p>"
            }
          ]
        },
        {
          paragraphs: [
            {
              text: "<p>Eine <a href='foerderung#ausfallabsicherung'>Ausfallabsicherung</a> soll Veranstalterinnen und Veranstaltern zudem Planungssicherheit für größere Kulturveranstaltungen geben. Deshalb übernimmt der Sonderfonds für förderfähige Veranstaltungen im Falle Corona-bedingter Absagen, Teilabsagen oder Verschiebungen den größten Teil der Ausfallkosten.</p>"
            }
          ]
        }
      ]
    }
  ],
  legal: [
    {
      headline: "Impressum",
      columns: [
        {
          paragraphs: [
            {
              headline:
                "Die Freie und Hansestadt Hamburg ist eine Körperschaft des öffentlichen Rechts<br/>Die Freie und Hansestadt Hamburg wird vertreten durch:",
              text: "<p>Erster Bürgermeister Dr. Peter Tschentscher<br/>Rathausmarkt 1<br/>20095 Hamburg</p>"
            },
            {
              headline: "Inhaltlich für diese Webseite verantwortlich:",
              text: "<p>Leiter der Pressestelle der Behörde für Kultur und Medien<br/>Enno Isermann<br/>Hohe Bleichen 22<br/>20354 Hamburg</p>"
            }
          ]
        }
      ]
    },
    {
      columns: [
        {
          headline: "Kontakt",
          paragraphs: [
            {
              text: '<p>Sollten Probleme mit der Registrierung oder der Anmeldung auftreten oder Sie Fragen, Anregungen oder Hinweise zur Plattform „Sonderfonds des Bundes für Kulturveranstaltungen“ allgemein haben, nehmen Sie bitte unter der E-Mail-Adresse <a href="mailto:service@sonderfonds-kulturveranstaltungen.de">service@sonderfonds-kulturveranstaltungen.de</a> Kontakt bzw. über die zentrale Hotline unter Telefon <a href="tel:+498006648430">0800 6648430</a> zu uns auf.</p>'
            }
          ]
        }
      ]
    },
    {
      columns: [
        {
          headline: "Urheberrecht",
          paragraphs: [
            {
              text: "<p>Das Layout der Homepage, die verwendeten Grafiken, die einzelnen Beiträge sowie die Sammlung der Beiträge sind urheberrechtlich geschützt. Die Seiten dürfen nur zum privaten Gebrauch vervielfältigt, Änderungen nicht vorgenommen und Vervielfältigungsstücke ohne Genehmigung nicht verbreitet werden.</p>"
            }
          ]
        }
      ]
    }
  ],
  overview: [
    {
      headline: "Auf einen Blick",
      anchor: "ueberblick",
      decorator: "ArrowRight",
      columns: [
        {
          headline: "Welche Veranstaltungen werden gefördert?",
          paragraphs: [
            {
              text: "<p><strong>Förderfähig sind ausschließlich Kulturveranstaltungen</strong> wie Konzerte, Festivals, Opern, Tanz, Film, Theater, Musicals, Kleinkunst, Varieté, Lesungen, Performing Arts, Medienvorführungen und künstlerische und kulturelle Ausstellungen. Wichtig ist, dass die Veranstaltung in Deutschland stattfindet und Eintrittskarten verkauft.</p>"
            },
            {
              text: '<a href="faq#allgemein_welche">Weitere Details finden sich in den FAQ</a>'
            }
          ]
        },
        {
          headline: "Wer kann Fördergelder beantragen?",
          paragraphs: [
            {
              text: "<p><strong>Veranstalterinnen und Veranstalter von Kulturveranstaltungen.</strong> Veranstalterin oder Veranstalter ist, wer das wirtschaftliche und organisatorische Risiko einer Veranstaltung trägt. Veranstalterinnen und Veranstalter in öffentlicher Trägerschaft sind ebenfalls antragsberechtigt, können jedoch nur die Wirtschaftlichkeitshilfe beantragen.</p>"
            },
            {
              text: '<a href="faq#allgemein_werantragsberechtigt">Weitere Details finden sich in den FAQ</a>'
            }
          ]
        },
        {
          headline: "Wie funktioniert die Förderung?",
          paragraphs: [
            {
              text: "<p>Die <a href='foerderung#wirtschaftlichkeitshilfe'>Wirtschaftlichkeitshilfe</a> startet am 1. Juli 2021 und unterstützt Veranstaltungen mit bis zu 500 möglichen Teilnehmenden (bzw. ab 1. August mit bis zu 2.000 Teilnehmenden), die pandemiebedingt nur mit reduzierter Teilnehmerzahl stattfinden können. Sie verdoppelt (bzw. verdreifacht bei besonders strengen Auflagen) die Einnahmen aus den ersten 1.000 Tickets, bis die Kosten einer Veranstaltung gedeckt sind. Die <a href='foerderung#ausfallabsicherung'>Ausfallabsicherung</a> schafft Planungssicherheit für größere Kulturveranstaltungen (mit mehr als möglichen 2.000 Teilnehmenden) ab dem 1. September 2021 und übernimmt 90 Prozent der Kosten Corona-bedingter Absagen, Teilabsagen oder Verschiebungen.</p>"
            },
            {
              text: "<p>Für beide Module müssen Veranstaltungen im Voraus registriert werden.</p>"
            },
            {
              text: '<a href="faq#allgemein_wiefunktioniert">Weitere Details finden sich in den FAQ</a>'
            }
          ]
        }
      ]
    },
    {
      headline: "Ablauf",
      anchor: "ablauf",
      component: "Process"
    }
  ],
  subsidy: [
    {
      pageHeadline: "Eine Förderung, zwei Bausteine",
      abstract:
        "Der Sonderfonds besteht aus zwei Modulen: kleinere und mittelgroße Veranstaltungen erhalten einen Zuschuss auf ihre Ticketeinnahmen, damit sie auch mit verringerter Teilnehmerzahl stattfinden können. Größere Veranstaltungen erhalten eine Absicherung gegen Corona-bedingte Absagen.",
      type: "tabs",
      columns: [
        {
          headline: "Wirtschaftlichkeitshilfe",
          subline:
            "ab 1.7.2021 für Veranstaltungen mit bis&nbsp;zu&nbsp;500 möglichen Teilnehmenden und ab 1.8.2021 mit bis&nbsp;zu&nbsp;2.000 Teilnehmenden",
          aside: "Wirtschaftlichkeitshilfe",
          anchor: "wirtschaftlichkeitshilfe",
          paragraphs: [
            {
              text: "<p>Um Kulturveranstaltungen auch mit weniger Teilnehmenden – aufgrund von notwendigen Hygienemaßnahmen und Abstandsregeln – wieder wirtschaftlich möglich zu machen, bietet der Sonderfonds des Bundes für Kulturveranstaltungen eine Wirtschaftlichkeitshilfe an. Damit wird die erste Phase des Neustarts für die Kultur schnell und unbürokratisch von staatlicher Seite unterstützt.</p>"
            },
            {
              headline: "Antragsberechtigung:",
              text: "<p>Die Wirtschaftlichkeitshilfe fördert ab dem 1. Juli 2021 Kulturveranstaltungen, die unter Beachtung Corona-bedingter Hygienebestimmungen bis zu 500 Teilnehmende empfangen können. Ab dem 1. August 2021 werden auch Veranstaltungen gefördert, die unter Beachtung Corona-bedingter Hygienebestimmungen bis zu 2.000 Teilnehmende empfangen können.</p>"
            },
            {
              headline: "Höhe der Förderung:",
              text: "<p>Mit der Wirtschaftlichkeitshilfe werden Verluste ausgeglichen. Bei pandemie-bedingter Verringerung der möglichen Anzahl der Teilnehmenden um mindestens 20 Prozent verdoppelt die Wirtschaftlichkeitshilfe die Einnahmen aus den ersten 1.000 verkauften Tickets. Wenn besonders strenge Infektionsschutzauflagen die mögliche Teilnehmerzahl um mehr als 75 Prozent reduzieren (z.B., wenn nur jeder fünfte Platz angeboten werden darf), verdreifacht die Wirtschaftlichkeitshilfe die Ticketeinnahmen aus den ersten 1.000 verkauften Tickets. Die maximale Förderung durch die Wirtschaftlichkeitshilfe bemisst sich an der Finanzierungslücke zwischen veranstaltungsbezogenen Kosten (zuzüglich einer Durchführungspauschale von 10 Prozent dieser Kosten, die systemseitig automatisch errechnet wird und nicht vom Veranstalter selbst angegeben werden muss) und den erzielten Einnahmen. Die Wirtschaftlichkeitshilfe ist bei 100.000 Euro pro Veranstaltung gedeckelt. Spezielle Regelungen bzw. Obergrenzen gelten für Veranstaltungen, die regulär am selben Veranstaltungsort wiederholt stattfinden (bspw. Kinofilmvorführungen oder Musicals).</p>"
            },
            {
              type: "textbox",
              sections: [
                {
                  headline: "Beispiel:",
                  paragraphs: [
                    {
                      text: "<p>Für eine Veranstaltung am 1. Juli verkauft eine Veranstalterin oder Veranstalter 400 Tickets, zu je 50 Euro. Die Corona-bedingte Kapazitätsgrenze beträgt 500 Personen (normalerweise wären 1.500 möglich). Die Wirtschaftlichkeitshilfe würde dann <strong>20.000 Euro</strong> betragen (entspricht einer Verdopplung der Ticketeinnahmen: 400&nbsp;×&nbsp;50 Euro), sofern die Förderhöchstgrenze nicht erreicht wird.</p>"
                    },
                    {
                      text: "<p>Wann die Förderhöchstgrenze erreicht ist, hängt von den Kosten der Veranstaltung ab. Angenommen die Kosten der beschriebenen Veranstaltung betragen lediglich 30.000 Euro, so würde sich die Förderhöchstgrenze auf <strong>13.000 Euro</strong> belaufen: Die veranstaltungsbezogenen Kosten von 30.000 Euro zuzüglich einer Durchführungspauschale in Höhe von 10 Prozent beliefen sich auf 33.000 Euro. Aus dem Ticketverkauf wurden 20.000 Euro erzielt. Die Finanzierungslücke beträgt also 13.000 Euro, was die maximale Förderung darstellt.</p>"
                    }
                  ]
                }
              ]
            },
            {
              headline: "Antragstellung:",
              text: "<p>Ein Antrag auf Wirtschaftlichkeitshilfe kann nach der Veranstaltung über die IT-Plattform gestellt werden. Spätestens am Tag vor der Veranstaltung muss diese jedoch bereits auf der Plattform registriert werden. Im Rahmen der Registrierung sind der Charakter als Kulturveranstaltung sowie die Corona-bedingte Kapazitätsreduktion nachzuweisen (z.B. durch Hygienekonzept oder Eindämmungsverordnung). Damit die Antragstellung und Bearbeitung effizient erfolgt, können auch mehrere Veranstaltungen in einem Antrag gebündelt werden.</p>"
            },
            {
              headline:
                "Optionale Ausfallabsicherung für kleinere Veranstaltungen:",
              text: "<p>Für den Fall, dass wegen der Verschärfung der öffentlichen Pandemiebestimmungen eine für die Wirtschaftlichkeitshilfe registrierte Veranstaltung nicht stattfinden kann, werden Veranstalterinnen und Veranstalter anteilig für 90 Prozent nachgewiesener, veranstaltungsbezogener Ausfallkosten entschädigt. Hierzu muss die Veranstalterin oder der Veranstalter bei Registrierung der Veranstaltung eine Kostenkalkulation eingereicht haben.</p>"
            }
          ]
        },
        {
          headline: "Ausfallabsicherung",
          subline:
            "ab 1.9.2021 für Veranstaltungen mit mehr&nbsp;als&nbsp;2.000 möglichen Teilnehmenden",
          aside: "Ausfallabsicherung",
          anchor: "ausfallabsicherung",
          paragraphs: [
            {
              text: "<p>Größere Veranstaltungen erfordern eine intensive Planung und Logistik, haben deshalb eine lange Vorlaufzeit und benötigen entsprechende Planungssicherheit. In Zeiten der Pandemie ist diese langfristige Planung sehr schwierig. Gleichzeitig ist bei großen Veranstaltungen das finanzielle Risiko einer Absage oder Verschiebung für Veranstalterinnen und Veranstalter erheblich. Deshalb bietet der Sonderfonds des Bundes eine Ausfallabsicherung für Veranstaltungen ab möglichen 2.000 Teilnehmenden (unter Corona-Bedingungen) an. Die Ausfallabsicherung soll eine Planung von größeren Veranstaltungen ermöglichen und übernimmt deshalb 90 Prozent der Ausfall- oder Verschiebungskosten, falls eine geplante Veranstaltung pandemiebedingt nicht oder erst später stattfinden kann.</p>"
            },
            {
              headline: "Antragsberechtigung:",
              text: "<p>Die Ausfallabsicherung sichert Kulturveranstaltungen, die mehr als 2.000 Gäste empfangen können, ab dem 1. September 2021 gegen das Risiko einer Corona-bedingten Absage, Teilabsage oder Verschiebung ab.</p>"
            },
            {
              headline: "Höhe:",
              text: "<p>Im Falle einer pandemiebedingten Absage, Teilabsage oder einer Verschiebung übernimmt die Ausfallabsicherung <strong>maximal 90 Prozent der dadurch entstandenen veranstaltungsbezogenen Kosten</strong>. Die <strong>maximale Entschädigungssumme beträgt 8 Millionen Euro</strong> pro Veranstaltung. Sofern vorhanden, werden die erzielten veranstaltungsbezogenen Einnahmen von den Ausfallkosten abgezogen.</p>"
            },
            {
              headline: "Förderfähige Kosten:",
              text: '<p>Ähnlich wie bei der durch die Bundesregierung angebotene Überbrückungshilfe gibt es eine feste <a href="/faq#foerderfaehig_liste"><strong>Liste an förderfähigen Kosten</strong></a>. Förderfähig sind zum Beispiel Betriebskosten, Kosten für Personal, Anmietung, Wareneinsätze, Künstlergagen, beauftragte Dienstleisterinnen und Dienstleister etc. Kosten können auch dann geltend gemacht werden, wenn sie vor der Antragstellung angefallen sind.</p>'
            },
            {
              headline: "Registrierung für die Ausfallabsicherung:",
              text: "<p>Die Veranstalterinnen und Veranstalter registrieren die Veranstaltung spätestens am Tag <u>vor</u> der geplanten Durchführung und legen dabei auch eine Kostenkalkulation sowie ein geeignetes Hygienekonzept vor. Muss die Veranstaltung Corona-bedingt abgesagt, verkleinert oder verschoben werden, kann die Förderung beantragt werden. Die konkreten Verluste und entstandenen Kosten werden dabei von den Veranstalterinnen und Veranstaltern nachgewiesen und von prüfenden Dritten bestätigt. Die Veranstalterinnen und Veranstalter verpflichten sich zu einem kostenminimierenden Verhalten.</p>"
            }
          ]
        }
      ]
    }
  ]
};

export default content;
