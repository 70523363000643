const CONTENT_ANCHOR = "content";

export const routes = {
  // accessiblity: {
  //   path: "barrierefreiheit",
  //   prefix: "home",
  //   component: Home // DataProtection,
  // },
  data: {
    path: "datenschutz",
    prefix: "home",
    component: "DataProtection"
  },
  faq: {
    path: "faq",
    prefix: "faq",
    component: "Faq"
  },
  aktuelles: {
    path: "aktuelles",
    prefix: "aktuelles",
    component: "Blog"
  },
  implementation: {
    path: "implementation",
    prefix: "implementation",
    component: "SimpleContent",
    params: {
      prefix: "implementation"
    }
  },
  legal: {
    path: "impressum",
    prefix: "legal",
    component: "Legal"
  },
  subsidy: {
    path: "foerderung",
    prefix: "subsidy",
    component: "Subsidy"
  },
  overview: {
    path: "",
    hash: `${CONTENT_ANCHOR}`,
    prefix: "home",
    component: "Home",
    isFallbackPage: true
  }
};

export const allRoutes = Object.values(routes);

export const fallback = routes.overview;

const navLinksOrder = [
  "overview",
  "subsidy",
  "faq",
  "implementation",
  "aktuelles"
];

const metaNavLinksOrder = [
  "legal",
  "data"
  // "accessiblity"
];

export const navLinks = navLinksOrder.map((key) => ({ key, ...routes[key] }));
export const metaNavLinks = metaNavLinksOrder.map((key) => ({
  key,
  ...routes[key]
}));
