import React from "react";
import styled, { keyframes } from "styled-components";
import OpenerStageBlogItem from "./OpenerStageBlogItem";

const SlideWrapper = styled.div(({ nbSlidesPerView }) => ({
  width: `${100 / nbSlidesPerView}%`,
  display: "flex",
  justifyContent: "center"
}));

const scroll = (slideWidthPx, nbItems) => keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-${slideWidthPx}px * ${nbItems}))}
`;

const SliderAnimationLane = styled.div`
  display: flex;
  animation-name: ${(props) => scroll(props.slideWidthPx, props.nbItems)};
  animation-duration: ${(props) => props.animationDuration};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: ${(props) => props.animationPlayState};
  width: ${(props) => props.slideWidthPx * props.nbSlides}px;
  position: relative;
`;

const Slider = ({
  items = [],
  itemOptions = {},
  stopped = false,
  style = {},
  nbSlidesPerView,
  containerWidth,
  animationDuration
}) => {
  const nbItems = items.length;
  const nbSlides = nbItems * 2;
  const animationPlayState = stopped ? "paused" : "running";
  const slideWidthPx = containerWidth / nbSlidesPerView;
  const maxWidth = nbSlides * slideWidthPx;

  const renderItem = ({ item, suffix = "", index }) => (
    <SlideWrapper
      key={`slide${suffix}-${index}`}
      nbSlidesPerView={nbSlidesPerView}
    >
      <OpenerStageBlogItem
        layout="opener"
        itemWidth={itemOptions.width}
        opacity={itemOptions.disabled ? 0.5 : 1}
        disabled={itemOptions.disabled}
        pictureRatio={itemOptions.pictureRatio}
        clickOnPicture={() => itemOptions.clickOnPicture(index)}
        clickOutside={itemOptions.clickOutside}
        showPicture={
          itemOptions.showPicture ? itemOptions.showPicture(index) : true
        }
        startSwipers={itemOptions.startSliders}
        {...item}
      />
    </SlideWrapper>
  );

  return (
    <div
      className="slider"
      style={{
        ...style,
        overflow: "hidden",
        position: "relative",
        width: `${maxWidth}px`,
        display: "flex",
        alignItems: "center"
      }}
    >
      <SliderAnimationLane
        animationPlayState={animationPlayState}
        slideWidthPx={slideWidthPx}
        nbSlides={nbSlides}
        nbItems={nbItems}
        animationDuration={animationDuration}
      >
        {items.map((item, index) => renderItem({ item, index }))}
        {items.map((item, index) =>
          renderItem({ item, index, suffix: "duplicate" })
        )}
      </SliderAnimationLane>
    </div>
  );
};
export default Slider;
