import React from "react";
import styled from "styled-components";
import {
  backgrounds,
  containerWidthsFull,
  containerWidthsIndent,
  containerWidthsTabs,
  containerWidthsText,
  containerPaddingsText,
  containerPaddingsTopText,
  containerPaddingBottomText,
  containerPaddingsBox,
  containerPaddingsTabs
} from "../utils/theme";
import { useViewerClient } from "../context/viewerClient";
import Container from "./Container";
import Section from "./Section";

const Comp = styled.div(({ styles, isPrint }) => {
  const printStyles = isPrint
    ? {
        "@media print": {
          display: "none"
        }
      }
    : {};
  return {
    ...printStyles,
    ...styles
  };
});

/**
 *
 * @param {object} content content object
 * @param {string} prefix prefix for id and child elements without anchors
 * @param {string} type one out of intro, box, content, cta
 * @param {object} styles additional styles
 * @param {boolean} isPrint if true, use print styles
 * @param {function} contentProvider content provider function
 * @returns
 */
const Block = ({
  content,
  prefix,
  theme,
  type,
  styles,
  isPrint,
  contentProvider
}) => {
  const { resolution, resolutionCoarse } = useViewerClient();

  const containerProps = {};
  let spacing = {};

  switch (type) {
    case "faqAbstract":
      containerProps.contentSpacings = containerWidthsText;
      spacing = containerPaddingsTopText[resolution];
      break;
    case "blogContainer":
      containerProps.contentSpacings = containerWidthsFull;
      spacing = containerPaddingBottomText[resolution];
      break;
    case "full":
      containerProps.contentSpacings = containerWidthsFull;
      spacing = containerPaddingsText[resolution];
      break;
    case "intro":
      containerProps.contentSpacings = containerWidthsIndent;
      spacing = containerPaddingsText[resolution];
      break;
    case "box":
      containerProps.contentSpacings = containerWidthsText;
      spacing = containerPaddingsBox[resolution];
      break;
    case "tabs":
      containerProps.contentSpacings = containerWidthsTabs;
      spacing = containerPaddingsTabs[resolution];
      break;
    default:
      containerProps.contentSpacings = containerWidthsText;
      spacing = containerPaddingsText[resolution];
      break;
  }

  const background = backgrounds[theme || "white"];

  return (
    <Comp
      styles={{ ...spacing, background, ...styles }}
      isPrint={isPrint}
      resolutionCoarse={resolutionCoarse}
    >
      {content.map(({ decorator, ...section }, index) => {
        const key = `${prefix}_${index}`;
        return (
          <Container
            fullWidth
            {...containerProps}
            decorator={decorator}
            key={key}
          >
            <Section
              section={section}
              prefix={key}
              contentProvider={contentProvider}
            />
          </Container>
        );
      })}
    </Comp>
  );
};

export default Block;
