import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { parseContent } from "../utils/content";
import {
  colors,
  fontStyleTabHeadline,
  fontStyleTabSubline,
  styleTabNavItem
} from "../utils/theme";
import DotSeparator from "./DotSeparator";
import Triangle from "./Triangle";

const borderRadius = "4px";

const Comp = styled.div(
  ({ gradient, backgroundAttachment, size, isActive, fullWidth }) => {
    const styles = fullWidth ? {} : { borderRadius };
    if (backgroundAttachment) {
      styles.backgroundAttachment = backgroundAttachment;
    }
    return {
      flex: `0 1 ${size * 100}%`,
      position: "relative",
      cursor: "pointer",
      background: isActive ? colors.GREY_DARK : gradient,
      ...styles
    };
  }
);

const TabNavItemContent = styled.div(({ resolutionCoarse }) => {
  return {
    color: colors.WHITE,
    ...styleTabNavItem[resolutionCoarse]
  };
});

const Headline = styled.span(({ resolutionCoarse }) => {
  return {
    fontWeight: 600,
    ...fontStyleTabHeadline[resolutionCoarse]
  };
});

const Subline = styled.span(({ resolutionCoarse }) => {
  return {
    ...fontStyleTabSubline[resolutionCoarse]
  };
});

const TriangleWrapper = styled.div(({ align }) => {
  return {
    position: "absolute",
    top: "100%",
    [align]: "20%"
  };
});

const TabNavItem = ({ headline, subline, ...props }) => {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp {...props} role="button">
      {!props.isFirst && <DotSeparator position="left" />}
      <TabNavItemContent resolutionCoarse={resolutionCoarse}>
        <Headline resolutionCoarse={resolutionCoarse}>
          {parseContent(headline)}
        </Headline>
        <br />
        <Subline resolutionCoarse={resolutionCoarse}>
          {parseContent(subline)}
        </Subline>
      </TabNavItemContent>
      {props.isActive && (
        <TriangleWrapper align={props.isFirst ? "right" : "left"}>
          <Triangle color={colors.GREY_DARK} />
        </TriangleWrapper>
      )}
    </Comp>
  );
};

export default TabNavItem;
