import React from "react";
import { Switch, Route } from "react-router-dom";
import { useComponents } from "../context/components";
import { allRoutes } from "../utils/routes";

function RouteSwitch({ routes = allRoutes, prefix = "/", children }) {
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const components = useComponents();

  return (
    <Switch>
      {routes.map(
        ({ path, isFallbackPage, component, params, ...routeProps }) => {
          if (isIE && !isFallbackPage) {
            return null;
          }
          const compProps = {};
          if (component) {
            const Component = components[component];
            compProps.render = (rp) => <Component {...rp} {...params} />;
          }
          return (
            <Route
              key={path}
              path={`${prefix}${path}`}
              {...routeProps}
              {...compProps}
            />
          );
        }
      )}
      {/* TODO move out */}
      {isIE ? (
        <>Der von Ihnen verwendete Browser wird nicht unterstützt</>
      ) : (
        children
      )}
    </Switch>
  );
}

export default RouteSwitch;
