import React from "react";

function ArrowUpIcon({ size = 21, fill }) {
  const pathProps = fill ? { fill } : {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 21"
    >
      <g transform="translate(21) rotate(90)">
        <path
          d="M21,9.188H4.987l7.35-7.35L10.5,0,0,10.5,10.5,21l1.837-1.837-7.35-7.35H21Z"
          transform="translate(0 0)"
          className="fill"
          {...pathProps}
        />
      </g>
    </svg>
  );
}

export default ArrowUpIcon;
