import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontStyleAbstract } from "../utils/theme";

const Comp = styled.div(({ resolutionCoarse, styles }) => {
  return {
    fontFamily: fontFamily.normal,
    fontWeight: "normal",
    ...fontStyleAbstract[resolutionCoarse],
    ...styles
  };
});

function Abstract({ children, styles }) {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse} styles={styles}>
      {children}
    </Comp>
  );
}

export default Abstract;
