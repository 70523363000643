import React from "react";

function ArrowRightLongIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="22"
      viewBox="0 0 30 22"
    >
      <g transform="translate(0 0.664)">
        <path
          d="M-3301.295,11719h28.014"
          transform="translate(3301.295 -11708.082)"
          className="stroke"
          fill="none"
          strokeWidth="2"
        />
        <path
          d="M-3269.943,11699.66l9.736,10.95-9.736,10.557"
          transform="translate(3288.205 -11699.66)"
          className="stroke"
          fill="none"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
export default ArrowRightLongIcon;
