import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import Page from "../components/Page";
import Block from "../components/Block";
import { useContent } from "../context/content";
import content from "../locales/de/content";
import RouteSwitch from "../containers/RouteSwitch";
import { useViewerClient } from "../context/viewerClient";

const Blog = () => {
  const { contentLists, fetchContentList } = useContent();
  const match = useRouteMatch();
  const blogContent = contentLists.blog;
  const { resolutionCoarse } = useViewerClient();

  const shouldFetch = !blogContent;

  useEffect(() => {
    // get content from provider
    if (shouldFetch) {
      fetchContentList("blog");
    }
  }, [fetchContentList, shouldFetch]);

  const blogRoutes =
    blogContent && blogContent.posts
      ? blogContent.posts.map(({ path, id }) => ({
          path,
          component: "BlogPost",
          params: {
            contentPath: "posts",
            id
          }
        }))
      : [];

  return (
    <Page>
      <RouteSwitch routes={blogRoutes} prefix={`${match.path}/`}>
        <>
          <Block
            content={content.blogIntro}
            prefix="blog"
            type="faqAbstract"
            theme="grey"
          />
          <Block
            content={content.blogOverview}
            type="blogContainer"
            prefix="blog"
            theme="grey"
          />
          {resolutionCoarse === "mobile" ? (
            <Block
              content={content.contact}
              prefix="contact"
              type="cta"
              theme="grey"
            />
          ) : (
            <Block content={content.contact} prefix="contact" type="cta" />
          )}
        </>
      </RouteSwitch>
    </Page>
  );
};

export default Blog;
