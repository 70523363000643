import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { useVersionedContent } from "../context/versionedContent";
import { useViewerClient } from "../context/viewerClient";
import { colors, fontStyleH4Print, themeButtonReset } from "../utils/theme";

const Comp = styled.div(({ isMobile }) => ({
  display: "flex",
  flexWrap: isMobile ? "wrap" : "no-wrap",
  alignItems: "center"
}));

const TextWrapper = styled.div(({ isMobile, resolutionCoarse }) => ({
  flex: isMobile ? "1 1 100%" : "1 1 auto",
  paddingLeft: isMobile ? "13px" : "36px",
  ...fontStyleH4Print[resolutionCoarse]
}));

const ButtonWrapper = styled.div(({ isMobile }) => ({
  flex: isMobile ? "1 1 100%" : "0 0 auto",
  marginBottom: isMobile ? "85px" : 0,
  textAlign: "center"
}));

const PrintButton = styled.button({
  ...themeButtonReset,
  fontSize: "24px",
  height: "58px",
  background: colors.GREY_DARK,
  color: colors.WHITE,
  borderRadius: "4px",
  paddingLeft: "2em",
  paddingRight: "2em"
});

function ContentFilterPrint({ id }) {
  const { selectedVersions } = useVersionedContent();
  const { isGTETabletP, resolutionCoarse } = useViewerClient();
  const { t } = useTranslation();

  return (
    <Comp isMobile={!isGTETabletP}>
      <ButtonWrapper isMobile={!isGTETabletP}>
        <PrintButton onClick={() => window.print()}>
          {t("contentFilter.print.action")}
        </PrintButton>
      </ButtonWrapper>
      <TextWrapper isMobile={!isGTETabletP} resolutionCoarse={resolutionCoarse}>
        <Trans
          i18nKey={"contentFilter.print.intro"}
          date={selectedVersions[id][0].label}
        >
          Drucken Sie den aktuellen Stand des{" "}
          <strong>FAQ vom {{ date: selectedVersions[id][0].label }}</strong> im
          Volltext und ohne Markierungen aus.
        </Trans>
      </TextWrapper>
    </Comp>
  );
}

export default ContentFilterPrint;
