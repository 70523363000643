import React from "react";

function ProcessTabletAfter({ backgroundColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 431.87 72">
      <path
        fill="#fff"
        d="M422.28 19.67L422.28 19.64 399.45 19.64 399.45 23.54 415.66 23.54 389.64 49.55 369.43 29.32 366.67 32.08 386.89 52.3 389.64 55.06 418.41 26.29 418.41 42.49 422.31 42.49 422.31 19.67 422.28 19.67z"
      />
      <path
        fill={backgroundColor}
        d="M0 72h431.87V0H0zm3.41-32.71a2 2 0 01-1.41.59 2 2 0 01-1.41-.59A2 2 0 010 37.88a2 2 0 114 0 2 2 0 01-.59 1.41zm8.4.59a2 2 0 112-2 2 2 0 01-2 2zm9.81 0a2 2 0 112-2 2 2 0 01-2 2zm9.82 0a2 2 0 112-2 2 2 0 01-2 2zm9.81 0a2 2 0 112-2 2 2 0 01-2 2zm9.81 0a2 2 0 112-2 2 2 0 01-2 2zm9.82 0a2 2 0 112-2 2 2 0 01-2 2zm9.81 0a2 2 0 112-2 2 2 0 01-2 2zm9.81 0a2 2 0 112-2 2 2 0 01-2 2zm9.82 0a2 2 0 112-2 2 2 0 01-2 2zm9.81 0a2 2 0 112-2 2 2 0 01-2 2zm9.81 0a2 2 0 112-2 2 2 0 01-2 2zm11.23-.59a2 2 0 110-2.83 2 2 0 01.59 1.42 2 2 0 01-.59 1.41zM199.87 72h-72V0h72zm8.3-32.71a2 2 0 11.58-1.41 2 2 0 01-.58 1.41zm8.36.59a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-1.95 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm11.18-.59a2 2 0 01-2.82 0 2 2 0 01-.59-1.41 2 2 0 114 0 2 2 0 01-.59 1.41zM431.87 72h-72V0h72z"
      />
      <path
        fill="#fff"
        d="M163.91 12.16L163.89 12.14 147.75 28.28 150.51 31.03 161.97 19.58 161.96 49.26 165.86 49.26 165.86 19.57 177.32 31.03 180.07 28.27 163.94 12.14 163.91 12.16z"
      />
      <path fill="#fff" d="M140.66 54.79H187.17V58.69H140.66z" />
    </svg>
  );
}

export default ProcessTabletAfter;
