import React, { createContext, useCallback, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  breakpoints,
  themeStage,
  tickerHeight,
  tickerHeightMobile
} from "../utils/theme";

export const ViewerClientContext = createContext({
  resolution: "mobileP"
});

export const ViewerClientProvider = ({ children }) => {
  const [footerBndRect, setFooterBndRect] = useState(null);

  let resolution = "mobileP";
  let resolutionCoarse = "mobile";
  const isGTEMobileL = useMediaQuery({
    minWidth: breakpoints.mobileL
  });
  if (isGTEMobileL) {
    resolution = "mobileL";
  }
  const isGTETabletP = useMediaQuery({
    minWidth: breakpoints.tabletP
  });
  if (isGTETabletP) {
    resolution = "tabletP";
    resolutionCoarse = "tablet";
  }
  const isGTETabletL = useMediaQuery({
    minWidth: breakpoints.tabletL
  });
  if (isGTETabletL) {
    resolution = "tabletL";
  }
  const isGTEDesktop = useMediaQuery({
    minWidth: breakpoints.desktop
  });
  if (isGTEDesktop) {
    resolution = "desktop";
    resolutionCoarse = "desktop";
  }
  const isGTEWide = useMediaQuery({
    minWidth: breakpoints.wide
  });
  if (isGTEWide) {
    resolution = "wide";
  }

  const ticker = isGTEDesktop ? tickerHeight : tickerHeightMobile;
  const { headerHeightSticky: headerHeight } = themeStage[resolution];

  // keep the footer rect, for removing the "fixed" prop on links, prevents the overlapping with the footer
  const setFooterBoundingRect = useCallback(
    (value) => setFooterBndRect(value),
    [setFooterBndRect]
  );

  return (
    <ViewerClientContext.Provider
      value={{
        resolution,
        resolutionCoarse,
        isGTEMobileL,
        isGTETabletP,
        isGTETabletL,
        isGTEDesktop,
        isGTEWide,
        currentTickerHeight: ticker,
        headerHeight: headerHeight + ticker,
        footerBndRect,
        setFooterBoundingRect
      }}
    >
      {children}
    </ViewerClientContext.Provider>
  );
};

export const useViewerClient = () => {
  return useContext(ViewerClientContext);
};
