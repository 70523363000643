import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontStyleH4 } from "../utils/theme";

const Comp = styled.h4(({ resolutionCoarse }) => {
  return {
    fontFamily: fontFamily.normal,
    fontWeight: 600,
    marginBlockEnd: 0,
    ...fontStyleH4[resolutionCoarse]
  };
});

function H4({ children }) {
  const { resolutionCoarse } = useViewerClient();
  return <Comp resolutionCoarse={resolutionCoarse}>{children}</Comp>;
}

export default H4;
