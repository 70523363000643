import React from "react";

// "4.5 5.5 12.5 12"
function ArrowRightUpIcon({ size = 22.627, width, fill }) {
  const pathProps = fill ? { fill } : {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || size}
      height={size}
      viewBox="4.5 5.5 12.5 12"
    >
      <g transform="translate(10.715 23.227) rotate(-135)">
        <path
          d="M16,7H3.8L9.4,1.4,8,0,0,8l8,8,1.4-1.4L3.8,9H16Z"
          transform="translate(0 16.847) rotate(-90)"
          className="fill"
          {...pathProps}
        />
      </g>
    </svg>
  );
}

export default ArrowRightUpIcon;
