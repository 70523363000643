import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NavLink as Link } from "react-router-dom";
import { useViewerClient } from "../context/viewerClient";
import { useStyles } from "../context/styles";
import { metaNavLinks, navLinks } from "../utils/routes";
import {
  colors,
  fontSizeNavBar,
  headerSpacingDefault,
  headerSpacingSticky,
  transitionSpeed,
  themeFontNavBar,
  contentSpacing,
  colorSchemes,
  headerSpacingMobile,
  burgerIconHeight,
  themeButtonReset,
  fontSizeNavBarFooter,
  header,
  burgerIconWidth
} from "../utils/theme";
import BurgerIcon from "./BurgerIcon";
import BundIcon from "./BundIcon";
import Container from "./Container";
import CrossIcon from "./CrossIcon";
import DotSeparator from "./DotSeparator";
import FHHIcon from "./FHHIcon";
import Logo from "./Logo";
import NavLinkItem from "./NavLinkItem";
import SponsorLogoWrapper from "./SponsorLogoWrapper";
import ArrowRightIcon from "./ArrowRightIcon";
import Newsticker from "./Newsticker";

const Comp = styled.header(({ isFixed, background, height }) => ({
  background,
  backgroundAttachment: "fixed",
  position: isFixed ? "fixed" : "relative",
  top: 0,
  zIndex: 100,
  height,
  width: "100%",
  "@media print": {
    display: "none"
  }
}));

const NavSection = styled.nav(({ resolution, height }) => {
  return {
    fontSize: fontSizeNavBar[resolution],
    height,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    transitionDuration: `${transitionSpeed}`
  };
});

const LogoWrapper = styled.div({
  flex: "0 0 auto"
});

const LogoExtension = styled.div(({ logoHeight }) => {
  return {
    height: `${logoHeight}px`,
    width: "100%",
    background: colors.WHITE,
    transitionDuration: `${transitionSpeed}`
  };
});

const NavWrapper = styled.ul(({ resolution }) => ({
  flex: "1 1 100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: contentSpacing[resolution],
  margin: 0
}));

const NavItem = styled.li({
  listStyle: "none",
  a: {
    ...themeFontNavBar,
    textDecoration: "none",
    paddingLeft: "15px",
    paddingRight: "15px",
    textUnderlinePosition: "under"
  },
  "a:focus": {
    textDecoration: "underline"
  },
  "a:hover": {
    textDecoration: "underline"
  }
});

const NavItemButton = styled.li({
  listStyle: "none",
  flex: "0 0 auto",
  a: {
    textDecoration: "none"
  },
  "a:focus": {
    textDecoration: "underline"
  },
  "a:hover": {
    textDecoration: "underline"
  },
  "a:active": {
    textDecoration: "underline"
  }
});

const NavSpacer = styled.div({
  flex: "0 1 80px"
});

const NavButton = styled.a({
  fontSize: "inherit",
  ...themeFontNavBar,
  ...colorSchemes.dark,
  textDecoration: "none",
  padding: "15px 40px",
  // marginLeft: "80px",
  borderRadius: "4px",
  svg: {
    marginLeft: "10px"
  },
  ".fill": {
    fill: colorSchemes.dark.color
  },
  ":focus": {
    ...colorSchemes.light,
    textDecoration: "none"
  },
  ":hover": {
    ...colorSchemes.light,
    textDecoration: "none",
    ".fill": {
      fill: colorSchemes.light.color
    }
  },
  ":active": {
    ...colorSchemes.light,
    textDecoration: "none"
  }
});

const Overlay = styled.div({
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "auto",
  zIndex: 10,
  transitionDuration: `${transitionSpeed}`
});

const BurgerNavContent = styled.div(({ background }) => ({
  flex: "0 0 auto",
  display: "flex",
  flexDirection: "column",
  background,
  backgroundAttachment: "fixed",
  position: "relative"
}));
const PrimaryBurgerNavWrapper = styled.ul(({ resolution, isGTETabletP }) => {
  return {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    fontSize: fontSizeNavBar[resolution],
    padding: `40px ${contentSpacing[resolution]}`,
    margin: `${isGTETabletP ? "50px" : "0"} 0 70px 0`,
    li: {
      marginBottom: "25px"
    }
  };
});
const SecondaryBurgerNavWrapper = styled.ul(({ resolution }) => {
  return {
    flex: "1 1 auto",
    display: "flex",
    flexWrap: "wrap",
    fontSize: fontSizeNavBarFooter[resolution],
    lineHeight: "24px",
    padding: `40px ${contentSpacing[resolution]}`,
    margin: 0,
    li: {
      marginRight: "20px"
    },
    "li:last-child": {
      marginRight: 0
    }
  };
});
const BurgerNavFooter = styled.div(({ resolution }) => ({
  flex: "1 0 auto",
  background: colors.WHITE,
  padding: `40px ${contentSpacing[resolution]}`,
  paddingTop: "40px",
  ".fill": {
    fill: colorSchemes.light.color
  }
}));
const BurgerNavFooterContent = styled.div({
  display: "flex"
});

const BurgerWrapper = styled.div(({ height, width, resolution }) => ({
  ...themeButtonReset,
  display: "flex",
  alignItems: "center",
  height,
  width,
  marginRight: contentSpacing[resolution],
  position: "relative"
}));

const OverlayIconWrapper = styled.div(({ height, top, resolution }) => ({
  ...themeButtonReset,
  height,
  marginRight: contentSpacing[resolution],
  marginTop: `${top}px`,
  position: "absolute",
  right: 0
}));

const BurgerButton = styled.button({
  ...themeButtonReset,
  height: "100%",
  width: "inherit",
  zIndex: 11,
  svg: {
    height: "100%",
    width: "auto"
  }
});

function Header({ isSmall, isFixed }) {
  const { t } = useTranslation();
  const { isGTETabletP, isGTEDesktop, currentTickerHeight, resolution } =
    useViewerClient();
  const { gradient } = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const spacing = isGTETabletP
    ? isSmall
      ? headerSpacingSticky
      : headerSpacingDefault
    : headerSpacingMobile;
  return (
    <Comp
      isFixed={!isGTEDesktop || isFixed}
      hideTopBar={isGTEDesktop && isFixed}
      background={gradient}
    >
      <Newsticker />
      <Container
        theme="transparent"
        borderBottomStyle="dots"
        containerStyles={{ height: header[resolution].main.height }}
        fullWidth
        left={<LogoExtension logoHeight={header[resolution].logo.height} />}
      >
        <NavSection
          resolution={resolution}
          height={header[resolution].main.height}
        >
          <LogoWrapper>
            <a href="/" title={t("header.home")}>
              <Logo height={header[resolution].logo.height} />
            </a>
          </LogoWrapper>
          {isGTEDesktop ? (
            <NavWrapper resolution={resolution}>
              {navLinks.map(({ key, path, hash }) => (
                <NavItem key={`nav_${key}`}>
                  <Link
                    to={`${path ? `/${path}` : ""}${hash ? `/#${hash}` : ""}`}
                    activeStyle={{
                      textDecoration: "underline"
                    }}
                    strict
                    exact
                  >
                    {t(`nav.${key}`)}
                  </Link>
                </NavItem>
              ))}
              <NavSpacer />
              <NavItemButton key={"nav_button"}>
                <NavButton
                  href={`${process.env.REACT_APP_APPLY_LINK}`}
                  target="_blank"
                >
                  {t("link.apply.text")}
                  <ArrowRightIcon />
                </NavButton>
              </NavItemButton>
            </NavWrapper>
          ) : (
            <>
              {isMenuOpen ? (
                <Overlay>
                  <BurgerNavContent background={gradient}>
                    <PrimaryBurgerNavWrapper
                      resolution={resolution}
                      isGTETabletP={isGTETabletP}
                    >
                      {navLinks.map(({ key, path, hash }) => (
                        <NavLinkItem key={`b_nav_${key}`}>
                          <Link
                            to={`${path ? `/${path}` : ""}${
                              hash ? `/#${hash}` : ""
                            }`}
                            activeStyle={{
                              textDecoration: "underline"
                            }}
                            strict
                            exact
                          >
                            {t(`nav.${key}`)}
                          </Link>
                        </NavLinkItem>
                      ))}
                    </PrimaryBurgerNavWrapper>
                    <OverlayIconWrapper
                      height={burgerIconHeight}
                      top={10 + spacing + currentTickerHeight}
                      resolution={resolution}
                    >
                      <BurgerButton
                        onClick={() => setIsMenuOpen(false)}
                        ariaLabel={t("nav.menuClose")}
                      >
                        <CrossIcon />
                      </BurgerButton>
                    </OverlayIconWrapper>
                    <SecondaryBurgerNavWrapper resolution={resolution}>
                      {metaNavLinks.map(({ key, path, hash }) => (
                        <NavLinkItem key={`b_nav_${key}`}>
                          <Link
                            to={`${path ? `/${path}` : ""}${
                              hash ? `/#${hash}` : ""
                            }`}
                          >
                            {t(`nav.${key}`)}
                          </Link>
                        </NavLinkItem>
                      ))}
                    </SecondaryBurgerNavWrapper>
                    <DotSeparator />
                  </BurgerNavContent>
                  <BurgerNavFooter resolution={resolution}>
                    <BurgerNavFooterContent>
                      <SponsorLogoWrapper isFirst>
                        <BundIcon />
                      </SponsorLogoWrapper>
                      <SponsorLogoWrapper>
                        <FHHIcon />
                      </SponsorLogoWrapper>
                    </BurgerNavFooterContent>
                  </BurgerNavFooter>
                </Overlay>
              ) : (
                <BurgerWrapper
                  height={burgerIconHeight}
                  width={burgerIconWidth}
                  resolution={resolution}
                >
                  <BurgerButton
                    onClick={() => setIsMenuOpen(true)}
                    ariaLabel={t("nav.menuOpen")}
                  >
                    <BurgerIcon
                      width={burgerIconWidth}
                      height={burgerIconHeight}
                    />
                  </BurgerButton>
                </BurgerWrapper>
              )}
            </>
          )}
        </NavSection>
      </Container>
    </Comp>
  );
}

export default Header;
