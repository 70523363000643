/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from "react";
import Ticker from "react-ticker";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import {
  colors,
  themeTicker,
  tickerHeight,
  tickerHeightMobile
} from "../utils/theme";
import { useContent } from "../context/content";
import { parseContent } from "../utils/content";

const Comp = styled.div(({ resolutionCoarse }) => ({
  width: "100%",
  color: colors.GREY_DARK,
  background: colors.YELLOW_MEDIUM,
  ...themeTicker[resolutionCoarse]
}));

const TickerItem = styled.div({
  whiteSpace: "nowrap",
  marginLeft: "12px",
  p: {
    marginBlockStart: "0px",
    marginBlockEnd: "0px",
    a: {
      svg: {
        display: "none"
      }
    }
  }
});

function Newsticker() {
  const { newsticker, fetchNewstickerContents } = useContent();
  const { resolutionCoarse, isGTETabletP } = useViewerClient();
  const [fontsLoaded, setFontsLoaded] = useState(false);
  document.fonts.ready.then(() => setFontsLoaded(true));

  useEffect(() => {
    if (!newsticker || !newsticker.Inhalt) {
      fetchNewstickerContents();
    }
  }, [newsticker, fetchNewstickerContents]);

  if (!fontsLoaded) {
    return null;
  }
  if (!newsticker || !newsticker.Inhalt) {
    return null;
  }
  const newstickerContent = parseContent(newsticker.Inhalt);
  return (
    <Comp resolutionCoarse={resolutionCoarse}>
      {isGTETabletP ? (
        <Ticker height={tickerHeight} speed={3}>
          {() => <TickerItem>{newstickerContent}</TickerItem>}
        </Ticker>
      ) : (
        <Ticker height={tickerHeightMobile} speed={3} offset="run-in">
          {() => <TickerItem>{newstickerContent}</TickerItem>}
        </Ticker>
      )}
    </Comp>
  );
}

export default Newsticker;
