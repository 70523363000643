import React from "react";
import { colors } from "../utils/theme";

function PDFIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M27,0H9A3,3,0,0,0,6,3V21a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V3A3,3,0,0,0,27,0ZM14.25,11.25A2.24,2.24,0,0,1,12,13.5H10.5v3H8.25v-9H12a2.24,2.24,0,0,1,2.25,2.24h0Zm7.5,3a2.24,2.24,0,0,1-2.24,2.25H15.75v-9H19.5a2.24,2.24,0,0,1,2.25,2.24h0Zm6-4.5H25.5v1.5h2.25V13.5H25.5v3H23.25v-9h4.5ZM10.5,11.25H12V9.75H10.5ZM3,6H0V27a3,3,0,0,0,3,3H24V27H3Zm15,8.25h1.5V9.75H18Z"
        fill={colors.GREY_DARK}
      />
    </svg>
  );
}
export default PDFIcon;
