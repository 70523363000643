import styled from "styled-components";

const ControlsWrapper = styled.div({
  lineHeight: "30px",
  marginBottom: "30px",
  display: "flex",
  justifyContent: "space-between"
});

export default ControlsWrapper;
