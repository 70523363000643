import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import CircleButton from "./CircleButton";
import FacebookIcon from "./FacebookIcon";
import TwitterIcon from "./TwitterIcon";
import ShowTempInfo from "./ShowTempInfo";
import CopyLinkIcon from "./CopyLinkIcon";

const Comp = styled.div(({ isGTEDesktop, isGTETabletL, isGTETabletP }) => {
  if (isGTEDesktop) {
    return {
      display: "flex",
      flexDirection: "column",
      gap: "60px",
      marginTop: "112px",
      marginBottom: "62px",
      a: {
        display: "block",
        marginLeft: "30px",
        textDecoration: "none",
        ":hover": {
          textDecoration: "none"
        }
      }
    };
  }
  if (isGTETabletL) {
    return {
      display: "flex",
      flexDirection: "column",
      gap: "60px",
      marginBottom: "40px",
      a: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        textDecoration: "none",
        ":hover": {
          textDecoration: "none"
        }
      }
    };
  }
  if (isGTETabletP) {
    return {
      display: "flex",
      flexDirection: "column",
      gap: "60px",
      marginBottom: "40px",
      width: "100%",
      a: {
        paddingLeft: "30px",
        flex: "0 1 auto",
        textDecoration: "none",
        ":hover": {
          textDecoration: "none"
        }
      }
    };
  }
  return {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "40px",
    width: "100%",
    a: {
      flex: "0 1 auto",
      textDecoration: "none",
      ":hover": {
        textDecoration: "none"
      }
    }
  };
});

const CopyLinkIconWrapper = styled.div(() => {
  return {
    width: "60px"
  };
});

const CopyLinkIconInfoWrapper = styled.div(({ isGTETabletL, isGTEDesktop }) => {
  if (isGTETabletL || isGTEDesktop) {
    return {
      display: "block",
      position: "absolute"
    };
  }
  return {
    display: "block"
  };
});

function ShareLinks() {
  const { t } = useTranslation();
  const { isGTETabletP, isGTETabletL, isGTEDesktop, resolution } =
    useViewerClient();
  const urlEncoded = encodeURIComponent(window.location.href);
  const [showInfoHint, setShowInfoHint] = useState(false);

  const copyLink = (e) => {
    e.preventDefault();
    const linkValue = window.location.href;
    navigator.clipboard.writeText(linkValue);
    setShowInfoHint(true);
  };

  return (
    <Comp
      isGTEDesktop={isGTEDesktop}
      isGTETabletL={isGTETabletL}
      isGTETabletP={isGTETabletP}
    >
      <a
        href={`https://twitter.com/intent/tweet?text=${urlEncoded}`}
        target="_blank"
        rel="noreferrer"
        title={t("share.twitter")}
      >
        <CircleButton iconStyles={{ marginLeft: "4px", marginTop: "3px" }}>
          <TwitterIcon />
        </CircleButton>
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${urlEncoded}`}
        target="_blank"
        rel="noreferrer"
        title={t("share.facebook")}
      >
        <CircleButton iconStyles={{ marginLeft: "-1px", marginTop: "1px" }}>
          <FacebookIcon />
        </CircleButton>
      </a>
      <a
        href="#"
        style={{ minHeight: "60px" }}
        onClick={(event) => copyLink(event)}
        title={t("share.copy")}
      >
        <CopyLinkIconInfoWrapper
          isGTEDesktop={isGTEDesktop}
          isGTETabletL={isGTETabletL}
        >
          <CopyLinkIconWrapper>
            <CopyLinkIcon />
          </CopyLinkIconWrapper>
        </CopyLinkIconInfoWrapper>
      </a>
      <ShowTempInfo
        showInfoHint={showInfoHint}
        resetShowInfoHint={setShowInfoHint}
        text={t(`share.showTempInfo.${resolution}.copyInfo`)}
      />
    </Comp>
  );
}

export default ShareLinks;
