import React from "react";

function CrossIcon({ color = "#fff", width = "53", height = "43" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="15 12 53 43"
    >
      <g transform="translate(0.249 13)">
        <g transform="translate(-1.647 -13)">
          <rect
            width="48.419"
            height="6"
            transform="translate(27.641 13.454) rotate(45)"
            fill={color}
          />
          <rect
            width="48.419"
            height="6"
            transform="translate(23.837 47.691) rotate(-45)"
            fill={color}
          />
        </g>
        <rect
          width="83"
          height="67"
          transform="translate(-0.249 -13)"
          fill={color}
          opacity="0"
        />
      </g>
    </svg>
  );
}
export default CrossIcon;
