import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { blogSpacing, colors, fontStyleBlogListHeadline } from "../utils/theme";

const Comp = styled.div(({ resolutionCoarse }) => {
  const borderStyle = `2px solid ${colors.GREY_DARK}`;
  return {
    borderTop: borderStyle,
    borderBottom: borderStyle,
    paddingLeft: blogSpacing.postHeadlineIndent[resolutionCoarse],
    paddingRight: blogSpacing.postHeadlineIndent[resolutionCoarse]
  };
});

const Headline = styled.h2(({ resolutionCoarse }) => ({
  fontWeight: 600,
  marginTop: "11px",
  marginBottom: "11px",
  ...fontStyleBlogListHeadline[resolutionCoarse]
}));

function BlogHeadline({ type = "blog" }) {
  const { t } = useTranslation();
  const { resolutionCoarse } = useViewerClient();

  return (
    <Comp resolutionCoarse={resolutionCoarse}>
      <Headline resolutionCoarse={resolutionCoarse}>
        {t(`blog.headline.${type}`)}
      </Headline>
    </Comp>
  );
}

export default BlogHeadline;
