import React from "react";

function ProcessMobilePre({ backgroundColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.84 207.84">
      <path
        fill={backgroundColor}
        d="M3 71.84h68.84V3H3zm39.29-48.77L45 20.32l16.18 16.14-16.13 16.18-2.76-2.76 11.46-11.45H14.63v-3.9h39.11zM71.84 204.84V136H3v68.84zm-58.95-20.6v-27.65h27.66v11.88h20.23L49.32 157l2.75-2.76 16.14 16.16-16.13 16.18-2.76-2.76 11.46-11.45H40.55v11.88H12.89z"
      />
      <path fill={backgroundColor} d="M16.79 160.49H36.64V180.34H16.79z" />
      <path
        fill={backgroundColor}
        d="M0 0v207.84h74.84V0zm0 73.34V0h74.84v74.84H0zm37.57 43.52a2 2 0 112-2 2 2 0 01-2 2zm2 9a2 2 0 11-.59-1.42 2 2 0 01.59 1.42zm-2-20a2 2 0 112-2 2 2 0 01-2 2zm0-11a2 2 0 112-2 2 2 0 01-2 2zM39 83.27a2 2 0 110-2.83 2 2 0 01.59 1.42 2 2 0 01-.59 1.41zm35.84 124.57H0V133h74.84z"
      />
    </svg>
  );
}

export default ProcessMobilePre;
