import styled from "styled-components";
import { colors } from "../utils/theme";

const InputGroup = styled.div({
  display: "flex",
  border: `2px solid ${colors.GREY_DARK}`,
  borderRadius: "4px",
  lineHeight: "48px",
  background: colors.WHITE,
  marginBottom: "20px"
});

export default InputGroup;
