import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { flatten, uniq, has } from "lodash";
import { useContent } from "./content";
import {
  containsDiff,
  findWordsInTextReversed,
  getDiff,
  getMatchReversed,
  getParagraphsDiff,
  parseDate,
  reverse
} from "../utils/content";

export const CONTENT_PATH = "versions";

export const VersionedContentContext = createContext({
  versionLists: {},
  contentLists: {},
  search: () => {}
});

const getVersionMap = (list, dates = {}) => {
  if (list && list.length > 0) {
    list.forEach(({ sections, versions }) => {
      getVersionMap(sections, dates);
      if (versions && versions.length > 0) {
        versions.forEach(({ date }) => {
          dates[date] = date;
        });
      }
    });
  }
  return dates;
};

const findLatestVersion = (selectedDate, versions) => {
  // assume descending order
  return versions.find(({ date }) => date <= selectedDate) || {};
};

const getVersionedContentList = (list, selected, idMap) => {
  if (!list || !list.length) {
    return list;
  }
  return list.map(
    ({
      id: sectionId,
      sections: subsections,
      versions,
      headline,
      ...other
    }) => {
      const versionedSections = getVersionedContentList(
        subsections,
        selected,
        idMap
      );
      if (versions && versions.length > 0) {
        const v1 = findLatestVersion(selected[0], versions);
        const res = {
          ...other,
          id: v1.id,
          headline: v1.headline,
          sections: versionedSections
        };
        if (idMap) {
          if (v1.id) {
            idMap[v1.id] = true;
          }
        }
        if (selected[1]) {
          const v2 = findLatestVersion(selected[1], versions);

          if (!v2 || !v2.id) {
            // new content
            res.id = `${v1.id}_`;
            res.versionIds = [v1.id];
            res.headline = getDiff(v1.headline, "");
            res.hasHeadlineDiff = true;
          } else if (v2.id !== v1.id) {
            // versions differ
            const diffHeadline = getDiff(v1.headline, v2.headline || "");

            res.id = `${v1.id}_${v2.id}`;
            res.versionIds = [v1.id, v2.id];
            res.headline = diffHeadline;
            res.hasHeadlineDiff = containsDiff(diffHeadline);

            if (idMap) {
              idMap[v2.id] = true;
            }
          }
        }
        return res;
      }
      // no versions
      return { id: sectionId, sections: versionedSections, ...other };
    }
  );
};

const computeContentDiff = (list, contents) => {
  if (!list || !list.length) {
    return {};
  }
  let sectionsHeadlineDiffCount = 0;
  let sectionsContentDiffCount = 0;
  let sectionsDiffCount = 0;
  list.forEach((section) => {
    const { versionIds, sections } = section;
    if (sections && sections.length) {
      const {
        sectionsHeadlineDiffCount: sHDC,
        sectionsContentDiffCount: sCDC,
        sectionsDiffCount: sDC
      } = computeContentDiff(sections, contents);
      sectionsHeadlineDiffCount += sHDC || 0;
      sectionsContentDiffCount += sCDC || 0;
      sectionsDiffCount += sDC || 0;
      section.sectionsHeadlineDiffCount = sHDC;
      section.sectionsContentDiffCount = sCDC;
      section.sectionsDiffCount = sDC;
    }

    if (versionIds) {
      const diffContentID = `${versionIds[0]}_${versionIds[1] || ""}`;
      if (!contents[CONTENT_PATH][diffContentID]) {
        // compute diff for paragraphs
        const c1 = contents[CONTENT_PATH][versionIds[0]];
        const { paragraphs: p1 } = c1;
        let p2;
        if (versionIds[1]) {
          const c2 = contents[CONTENT_PATH][versionIds[1]];
          p2 = c2.paragraphs || [];
        } else {
          p2 = [];
        }
        const paragraphs = getParagraphsDiff(p1, p2);
        // add diff to contents
        contents[CONTENT_PATH][diffContentID] = { paragraphs };
      }
      // add metaInfo to list
      section.hasContentDiff = !!contents[CONTENT_PATH][
        diffContentID
      ].paragraphs.find(
        ({ headline, text }) =>
          containsDiff(text) || (headline && containsDiff(headline))
      );
      if (section.hasHeadlineDiff) {
        sectionsHeadlineDiffCount += 1;
      }
      if (section.hasContentDiff) {
        sectionsContentDiffCount += 1;
      }
      if (section.hasHeadlineDiff || section.hasContentDiff) {
        sectionsDiffCount += 1;
      }
    }
  });
  return {
    sectionsHeadlineDiffCount,
    sectionsContentDiffCount,
    sectionsDiffCount
  };
};

export const VersionedContentProvider = ({ children }) => {
  const {
    contentLists,
    fetchContentList,
    fetchContents,
    contents,
    setContents
  } = useContent();
  // available versions per content area
  const [versionLists, setVersionLists] = useState({});
  // currently selected versions dates per content area
  const [selectedVersions, setSelectedVersions] = useState({});
  // mode per content area
  const [modes, setModes] = useState({});
  // sections list for selected dates per content area
  const [vContentLists, setVContentLists] = useState({});

  const fillContentList = useCallback(
    (section, regEx) => {
      let matchCount = 0;
      // filter subsections
      const filteredSections = [];
      if (section.sections && section.sections.length) {
        section.sections.forEach((subSection) => {
          const sectionFiltered = fillContentList(subSection, regEx);
          if (sectionFiltered) {
            filteredSections.push(sectionFiltered);
            matchCount += sectionFiltered.matchCount || 0;
          }
        });
      }
      // filter / mark headline
      if (section.headline && regEx) {
        const res = getMatchReversed(section.headline, regEx);
        matchCount += res.count;
        if (res.count) {
          section.headline = res.text;
        }
      }
      // get contents
      const content = contents[CONTENT_PATH][section.id];
      if (content && content.paragraphs) {
        const paragraphs = content.paragraphs.map((paragraph) => {
          // mark paragraphs
          const { text, headline, ...other } = paragraph;

          if (regEx) {
            const { text: newHeadline, count: hlCount } = getMatchReversed(
              headline,
              regEx
            );
            matchCount += hlCount;

            const { text: newText, count: tCount } = getMatchReversed(
              text,
              regEx
            );
            matchCount += tCount;

            return {
              headline: newHeadline,
              text: newText,
              ...other
            };
          }
          return paragraph;
        });
        section.paragraphs = paragraphs;
      }

      section.sections = filteredSections.length ? filteredSections : null;
      if (regEx) {
        section.matchCount = matchCount;
      }
      return section;
    },
    [contents]
  );

  const setSelectedContent = useCallback(
    async (key, selVers, fetch) => {
      const list = contentLists[key];
      // content list based on default selection
      const idMap = {};
      const sections = getVersionedContentList(
        list.sections,
        selVers.map(({ value }) => value),
        idMap
      );
      let metaInfo = {};
      if (selVers[1] || fetch) {
        const paragraphIDs = Object.keys(idMap);
        // load entire content
        const contentsMap = await fetchContents(
          paragraphIDs.filter((id) => !contents[id]),
          "versions",
          false
        );
        // set content, count
        const mergedContents = { ...contents, ...contentsMap };
        metaInfo = computeContentDiff(sections, mergedContents);
        setContents(mergedContents);
      }
      const vContentList = {
        sections,
        date: selVers[0] ? selVers[0].value : null,
        dateOld: selVers[1] ? selVers[1].value : null,
        displayParams: {
          contentPath: CONTENT_PATH
        },
        ...metaInfo
      };
      await setVContentLists({ ...vContentLists, [key]: vContentList });
    },
    [contentLists, contents, fetchContents, setContents, vContentLists]
  );

  const setCompareToVersion = useCallback(
    async (key, s, fetch) => {
      const selVers = [versionLists[key][0]];
      if (s > 0) {
        const compareToVersion = versionLists[key][parseInt(s, 10)];
        selVers.push(compareToVersion);
        setModes({ ...modes, [key]: "compare" });
      }
      if (selVers[0]) {
        setSelectedVersions({ ...selectedVersions, [key]: selVers });
        await setSelectedContent(key, selVers, fetch);
      }
    },
    [modes, selectedVersions, setSelectedContent, versionLists]
  );

  useEffect(() => {
    const keys = Object.keys(contentLists);
    keys.forEach((key) => {
      const isVersioned = has(contentLists[key], "sections.0.versions.0");
      if (!vContentLists[key] && isVersioned) {
        const list = contentLists[key];
        const versionMap = getVersionMap(list.sections);
        const dates = Object.values(versionMap).sort((a, b) => b - a);
        const versionList = dates.map((date, index) => ({
          label: parseDate(date),
          value: date,
          key: index
        }));
        setVersionLists({ ...versionLists, [key]: versionList });

        // last version only
        const selVers = [versionList[0]];
        if (selVers[0]) {
          setSelectedVersions({ ...selectedVersions, [key]: selVers });
          setSelectedContent(key, selVers);
        }
      }
    });
  }, [
    contentLists,
    selectedVersions,
    setSelectedContent,
    vContentLists,
    versionLists
  ]);

  const setMode = useCallback(
    async (key, mode) => {
      if (modes[key] !== mode) {
        if (mode === "show") {
          setCompareToVersion(key, 0);
        } else if (mode === "search" || mode === "print") {
          await setCompareToVersion(key, 0, true);
        }
        setModes({ ...modes, [key]: mode });
      }
    },
    [modes, setCompareToVersion]
  );

  const findWords = useCallback(
    (regEx, { headline, id, sections }) => {
      const words = [];
      if (sections && sections.length) {
        const sectionWords = flatten(
          sections.map((section) => findWords(regEx, section))
        );
        words.push(...sectionWords);
      }

      if (headline) {
        words.push(...findWordsInTextReversed(regEx, headline));
      }
      if (contents.versions && id) {
        const content = contents.versions[id];
        if (content) {
          const { paragraphs } = content;
          if (paragraphs && paragraphs.length > 0) {
            const contentWords = flatten(
              paragraphs.map(({ headline, text }) => {
                const pWords = [];
                if (headline) {
                  pWords.push(...findWordsInTextReversed(regEx, headline));
                }
                if (text) {
                  pWords.push(...findWordsInTextReversed(regEx, text));
                }
                return pWords;
              })
            );
            if (contentWords) {
              words.push(...contentWords);
            }
          }
        }
      }
      return words;
    },
    [contents]
  );

  const getSearchOptions = useCallback(
    async (key, term, callback) => {
      // const t1 = new Date().getTime();
      // reverse for old browsers like safari that don't support lookbehind assertions
      const regEx = new RegExp(
        `[\\wöäüßÄÖÜ\\u2012-\\u2014-]*${reverse(
          term,
          true,
          true
        )}[\\wöäüßÄÖÜ\\u2012-\\u2014-]*(?![^<^>]*<[^>]*)`,
        "gi"
      );
      const resultList = findWords(regEx, vContentLists[key]);
      resultList.sort();
      // const t2 = new Date().getTime();
      // const time = t2 - t1;
      // const count = resultList.length;
      // console.log(`Found ${count} words in ${time}ms`);
      // const results = uniq(resultList).map((value) => ({ label: value, value }));
      callback(uniq(resultList));
    },
    [findWords, vContentLists]
  );

  const search = useCallback(
    (key, searchString) => {
      // assume all content is loaded at this time an there is a selected version
      const list = contentLists[key];
      // content list based on selected version
      if (!selectedVersions[key] || !selectedVersions[key][0]) {
        return null;
      }
      const sections = getVersionedContentList(list.sections, [
        selectedVersions[key][0].value
      ]);

      if (!searchString) {
        // reset
        setVContentLists({
          ...vContentLists,
          [key]: {
            sections,
            date: selectedVersions[key][0].value,
            displayParams: {
              contentPath: CONTENT_PATH
            }
          }
        });
        return;
      }

      const vContentList = {
        sections,
        date: selectedVersions[key][0].value,
        displayParams: {
          contentPath: CONTENT_PATH,
          openMulti: true,
          useFilter: true
        }
      };
      // reverse for old browsers like safari that don't support lookbehind assertions
      const regEx = new RegExp(
        `(${reverse(searchString, true)})(?![^<^>]*<[^>]*)`,
        "gi"
      );
      fillContentList(vContentList, regEx);
      setVContentLists({ ...vContentLists, [key]: vContentList });
    },
    [contentLists, fillContentList, selectedVersions, vContentLists]
  );

  const getCompleteContent = useCallback(
    (key) => {
      const list = contentLists[key];
      const sections = getVersionedContentList(list.sections, [
        selectedVersions[key][0].value
      ]);
      const vContentList = {
        sections,
        date: selectedVersions[key][0].value
      };
      fillContentList(vContentList);
      return vContentList;
    },
    [contentLists, fillContentList, selectedVersions]
  );

  return (
    <VersionedContentContext.Provider
      value={{
        setCompareToVersion,
        selectedVersions,
        versionLists,
        contentLists: vContentLists,
        fetchContentList,
        modes,
        setMode,
        getSearchOptions,
        search,
        getCompleteContent
      }}
    >
      {children}
    </VersionedContentContext.Provider>
  );
};

export const useVersionedContent = () => {
  return useContext(VersionedContentContext);
};
