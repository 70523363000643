export const breakpoints = {
  mobileL: 450,
  tabletP: 768,
  tabletL: 1024,
  desktop: 1280,
  wide: 1920
};

const maxContentWidth = "1280px";
export const maxTextWidth = "1060px";
export const maxTextColumnWidth = "730px";

export const colors = {
  // ORANGE: "#F08F34",
  // PINK: "#FF3FF5",
  TURQUOISE: "#31E8C5",
  CORAL: "#E86031",
  BLUE_LIGHT: "#EDFCFA",
  BLUE_MEDIUM: "#A6EEE6",
  GREY_DARK: "#1F1F1F",
  GREY_MEDIUM_DARK: "#6D6D6D",
  GREY_MEDIUM: "#B5B5B5",
  GREY_MEDIUM_LIGHT: "#E8E8E8",
  GREY_LIGHT: "#F5F5F5",
  GREY_TOASTER: "#707070",
  RED: "#FF4D4D",
  TEAL: "#F6F8F9",
  WHITE: "#FFFFFF",
  YELLOW_LIGHT: "#FFFDE2",
  YELLOW_MEDIUM: "#FEF68B",
  LILA: "#CD5980"
};

export const backgrounds = {
  dark: colors.GREY_DARK,
  medium: colors.GREY_MEDIUM_DARK,
  transparent: "transparent",
  grey: colors.GREY_LIGHT,
  yellow: colors.YELLOW_LIGHT,
  blue: colors.BLUE_LIGHT,
  teal: colors.TEAL,
  white: colors.WHITE
};

export const gradients = [
  ["#15C1FF", "#FE6A2A"], // himmelblau -> apricot
  ["#C2466E", "#E7B04B"], // boardeau -> gelb
  ["#FE6A2A", "#00C7CE"], // apricot -> türkis

  ["#00C7CE", "#FE6A2A"], // türkis -> apricot
  ["#8F0A95", "#FF9E67"], // lila -> orange
  ["#FF9E67", "#8F0A95"], // orange -> lila
  ["#9170E7", "#7DE3D1"], // lila -> türkis
  ["#7DE3D1", "#9170E7"] // türkis -> lila
];

export const transitionSpeed = "250ms";

export const fontFamily = {
  normal: "Rubik, Verdana, Arial, Helvetica Neue, Helvetica, sans-serif",
  headline: "Fraunces, Verdana, Arial, Helvetica Neue, Helvetica, sans-serif",
  printSerif: "Times New Roman, Times, Georgia, serif",
  printSans: "Arial, Verdana, Helvetica, sans-serif"
};

export const tickerHeight = 40;
export const tickerHeightMobile = 36;

export const pageBarHeight = 60;

export const contentSpacing = {
  mobileP: "27px",
  mobileL: "27px",
  tabletP: "72px",
  tabletL: "100px",
  desktop: "35px",
  wide: "50px"
};

export const boxSpacing = {
  mobileP: "15px",
  mobileL: "15px",
  tabletP: "72px",
  tabletL: "100px"
};

export const headerGraphicHeight = 85;
export const burgerIconHeight = 38;
export const burgerIconWidth = 42;
export const headerGraphicHeightMobile = 58;
export const headerSpacingDefault = 50;
export const headerSpacingSticky = 20;
export const headerSpacingMobile = 7;

const headerHeightDefault = headerGraphicHeight + 2 * headerSpacingDefault;
const headerHeightSticky = headerGraphicHeight + 2 * headerSpacingSticky;
const headerHeightMobile = headerGraphicHeightMobile + 2 * headerSpacingMobile;

export const mobileGraphicHeight = "68px";

export const indentAccordionLeftMobile = 16;
export const indentAccordionLeftDesktop = 16;
export const indentAccordionRight = 42;

export const fontSizeNavBar = {
  mobileP: "24px",
  mobileL: "24px",
  tabletP: "24px",
  tabletL: "24px",
  desktop: "24px",
  wide: "24px"
};

export const fontSizeNavBarFooter = {
  mobileP: "16px",
  mobileL: "16px",
  tabletP: "16px",
  tabletL: "16px"
};

export const toaster = {
  mobileP: { width: "275px" },
  mobileL: { width: "275px" },
  tabletP: { width: "624px" },
  tabletL: { width: "624px" },
  desktop: { width: "624px" },
  wide: { width: "730px" }
};

export const header = {
  mobileP: {
    logo: {
      height: 56
    },
    main: {
      height: 72
    }
  },
  mobileL: {
    logo: {
      height: 56
    },
    main: {
      height: 72
    }
  },
  tabletP: {
    logo: {
      height: 64
    },
    main: {
      height: 96
    }
  },
  tabletL: {
    logo: {
      height: 64
    },
    main: {
      height: 96
    }
  },
  desktop: {
    logo: {
      height: 72
    },
    main: {
      height: 112
    }
  },
  wide: {
    logo: {
      height: headerGraphicHeight
    },
    main: {
      height: 128
    }
  }
};

export const fontSizeFooter = {
  mobile: "16px",
  tablet: "16px",
  desktop: "20px"
};

export const fontSizeH1 = {
  mobile: "30px",
  tablet: "36px",
  desktop: "40px"
};

export const fontStyleOpenerStage = {
  mobile: {
    fontSize: "26px"
  },
  tablet: {
    fontSize: "32px"
  },
  desktop: {
    fontSize: "36px"
  }
};

export const fontStyleH2 = {
  mobile: {
    fontSize: "24px"
  },
  tablet: {
    fontSize: "30px"
  },
  desktop: {
    fontSize: "34px"
  }
};

export const fontStyleH3 = {
  mobile: {
    fontSize: "20px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "28px",
    lineHeight: "42px"
  }
};

export const fontStyleH4 = {
  mobile: {
    fontSize: "18px",
    marginBlockStart: "16px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "20px",
    marginBlockStart: "1.2em",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "20px",
    marginBlockStart: "1.5em",
    lineHeight: "30px"
  }
};

export const fontStyleH4Print = {
  mobile: {
    fontSize: "18px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "20px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "20px",
    lineHeight: "30px"
  }
};

export const fontStyleAbstract = {
  mobile: {
    fontSize: "22px",
    lineHeight: "30px",
    marginBlockStart: "1em",
    marginBlockEnd: "2.5em"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "34px",
    marginBlockStart: "1em",
    marginBlockEnd: "1.5em"
  },
  desktop: {
    fontSize: "28px",
    lineHeight: "42px",
    marginBlockStart: "1em",
    marginBlockEnd: "1.5em",
    maxWidth: maxTextWidth
  }
};

export const textSpacing = {
  mobile: "18px",
  tablet: "12px",
  desktop: "12px"
};

export const fontStyleText = {
  mobile: {
    fontSize: "18px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "20px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "20px",
    lineHeight: "30px"
  }
};

export const fontStyleMeta = {
  mobileP: {
    fontSize: "18px",
    lineHeight: "22px"
  },
  mobileL: {
    fontSize: "18px",
    lineHeight: "22px"
  },
  tabletP: {
    fontSize: "18px",
    lineHeight: "22px"
  },
  tabletL: {
    fontSize: "19px",
    lineHeight: "24px"
  },
  desktop: {
    fontSize: "20px",
    lineHeight: "24px"
  },
  wide: {
    fontSize: "20px",
    lineHeight: "24px"
  }
};

export const linkStyles = {
  a: {
    fontWeight: 600,
    // whiteSpace: "nowrap",
    svg: {
      position: "relative",
      marginRight: "0.25em",
      height: "0.75em",
      width: "0.75em"
    }
  },
  ".faq-link": {
    display: "inline-block",
    fontWeight: "normal",
    whiteSpace: "normal",
    svg: {
      height: "0.6em",
      width: "0.6em"
    }
  },
  ".file-link svg": {
    height: "1em",
    width: "auto",
    marginLeft: "0.5em",
    marginBottom: "-0.1em"
  }
};

export const diffStyles = {
  "ins": {
    background: colors.YELLOW_MEDIUM,
    textDecoration: "inherit"
  },
  "del": {
    textDecoration: "line-through"
  },
  ".match": {
    background: colors.BLUE_MEDIUM
  }
};

export const fontStyleAccordion = {
  0: {
    mobile: {
      fontSize: "22px",
      lineHeight: "32px",
      fontWeight: 600,
      marginBlockStart: "0.5em",
      marginBlockEnd: "0.5em"
    },
    tablet: {
      fontSize: "28px",
      lineHeight: "36px",
      fontWeight: 600,
      marginBlockStart: "0.5em",
      marginBlockEnd: "0.5em"
    },
    desktop: {
      fontSize: "28px",
      lineHeight: "42px",
      fontWeight: 600,
      marginBlockStart: "0.4em",
      marginBlockEnd: "0.4em"
    }
  },
  1: {
    mobile: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 600,
      marginBlockStart: "0.6em",
      marginBlockEnd: "0.6em"
    },
    tablet: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 600,
      marginBlockStart: "0.5em",
      marginBlockEnd: "0.5em"
    },
    desktop: {
      fontSize: "24px",
      lineHeight: "34px",
      fontWeight: 600,
      marginBlockStart: "16px",
      marginBlockEnd: "16px"
    }
  }
};

export const controlStyleAccordion = {
  0: {
    mobile: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 600,
      marginBlockStart: "12px",
      marginBlockEnd: "12px"
    },
    tablet: {
      fontSize: "32px",
      lineHeight: "36px",
      fontWeight: 600,
      marginBlockStart: "0.5em",
      marginBlockEnd: "0.5em"
    },
    desktop: {
      fontSize: "40px",
      lineHeight: "40px",
      fontWeight: 600,
      marginBlockStart: "0.3em",
      marginBlockEnd: "0.3em"
    }
  },
  1: {
    mobile: {
      fontSize: "20px",
      lineHeight: "28px",
      paddingTop: "0.6em",
      paddingBottom: "0.6em"
    },
    tablet: {
      fontSize: "24px",
      lineHeight: "30px",
      marginBlockStart: "0.5em",
      marginBlockEnd: "0.5em"
    },
    desktop: {
      fontSize: "24px",
      lineHeight: "34px",
      marginBlockStart: "16px",
      marginBlockEnd: "16px"
    }
  }
};

export const containerStylesAccordion = {
  0: {
    mobile: {
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    tablet: {
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    desktop: {
      paddingTop: "20px",
      paddingBottom: "20px"
    }
  },
  1: {
    mobile: {
      paddingBottom: "50px"
    },
    tablet: {
      paddingBottom: "50px"
    },
    desktop: {
      paddingBottom: "60px"
    }
  }
};

export const fontStyleContact = {
  mobile: {
    fontSize: "20px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "40px"
  },
  desktop: {
    fontSize: "28px",
    lineHeight: "42px"
  }
};

export const styleTabNav = {
  mobileP: {
    marginLeft: `-${contentSpacing.mobileP}`,
    marginRight: `-${contentSpacing.mobileP}`
  },
  mobileL: {
    marginLeft: `-${contentSpacing.mobileL}`,
    marginRight: `-${contentSpacing.mobileL}`
  },
  tabletP: {
    marginLeft: `-${contentSpacing.tabletP}`,
    marginRight: `-${contentSpacing.tabletP}`
  },
  tabletL: {},
  desktop: {},
  wide: {}
};

export const styleTabNavItem = {
  mobile: {
    textAlign: "left",
    height: "202px",
    padding: `${contentSpacing.mobileP} 35px`
  },
  tablet: {
    textAlign: "center",
    padding: "30px"
  },
  desktop: {
    textAlign: "center",
    padding: "30px 50px"
  }
};

export const containerStylesTabs = {
  mobileP: {
    flex: "0 1 100%"
  },
  mobileL: {
    flex: "0 1 100%"
  },
  tabletP: {
    flex: "0 1 100%",
    paddingLeft: "10%"
  },
  tabletL: {
    flex: "0 1 65.85%"
  },
  desktop: {
    flex: `0 1 ${maxTextColumnWidth}`
  },
  wide: {
    flex: `0 1 ${maxTextColumnWidth}`
  }
};

export const fontStyleTabHeadline = {
  mobile: {
    fontSize: "18px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "30px",
    lineHeight: "36px"
  }
};

export const fontStyleTabSubline = {
  mobile: {
    fontSize: "18px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "20px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "24px",
    lineHeight: "34px"
  }
};

export const fontStyleTextBoxHeadline = {
  mobile: {
    fontSize: "24px",
    lineHeight: "28px",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "36px",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  },
  desktop: {
    fontSize: "24px",
    lineHeight: "36px",
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  }
};

export const fontStyleBlogListHeadline = {
  mobile: {
    fontSize: "22px",
    lineHeight: "28px"
  },
  tablet: {
    fontSize: "24px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "30px",
    lineHeight: "39px"
  }
};

export const fontStyleBlogListPostHeadline = fontStyleTabSubline;
export const fontStyleBlogLabel = {
  mobileP: {
    fontSize: "18px",
    lineHeight: "22px"
  },
  mobileL: {
    fontSize: "18px",
    lineHeight: "22px"
  },
  tabletP: {
    fontSize: "18px",
    lineHeight: "22px"
  },
  tabletL: {
    fontSize: "20px",
    lineHeight: "30px"
  },
  desktop: {
    fontSize: "18px",
    lineHeight: "22px"
  },
  wide: {
    fontSize: "18px",
    lineHeight: "22px"
  }
};

export const fontStyleCaption = {
  mobile: {
    fontSize: "14px",
    lineHeight: "18px",
    marginTop: "8px"
  },
  tablet: {
    fontSize: "16px",
    lineHeight: "20px",
    marginTop: "12px"
    // marginBottom: "20px"
  },
  desktop: {
    fontSize: "16px",
    lineHeight: "20px",
    marginTop: "12px"
    // marginBottom: "12px"
  }
};

export const blogSpacing = {
  grid: "24px",
  horizontal: {
    mobileP: "16px",
    mobileL: "16px",
    tabletP: "8px",
    tabletL: "10px",
    desktop: "9px",
    wide: "9px"
  },
  previewLinkRight: {
    mobileP: "32px",
    mobileL: "32px",
    tabletP: "8px",
    tabletL: "10px",
    desktop: "9px",
    wide: "9px"
  },
  previewPaddingBottom: {
    mobile: "24px",
    tablet: "32px",
    desktop: "20px"
  },
  previewMetaPaddingRight: {
    mobileP: 0,
    mobileL: 0,
    tabletP: "11px",
    tabletL: "14px",
    desktop: "15px",
    wide: "15px"
  },
  teaserMetaPaddingTop: {
    mobileP: "16px",
    mobileL: "16px",
    tabletP: "24px",
    tabletL: "72px",
    desktop: "32px",
    wide: "32px"
  },
  postTop: {
    mobile: "16px",
    tablet: "24px",
    desktop: "48px"
  },
  postHeadlineTop: {
    mobile: "16px",
    tablet: "16px",
    desktop: "32px"
  },
  postHeadlineIndent: {
    mobile: "16px",
    tablet: 0,
    desktop: "18px"
  },
  postContentTop: {
    mobile: "8px",
    tablet: "8px",
    desktop: "18px"
  },
  postVerticalS: {
    mobile: "24px",
    tablet: "32px",
    desktop: "40px"
  },
  postVerticalM: {
    mobile: "36px",
    tablet: "48px",
    desktop: "48px"
  }
};

export const blogPreviewShadow = {
  boxShadow: `0px 4px 8px ${colors.GREY_MEDIUM_DARK}`
};

export const fontStyleProcessHeadline = {
  mobile: {
    fontSize: "20px",
    lineHeight: "32px",
    marginTop: "1em",
    marginBottom: "1em"
  },
  tablet: {
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "1em",
    marginBottom: "1em"
  },
  desktop: {
    fontSize: "24px",
    lineHeight: "34px",
    marginTop: "0.5em",
    marginBottom: "1em"
  }
};
export const fontStyleProcessLabel = {
  mobile: {
    fontSize: "20px",
    lineHeight: "28px",
    height: mobileGraphicHeight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  tablet: {
    fontSize: "20px",
    lineHeight: "28px"
  },
  desktop: {
    fontSize: "20px",
    lineHeight: "30px"
  }
};
export const themeButtonReset = {
  padding: 0,
  border: "none",
  cursor: "pointer",
  backgroundColor: "transparent",
  fontFamily: "inherit",
  ":focus": {
    border: "none"
  },
  ":active": {
    border: "none",
    outline: "none"
  }
};

export const themePageBar = {
  height: pageBarHeight,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
};

export const themePageBarText = {
  fontFamily: fontFamily.normal,
  fontSize: "16px",
  color: colors.GREY_MEDIUM_DARK
};

export const themeTicker = {
  mobile: {
    fontSize: "18px",
    lineHeight: `${tickerHeightMobile}px`
  },
  tablet: {
    fontSize: "20px",
    lineHeight: `${tickerHeight}px`
  },
  desktop: {
    fontSize: "20px",
    lineHeight: `${tickerHeight}px`
  }
};

export const colorSchemes = {
  dark: {
    color: colors.WHITE,
    background: colors.GREY_DARK
  },
  light: {
    color: colors.GREY_DARK,
    background: colors.WHITE
  }
};
export const themeTopBarButton = {
  fontFamily: fontFamily.normal,
  fontSize: "20px",
  ...colorSchemes.dark
};

export const themeFontStage = {
  fontFamily: fontFamily.headline,
  fontWeight: "bold"
};

export const themeFontNavBar = {
  fontFamily: fontFamily.normal,
  fontWeight: "normal",
  color: colors.WHITE
};

export const themeStage = {
  mobileP: {
    stageHeight: 370,
    headerHeightDefault: headerHeightMobile,
    headerHeightSticky: headerHeightMobile
  },
  mobileL: {
    stageHeight: 430,
    headerHeightDefault: headerHeightMobile,
    headerHeightSticky: headerHeightMobile
  },
  tabletP: {
    stageHeight: 530,
    headerHeightDefault,
    headerHeightSticky
  },
  tabletL: {
    stageHeight: 530,
    headerHeightDefault,
    headerHeightSticky
  },
  desktop: {
    stageHeight: 645,
    headerHeightDefault,
    headerHeightSticky
  },
  wide: {
    stageHeight: 645,
    headerHeightDefault,
    headerHeightSticky
  }
};
export const themeStageContainer = {
  mobileP: {
    paddingTop: "10px",
    paddingBottom: "10px"
    // fontSize: `20.6vw`,
    // lineHeight: `106px`
  },
  mobileL: {
    paddingTop: "26px",
    paddingBottom: "26px"
    // fontSize: `20.6vw`,
    // lineHeight: `126px`
  },
  tabletP: {
    paddingTop: "46px",
    paddingBottom: "46px"
    // fontSize: `18vw`,
    // lineHeight: `146px`
  },
  tabletL: {
    paddingTop: "46px",
    paddingBottom: "46px"
    // fontSize: `12.3vw`,
    // lineHeight: `146px`
  },
  desktop: {
    paddingTop: "95px",
    paddingBottom: "95px",
    fontSize: "11vw"
    // lineHeight: `235px`,
    // justifyContent: "center"
  },
  wide: {
    paddingTop: "95px",
    paddingBottom: "95px",
    // fontSize: `220px`,
    // lineHeight: `235px`,
    justifyContent: "center"
  }
};

export const themeHeadline = {
  underline: {
    borderBottom: `2px solid ${colors.GREY_DARK}`
  }
};

export const containerWidthsDefault = {
  mobileP: {},
  mobileL: {},
  tabletP: {},
  tabletL: {},
  desktop: {},
  wide: {
    flexBase: "1920px"
  }
};

export const containerWidthsText = {
  mobileP: {
    paddingLeft: contentSpacing.mobileP,
    paddingRight: contentSpacing.mobileP,
    paddingBottom: "2em"
  },
  mobileL: {
    paddingLeft: contentSpacing.mobileL,
    paddingRight: contentSpacing.mobileL,
    paddingBottom: "2em"
  },
  tabletP: {
    paddingLeft: contentSpacing.tabletP,
    paddingRight: contentSpacing.tabletP,
    paddingBottom: "2em"
  },
  tabletL: {
    paddingLeft: contentSpacing.tabletL,
    paddingRight: contentSpacing.tabletL,
    paddingBottom: "2em"
  },
  desktop: {
    flexBase: maxTextWidth,
    paddingBottom: "2em"
  },
  wide: {
    flexBase: maxContentWidth,
    paddingBottom: "2em"
  }
};

export const containerWidthsFull = {
  mobileP: {},
  mobileL: {},
  tabletP: {
    paddingLeft: contentSpacing.tabletP,
    paddingRight: contentSpacing.tabletP
  },
  tabletL: {
    paddingLeft: contentSpacing.tabletL,
    paddingRight: contentSpacing.tabletL
  },
  desktop: {
    flexBase: maxTextWidth
  },
  wide: {
    flexBase: maxContentWidth
  }
};

export const containerWidthsTabs = {
  mobileP: {
    paddingLeft: boxSpacing.mobileP,
    paddingRight: boxSpacing.mobileP,
    paddingBottom: "40px"
  },
  mobileL: {
    paddingLeft: boxSpacing.mobileL,
    paddingRight: boxSpacing.mobileL,
    paddingBottom: "40px"
  },
  tabletP: {
    paddingLeft: boxSpacing.tabletP,
    paddingRight: boxSpacing.tabletP,
    paddingBottom: "40px"
  },
  tabletL: {
    paddingLeft: boxSpacing.tabletL,
    paddingRight: boxSpacing.tabletL,
    paddingBottom: "40px"
  },
  desktop: {
    flexBase: maxTextWidth,
    paddingBottom: "40px"
  },
  wide: {
    flexBase: maxContentWidth,
    paddingBottom: "40px"
  }
};

export const containerWidthsIndent = {
  mobileP: {
    paddingLeft: contentSpacing.mobileP,
    paddingRight: contentSpacing.mobileP
  },
  mobileL: {
    paddingLeft: contentSpacing.mobileL,
    paddingRight: contentSpacing.mobileL
  },
  tabletP: {
    paddingLeft: contentSpacing.tabletP,
    paddingRight: contentSpacing.tabletP
  },
  tabletL: {
    paddingLeft: contentSpacing.tabletL,
    paddingRight: contentSpacing.tabletL
  },
  desktop: {
    flexBase: maxTextWidth
  },
  wide: {
    flexBase: maxTextWidth
  }
};

export const containerPaddingsText = {
  mobileP: {
    paddingTop: "30px",
    paddingBottom: "40px"
  },
  mobileL: {
    paddingTop: "30px",
    paddingBottom: "40px"
  },
  tabletP: {
    paddingTop: "85px",
    paddingBottom: "110px"
  },
  tabletL: {
    paddingTop: "85px",
    paddingBottom: "110px"
  },
  desktop: {
    paddingTop: "85px",
    paddingBottom: "110px"
  },
  wide: {
    paddingTop: "85px",
    paddingBottom: "110px"
  }
};

export const containerPaddingBottomText = {
  mobileP: {
    paddingBottom: "40px"
  },
  mobileL: {
    paddingBottom: "40px"
  },
  tabletP: {
    paddingBottom: "110px"
  },
  tabletL: {
    paddingBottom: "110px"
  },
  desktop: {
    paddingBottom: "110px"
  },
  wide: {
    paddingBottom: "110px"
  }
};

export const containerPaddingsTopText = {
  mobileP: {
    paddingTop: "30px"
  },
  mobileL: {
    paddingTop: "30px"
  },
  tabletP: {
    paddingTop: "85px"
  },
  tabletL: {
    paddingTop: "85px"
  },
  desktop: {
    paddingTop: "85px"
  },
  wide: {
    paddingTop: "85px"
  }
};

export const containerPaddingsBox = {
  mobileP: {
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  mobileL: {
    paddingTop: "24px",
    paddingBottom: "24px"
  },
  tabletP: {
    paddingTop: "60px",
    paddingBottom: "60px"
  },
  tabletL: {
    paddingTop: "60px",
    paddingBottom: "60px"
  },
  desktop: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  wide: {
    paddingTop: "80px",
    paddingBottom: "80px"
  }
};

export const containerPaddingsTabs = {
  mobileP: {
    paddingBottom: "24px"
  },
  mobileL: {
    paddingBottom: "24px"
  },
  tabletP: {
    paddingBottom: "60px"
  },
  tabletL: {
    paddingBottom: "60px"
  },
  desktop: {
    paddingBottom: "80px"
  },
  wide: {
    paddingBottom: "80px"
  }
};

export const containerTextBox = {
  mobile: {
    marginLeft: `-${indentAccordionLeftMobile}px`,
    marginRight: `-${indentAccordionRight}px`,
    paddingBottom: "4px",
    paddingLeft: `${indentAccordionLeftMobile + 8}px`,
    paddingRight: `${indentAccordionRight + 8}px`,
    paddingTop: "4px",
    position: "relative"
  },
  tablet: {
    padding: "8px 16px"
  },
  desktop: {
    padding: "10px 32px"
  }
};

export const containerExpandableTextBox = {
  mobileP: {
    marginLeft: `-${contentSpacing.mobileP}`,
    marginRight: `-${contentSpacing.mobileP}`,
    padding: `18px ${contentSpacing.mobileP} 50px ${contentSpacing.mobileP}`
  },
  mobileL: {
    marginLeft: `-${contentSpacing.mobileP}`,
    marginRight: `-${contentSpacing.mobileP}`,
    padding: `18px ${contentSpacing.mobileL} 50px ${contentSpacing.mobileL}`
  },
  tabletP: {
    padding: "28px 22px 60px 65px",
    marginTop: "60px",
    marginBottom: "60px"
  },
  tabletL: {
    padding: "28px 65px 60px 65px",
    marginTop: "60px",
    marginBottom: "60px"
  },
  desktop: {
    padding: "18px 32px 50px 32px",
    marginTop: "60px",
    marginBottom: "60px"
  },
  wide: {
    padding: "18px 32px 50px 32px",
    marginTop: "60px",
    marginBottom: "60px"
  }
};

export const containerTextBoxIndent = {
  /* mobile: {
    "1": {
      marginLeft: "40px"
    },
    "2": {
      marginLeft: "80px"
    }
  }, */
  tablet: {
    "1": {
      marginLeft: "0px"
    },
    "2": {
      marginLeft: "80px"
    }
  },
  desktop: {
    "1": {
      marginLeft: "40px"
    },
    "2": {
      marginLeft: "80px"
    }
  }
};

export const nestedListIndent = {
  mobileL: "0px",
  mobileP: "0px",
  tabletP: "0px",
  tabletL: "16px"
};

export const secondNestedListIndent = {
  mobileL: "0px",
  mobileP: "0px",
  tabletP: "16px",
  tabletL: "16px"
};

export const decoratorSizes = {
  mobileP: "30px",
  mobileL: "30px",
  tabletP: "88px",
  tabletL: "88px",
  desktop: "88px",
  wide: "108px"
};

export const columnGutter = {
  wide: 20,
  desktop: 20,
  tabletL: 10,
  tabletP: 10
};
