import React from "react";

function CopyLinkIcon({ fill }) {
  const pathProps = fill ? { fill } : {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g transform="translate(-196 -360)">
        <g
          transform="translate(196 360)"
          fill="none"
          stroke="#1f1f1f"
          strokeWidth="2"
        >
          <circle cx="30" cy="30" r="30" stroke="none" />
          <circle cx="30" cy="30" r="29" fill="none" />
        </g>
        <g transform="translate(210.104 374.102)">
          <path
            d="M15,17.189a6.449,6.449,0,0,0,9.725.7l3.869-3.869A6.448,6.448,0,1,0,19.475,4.9L17.257,7.1"
            transform="translate(-1.683)"
            fill="none"
            stroke="#1f1f1f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            {...pathProps}
          />
          <path
            d="M18.476,16.078a6.449,6.449,0,0,0-9.725-.7L4.882,19.251A6.448,6.448,0,1,0,14,28.371l2.2-2.206"
            transform="translate(0 -1.469)"
            fill="none"
            stroke="#1f1f1f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            {...pathProps}
          />
        </g>
      </g>
    </svg>
  );
}

export default CopyLinkIcon;
