import React from "react";

function ProcessTabletPre({ backgroundColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 446.84 74.84">
      <path
        fill={backgroundColor}
        d="M71.84 3H3v68.84h68.84zM61.35 36.5L45.21 52.64l-2.75-2.76 11.45-11.45H14.8v-3.9h39.11L42.45 23.07l2.76-2.75 16.14 16.14z"
      />
      <path fill={backgroundColor} d="M251.96 27.49H271.81V47.34H251.96z" />
      <path
        fill={backgroundColor}
        d="M307 3h-68.83v68.84H307zm-3.63 34.44l-16.13 16.14-2.75-2.75 11.45-11.46h-20.23v11.88h-27.65V23.59h27.65v11.88h20.23L284.48 24l2.76-2.76 16.14 16.16z"
      />
      <path
        fill={backgroundColor}
        d="M446.84 0H0v74.84h446.84zm-372 74.84H0V0h74.84zm8.33-36a2 2 0 01-2.83 0 2 2 0 01-.58-1.42 2 2 0 014 0 2 2 0 01-.59 1.42zm8.36.58a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.76 0a2 2 0 112-2 2 2 0 01-1.95 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm9.77 0a2 2 0 112-2 2 2 0 01-2 2zm11.18-.58a2 2 0 01-1.42.58 2 2 0 01-1.41-.58 2 2 0 01-.59-1.42 2 2 0 01.59-1.41 2 2 0 011.41-.59 2 2 0 012 2 2 2 0 01-.58 1.42zm80.34 36h-74.84V0H310zm7.57-36a2 2 0 01-2.83 0 2 2 0 01-.58-1.42 2 2 0 014 0 2 2 0 01-.59 1.42zm8.48.58a2 2 0 112-2 2 2 0 01-2 2zm9.9 0a2 2 0 112-2 2 2 0 01-1.96 2zm9.9 0a2 2 0 112-2 2 2 0 01-2 2zm9.89 0a2 2 0 112-2 2 2 0 01-2 2zm9.9 0a2 2 0 112-2 2 2 0 01-2 2zm9.9 0a2 2 0 112-2 2 2 0 01-2 2zm9.9 0a2 2 0 112-2 2 2 0 01-2 2zm9.9 0a2 2 0 112-2 2 2 0 01-2 2zm9.89 0a2 2 0 112-2 2 2 0 01-2 2zm9.9 0a2 2 0 112-2 2 2 0 01-2 2zm9.9 0a2 2 0 112-2 2 2 0 01-2.04 2zm9.9 0a2 2 0 112-2 2 2 0 01-2 2zm11.31-.58a2 2 0 01-2.83 0 2 2 0 01-.58-1.42 2 2 0 014 0 2 2 0 01-.59 1.42z"
      />
    </svg>
  );
}

export default ProcessTabletPre;
