import React from "react";

const BurgerIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 53 43.194"
    >
      <path
        d="M4.5,46.194h53V40.305H4.5Zm0-18.653h53V21.653H4.5ZM4.5,3V8.889h53V3Z"
        transform="translate(-4.5 -3)"
        fill="#fff"
      />
    </svg>
  );
};
export default BurgerIcon;
