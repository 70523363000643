import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colorSchemes, contentSpacing, themeFontNavBar } from "../utils/theme";
import ArrowRightIcon from "./ArrowRightIcon";

const Comp = styled.div(({ resolution, showButton }) => {
  const buttonStyle = showButton
    ? { opacity: 1, transition: "opacity 0.25s" }
    : { opacity: 0, transition: "opacity 2s" };
  return {
    ...themeFontNavBar,
    ...colorSchemes.dark,
    ...buttonStyle,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: "6vh",
    zIndex: 5,
    right: contentSpacing[resolution],
    textDecoration: "none",
    padding: "12px 23px",
    fontSize: "20px",
    lineHeight: "30px",
    borderRadius: "27px",
    svg: {
      marginLeft: "10px"
    },
    ".fill": {
      fill: colorSchemes.dark.color
    }
  };
});

const ApplyButton = ({ children, ...props }) => {
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);
  const compRef = useRef(null);

  useLayoutEffect(() => {
    if (window) {
      let timeoutId;
      const handleScroll = () => {
        setShowButton(true);
        timeoutId = setTimeout(() => {
          if (compRef.current) {
            setShowButton(false);
          }
        }, 1750);
      };
      window.addEventListener("touchstart", handleScroll);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("touchstart", handleScroll);
        window.removeEventListener("scroll", handleScroll);
        window.clearTimeout(timeoutId);
      };
    }
  });

  return (
    <Comp showButton={showButton} {...props} ref={compRef}>
      <a
        href={process.env.REACT_APP_APPLY_LINK}
        resolution={props.resolution}
        target="_blank"
        rel="noreferrer"
      >
        {t("link.apply.text")}
        <ArrowRightIcon />
      </a>
    </Comp>
  );
};

export default ApplyButton;
