import React from "react";
import Page from "../components/Page";
import Block from "../components/Block";
import content from "../locales/de/content";
import { useVersionedContent } from "../context/versionedContent";
import PrintPage from "../components/PrintPage";

const SimpleContent = ({ prefix }) => {
  const { modes } = useVersionedContent();
  const isPrint = modes[prefix] === "print";

  return (
    <Page>
      {isPrint ? (
        <>
          <PrintPage prefix={prefix} />
        </>
      ) : (
        <Block
          content={content[prefix]}
          prefix={prefix}
          contentProvider={useVersionedContent}
        />
      )}
      <Block
        content={content.contact}
        prefix="contact"
        type="cta"
        theme="grey"
      />
    </Page>
  );
};

export default SimpleContent;
