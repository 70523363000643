import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { parseDate } from "../utils/content";
import { blogSpacing, colors, fontStyleMeta } from "../utils/theme";

const Comp = styled.div(
  ({ layout, resolution, resolutionCoarse, showSpacer }) => {
    const alignment = {
      display: "flex"
    };
    const spacing = {};
    if (showSpacer) {
      alignment.alignItems = "center";
    } else {
      alignment.justifyContent = "space-between";
    }

    if (layout === "preview") {
      spacing.paddingRight = blogSpacing.previewMetaPaddingRight[resolution];
    } else if (layout === "post") {
      if (resolutionCoarse !== "mobile") {
        spacing.paddingRight = blogSpacing.postHeadlineIndent[resolutionCoarse];
      }
    }

    return {
      ...alignment,
      ...spacing
    };
  }
);

const Catetory = styled.div(({ resolution }) => {
  return {
    ...fontStyleMeta[resolution],
    textTransform: "uppercase",
    color: colors.RED
  };
});

const Spacer = styled.hr({
  width: "64px",
  marginLeft: "18px",
  marginRight: "18px",
  border: `1px solid ${colors.GREY_DARK}`
});

const Date = styled.div(({ resolution }) => fontStyleMeta[resolution]);

/**
 *
 * @param {string} layout one of "preview" (grid), "teaser" (featured), "slider" (unused)
 * @returns
 */
function BlogPostMeta({ layout, showSpacer, category, date }) {
  const { resolution, resolutionCoarse } = useViewerClient();

  return (
    <Comp
      layout={layout}
      resolution={resolution}
      resolutionCoarse={resolutionCoarse}
      showSpacer={showSpacer}
    >
      <Catetory resolution={resolution}>
        {category ? category.name : ""}
      </Catetory>
      {showSpacer && <Spacer />}
      <Date resolution={resolution}>{parseDate(date, false)}</Date>
    </Comp>
  );
}

export default BlogPostMeta;
