/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";
import { useViewerClient } from "../context/viewerClient";
import { parseDate } from "../utils/content";
import {
  colors,
  fontStyleOpenerStage,
  fontStyleText,
  themeFontStage
} from "../utils/theme";
import CroppedPicture from "./CroppedPicture";
import { useStyles } from "../context/styles";
import ArrowRightIcon from "./ArrowRightIcon";

const CroppedPictureWrapper = styled.div(
  ({ showPicture, animationTime, opacity }) => {
    return {
      position: "absolute",
      top: 0,
      zIndex: showPicture ? 1 : -1,
      transition: `z-index ${animationTime}s`,
      width: "100%",
      opacity,
      cursor: "pointer"
    };
  }
);

const ItemWrapper = styled.div(({ itemWidth, height }) => {
  return {
    position: "relative",
    width: itemWidth,
    boxShadow: "0px 3px 7px rgba(31, 31, 31, 0.3)",
    height
  };
});

const CompWrapper = styled.div(() => {
  return {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%"
  };
});

const Comp = styled.div(() => {
  return {
    display: "flex"
  };
});

const HeadlineWrapper = styled.div(({ resolution, gradient }) => {
  return {
    background: gradient,
    height: resolution === "mobileP" ? "56%" : "70%",
    padding: "20px"
  };
});

const Headline = styled.div(({ resolutionCoarse }) => {
  return {
    color: colors.WHITE,
    ...themeFontStage,
    ...fontStyleOpenerStage[resolutionCoarse],
    wordBreak: "break-word"
  };
});

const TextWrapper = styled.div(() => {
  return {
    backgroundColor: colors.WHITE,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px 10px 20px"
  };
});

const TextInnerWrapper = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px"
  };
});

const Text = styled.div(({ resolutionCoarse }) => {
  return {
    ...fontStyleText[resolutionCoarse]
  };
});

const Date = styled.div(({ resolutionCoarse }) => {
  return {
    ...fontStyleText[resolutionCoarse],
    fontWeight: "600"
  };
});

// component based styling
const styles = {
  img: (animationTime, showPicture, cornerWidth, height, isFirefoxGTE91) => ({
    transition: `opacity ${animationTime}s, clip-path ${animationTime}s`,
    opacity: showPicture ? 1 : 0,
    // preserves the original aspect ratio, but the image occupies all the available space.
    objectFit: "cover",
    // the top right corner of the picture should look "folded" so that we can see
    // the underlying element
    clipPath: showPicture
      ? `polygon(0 0, calc(100% - ${cornerWidth}) 0, 100% ${cornerWidth}, 100% 100%, 0 100%)`
      : "polygon(0 0, 0 0, 100% 80%, 100% 100%, 0 100%)",
    height,
    width: isFirefoxGTE91 ? "-moz-available" : "-webkit-fill-available", // enforce picture to fill the available parent tag space
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px"
  })
};

/**
 *
 * @param {string} layout one of "preview" (grid), "teaser" (featured), "slider" (unused)
 * @returns
 */
function OpenerStageBlogItem({
  layout,
  headline,
  date,
  picture,
  videos,
  path,
  opacity,
  pictureRatio,
  disabled = false,
  showPicture = true,
  clickOnPicture,
  clickOutside = () => {},
  itemWidth,
  startSwipers = () => {},
  ...props
}) {
  const { resolution, resolutionCoarse } = useViewerClient();
  const match = useRouteMatch();
  const { gradient, isFirefoxGTE91 } = useStyles();
  const wrapperRef = useRef(null);
  const [cornerHover, setCornerHover] = useState(false);
  const [onLeaveTimeoutId, setOnLeaveTimeoutId] = useState();
  const timeoutIdRef = useRef();
  const { id: categoryId, name: categoryName } = props.category;

  // make timeoutIdRef always have the current timeoutId
  // so that the callbacks can access the current timeoutId value
  timeoutIdRef.current = onLeaveTimeoutId;

  useEffect(() => {
    setCornerHover(!showPicture);
  }, [showPicture]);

  // if the blog post has a video instead of a picture, we show the picture
  // from the first video
  const pictureToShow = (videos && videos[0] && videos[0].picture) || picture;

  const onPictureClick = () => {
    clearTimeout(timeoutIdRef.current);
    clickOnPicture();
  };

  const onItemMouseEnter = () => {
    if (showPicture) {
      setCornerHover(true);
    }
  };

  const onItemMouseLeave = () => {
    if (showPicture) {
      setCornerHover(false);
    } else {
      // if the picture is currently not shown,
      // set a timeout to show it again 2s after having left
      // the item
      clearTimeout(timeoutIdRef.current);
      const newOnLeaveTimeoutId = setTimeout(() => {
        clickOutside();
        setCornerHover(false);
      }, 2000);
      setOnLeaveTimeoutId(newOnLeaveTimeoutId);
    }
    startSwipers();
  };

  const height = `calc(${itemWidth} * ${pictureRatio / 100})`;
  const animationTime = ".5";
  const cornerWidth = cornerHover ? "60px" : "30px";

  // stands for the background disabled pics
  if (disabled) {
    return (
      <div
        id={"item-wrapper"}
        style={{
          opacity,
          width: itemWidth,
          position: "relative"
        }}
      >
        <CroppedPicture
          picture={pictureToShow}
          layout={layout}
          imgStyle={{ width: "100%", height: "inherit", objectFit: "cover" }}
        />
      </div>
    );
  }

  return (
    <ItemWrapper
      itemWidth={itemWidth}
      height={height}
      ref={wrapperRef}
      id={"item-wrapper"}
      onMouseEnter={onItemMouseEnter}
      onMouseLeave={onItemMouseLeave}
    >
      <CompWrapper>
        <Comp>
          <Link
            to={`aktuelles${match.path}${path}`}
            style={{
              textDecoration: "none",
              width: "100%",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }}
          >
            <HeadlineWrapper resolution={resolution} gradient={gradient}>
              <Headline resolutionCoarse={resolutionCoarse}>
                {headline || ""}
              </Headline>
            </HeadlineWrapper>
            <TextWrapper>
              <TextInnerWrapper>
                <Text resolutionCoarse={resolutionCoarse}>
                  {`${
                    categoryId === 11 || categoryName === "Aktuelles"
                      ? "Aktuelles"
                      : "Ein Feature"
                  } vom:`}
                </Text>
                <Date resolutionCoarse={resolutionCoarse}>
                  {parseDate(date, true)}
                </Date>
              </TextInnerWrapper>
              <ArrowRightIcon />
            </TextWrapper>
          </Link>
        </Comp>

        {picture && (
          <CroppedPictureWrapper
            showPicture={showPicture}
            animationTime={animationTime}
            opacity={opacity}
            onClick={onPictureClick}
          >
            <CroppedPicture
              picture={pictureToShow}
              layout={layout}
              imgStyle={styles.img(
                animationTime,
                showPicture,
                cornerWidth,
                height,
                isFirefoxGTE91
              )}
            />
            <button
              style={{
                position: "absolute",
                cursor: "pointer",
                top: 0,
                right: 0,
                width: showPicture ? cornerWidth : "100%",
                height: showPicture ? cornerWidth : "80%",
                opacity: showPicture ? 1 : 0,
                border: "none",
                background:
                  "linear-gradient(to bottom left, transparent calc(50% - 1px), black calc(50% - 1px), white 50%)",
                transition: `width ${animationTime}s, height ${animationTime}s, opacity ${animationTime}s`
              }}
            />
          </CroppedPictureWrapper>
        )}
      </CompWrapper>
    </ItemWrapper>
  );
}

export default OpenerStageBlogItem;
