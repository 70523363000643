import React from "react";

function ProcessMobileAfter({ backgroundColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.84 204.84">
      <path
        fill={backgroundColor}
        d="M36.23 10.66L36.2 10.63 20.06 26.77 22.82 29.53 34.28 18.07 34.27 47.76 38.17 47.76 38.17 18.07 49.63 29.53 52.39 26.77 36.25 10.63 36.23 10.66z"
      />
      <path fill={backgroundColor} d="M12.97 53.29H59.48V57.19H12.97z" />
      <rect height="71.84" x="71.68" y="132.81" fill={backgroundColor} />
      <path
        fill={backgroundColor}
        d="M0 0v204.84h71.84V0zm35.77 115.62a2 2 0 112-2 2 2 0 01-2 2zm2 9a2 2 0 01-.59 1.42 2 2 0 01-1.41.58 2 2 0 01-2-2 2 2 0 01.58-1.41 2 2 0 011.42-.59 2 2 0 012 2zm-2-20a2 2 0 112-2 2 2 0 01-2 2zm0-11a2 2 0 112-2 2 2 0 01-2 2zM37.18 82a2 2 0 01-1.41.58 2 2 0 01-2-2 2 2 0 01.58-1.41 2 2 0 011.42-.59 2 2 0 012 2 2 2 0 01-.59 1.42zm34.66 122.8H0V133h71.84zm0-133H0V0h71.84z"
      />
      <path
        fill={backgroundColor}
        d="M62.12 152.19L62.12 152.15 39.29 152.15 39.29 156.05 55.49 156.05 29.48 182.06 9.26 161.84 6.51 164.59 26.73 184.81 29.48 187.57 58.25 158.81 58.25 175.01 62.15 175.01 62.15 152.19 62.12 152.19z"
      />
    </svg>
  );
}

export default ProcessMobileAfter;
