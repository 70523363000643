import React from "react";
import { colors } from "../utils/theme";

function PlayIcon({ width = 120 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 120 120"
      className="play-icon"
    >
      <circle cx="60" cy="60" r="60" fill={colors.WHITE} />
      <path
        d="M24,0,48,42H0Z"
        transform="translate(87,36) rotate(90)"
        fill={colors.GREY_MEDIUM_DARK}
      />
    </svg>
  );
}
export default PlayIcon;
