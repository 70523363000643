import styled from "styled-components";
import { colors } from "../utils/theme";

const InputGroupLabel = styled.div(({ isMobile }) => ({
  flex: "0 0 auto",
  fontSize: isMobile ? "18px" : "20px",
  padding: isMobile ? "0 7px" : "0 15px",
  borderRight: `1px solid ${colors.GREY_MEDIUM}`,
  margin: "9px 0 9px 10px"
}));

export default InputGroupLabel;
