import React, { createContext, useContext, useMemo } from "react";
import { gradients } from "../utils/theme";

export const StylesContext = createContext({
  gradient: `linear-gradient(80deg, ${gradients[0][0]}, ${gradients[0][1]}`
});

export const StylesContextProvider = ({ children }) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;
  const isSafariIOs =
    isSafari &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream;
  const ffVersion = navigator.userAgent.match(/Firefox\/(\d*)/);
  const isFirefoxGTE91 =
    ffVersion && ffVersion[1] && parseInt(ffVersion[1], 10) >= 91;

  const gradientColors = useMemo(() => {
    const rnd = Math.random();
    const cnt = gradients.length;
    const index = Math.ceil(rnd * cnt) - 1;
    return gradients[index];
  }, []);

  const gradientAngle = useMemo(() => {
    if (isSafariIOs) {
      return "90deg";
    }
    return "80deg";
  }, [isSafariIOs]);

  return (
    <StylesContext.Provider
      value={{
        gradient: `linear-gradient(${gradientAngle}, ${gradientColors[0]}, ${gradientColors[1]})`,
        gradientColors,
        isSafari,
        isFirefoxGTE91
      }}
    >
      {children}
    </StylesContext.Provider>
  );
};

export const useStyles = () => {
  return useContext(StylesContext);
};
