import React, { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Parallax, Pagination, Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import {
  colors,
  containerStylesTabs,
  contentSpacing,
  styleTabNav
} from "../utils/theme";
import ArrowUpIcon from "./ArrowUpIcon";
import Column from "./Column";
import ScrollLink from "./ScrollLink";
import TabNavItem from "./TabNavItem";
import MarginalCol from "./MarginalCol";

SwiperCore.use([Parallax, Pagination, Navigation]);

const Comp = styled.div(() => {
  return {};
});

const TabNav = styled.div(({ resolution, useFlex }) => {
  return {
    display: useFlex ? "flex" : "inherit",
    ...styleTabNav[resolution]
  };
});

const TabContent = styled.div(({ usePadding, useMarginal }) => {
  if (usePadding) {
    return {
      paddingTop: "50px",
      paddingLeft: contentSpacing.mobileP,
      paddingRight: contentSpacing.mobileP
    };
  }
  return {
    display: "flex",
    justifyContent: useMarginal ? "start" : "center",
    paddingTop: "50px"
  };
});

const ParallaxWrapper = styled.div(({ useMargin }) => {
  if (useMargin) {
    return {
      marginLeft: "18%",
      width: "85%"
    };
  }
  return {
    width: "85%"
  };
});

const Tabs = ({ tabs, prefix }) => {
  const count = tabs.length;
  const {
    isGTEDesktop,
    isGTETabletL,
    isGTETabletP,
    resolutionCoarse,
    headerHeight,
    resolution
  } = useViewerClient();
  const { gradient } = useStyles();
  // set initially selected tab base on hash
  const { hash } = useLocation();
  const initialIndex = hash
    ? tabs.findIndex(({ anchor }) => anchor === hash.substr(1))
    : 0;
  const [currentIndex, setCurrentIndex] = useState(
    initialIndex >= 0 ? initialIndex : 0
  );

  const { headline, aside, ...content } = tabs[currentIndex];
  if (isGTETabletP) {
    return (
      <Comp resolutionCoarse={resolutionCoarse} id={prefix}>
        <TabNav resolution={resolution} useFlex>
          {tabs.map(({ headline, subline, anchor }, index) => {
            const isLast = index === tabs.length - 1;
            const isFirst = index === 0;
            const isActive = index === currentIndex;
            const key = `${prefix}_tabNav_${index}`;
            return (
              <TabNavItem
                size={1 / count}
                key={key}
                id={anchor || key}
                fullWidth={!isGTETabletL}
                isActive={isActive}
                gradient={gradient}
                backgroundAttachment="fixed"
                onClick={() => setCurrentIndex(index)}
                isFirst={isFirst}
                isLast={isLast}
                headline={headline}
                subline={subline}
              />
            );
          })}
        </TabNav>
        <TabContent useMarginal={isGTEDesktop}>
          {isGTEDesktop && (
            <MarginalCol styles={{ fontWeight: 600 }}>
              <ScrollLink scrollOffset={headerHeight + 20}>
                {() => (
                  <Link to={`#${prefix}`}>
                    <span>{aside}</span>
                    <ArrowUpIcon color={colors.GREY_DARK} />
                  </Link>
                )}
              </ScrollLink>
            </MarginalCol>
          )}
          <Column
            content={content}
            columnStyles={containerStylesTabs[resolution]}
          />
        </TabContent>
      </Comp>
    );
  }
  return (
    <TabNav resolution={resolution}>
      <Swiper
        slidesPerView={1}
        parallax
        onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
      >
        {tabs.map(({ headline, subline, anchor, ...tabContent }, index) => {
          const isLast = index === tabs.length - 1;
          const isFirst = index === 0;
          const isActive = index === currentIndex;
          const key = `${prefix}_tabNav_${index}`;
          return (
            <SwiperSlide key={anchor || key}>
              <ParallaxWrapper data-swiper-parallax="40%" useMargin={!!index}>
                <TabNavItem
                  size={1 / count}
                  fullWidth
                  isActive={isActive}
                  gradient={gradient}
                  isFirst={isFirst}
                  isLast={isLast}
                  headline={headline}
                  subline={subline}
                />
              </ParallaxWrapper>
              <TabContent usePadding>
                <Column
                  content={tabContent}
                  columnStyles={containerStylesTabs[resolution]}
                />
              </TabContent>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </TabNav>
  );
};

export default Tabs;
