import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import ProcessMobileAfter from "./ProcessMobileAfter";
import ProcessMobilePre from "./ProcessMobilePre";
import ProcessStep from "./ProcessStep";
import ProcessStepDesktop from "./ProcessStepDesktop";
import ProcessTabletAfter from "./ProcessTabletAfter";
import ProcessTabletPre from "./ProcessTabletPre";

const compStyles = {
  mobile: {
    paddingLeft: "10%"
  },
  tablet: {},
  desktop: {}
};

const Comp = styled.div(({ resolutionCoarse }) => {
  return {
    ...compStyles[resolutionCoarse],
    paddingTop: "30px",
    paddingBottom: "20px",
    svg: {
      display: "block",
      height: "100%"
    }
  };
});

function Process() {
  const { resolutionCoarse, isGTETabletP, isGTETabletL } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse}>
      {isGTETabletL ? (
        <ProcessStepDesktop />
      ) : (
        <>
          <ProcessStep
            Graphic={isGTETabletP ? ProcessTabletPre : ProcessMobilePre}
            prefix="pre"
          />
          <ProcessStep
            Graphic={isGTETabletP ? ProcessTabletAfter : ProcessMobileAfter}
            prefix="after"
          />
        </>
      )}
    </Comp>
  );
}

export default Process;
