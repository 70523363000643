import React from "react";
import styled from "styled-components";
import { themeFontNavBar } from "../utils/theme";

const Comp = styled.li({
  listStyle: "none",
  a: {
    ...themeFontNavBar,
    textDecoration: "none",
    textUnderlinePosition: "under"
  },
  "a:focus": {
    textDecoration: "underline"
  },
  "a:hover": {
    textDecoration: "underline"
  },
  "a:active": {
    textDecoration: "underline"
  }
});

const NavLinkItem = ({ children }) => {
  return <Comp>{children}</Comp>;
};

export default NavLinkItem;
