import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { columnGutter, maxTextColumnWidth } from "../utils/theme";
import Column from "./Column";

const Comp = styled.div(
  ({ resolution, showInColums, twoColumnsGTEDesktop }) => {
    if (showInColums) {
      return {
        marginLeft: `-${columnGutter[resolution]}px`,
        marginRight: `-${columnGutter[resolution]}px`,
        display: "flex",
        justifyContent: "space-between",
        maxWidth: twoColumnsGTEDesktop ? "1060px" : "none"
      };
    }
    return {};
  }
);

/**
 *
 * @param {object} columns columns object
 * @returns
 */
const ColumnsWrapper = ({ columns }) => {
  const { resolution, isGTETabletP, isGTEDesktop } = useViewerClient();

  const columnStyles = {};
  const oneColumn = columns && columns.length === 1;
  const showInColums =
    columns &&
    ((columns.length === 2 && isGTETabletP) ||
      (columns.length >= 3 && isGTEDesktop));
  const twoColumnsGTEDesktop = columns && columns.length === 2 && isGTEDesktop;

  if (!showInColums) {
    // add margin if columns are wrapped
    columnStyles.marginBottom = "2.2em";
    if (isGTETabletP) {
      // max width for single col text
      columnStyles.maxWidth = maxTextColumnWidth;
    }
  }

  if (oneColumn) {
    columnStyles.marginBottom = "-12px";
    columnStyles.maxWidth = "1060px";
  }

  return (
    <Comp
      resolution={resolution}
      showInColums={showInColums}
      twoColumnsGTEDesktop={twoColumnsGTEDesktop}
    >
      {columns.map((column, index) => (
        <Column
          showInColums={showInColums}
          count={columns.length}
          key={`col_${index}`}
          content={column}
          columnStyles={columnStyles}
        />
      ))}
    </Comp>
  );
};

export default ColumnsWrapper;
