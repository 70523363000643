import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useViewerClient } from "../context/viewerClient";
import { useVersionedContent } from "../context/versionedContent";
import { colors, themeButtonReset } from "../utils/theme";
import ContentFilterCompare from "./ContentFilterCompare";
import ContentFilterPrint from "./ContentFilterPrint";
import ContentFilterSearch from "./ContentFilterSearch";

const allModes = [["show", "compare"], ["search"], ["print"]];

const Comp = styled.div({});

const TabsGroup = styled.div({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: "80px"
});

const TabNavItem = styled.div(({ isFirst, isMobile, isLast }) => {
  const padding = isMobile ? "8px" : "35px";
  return {
    flex: `${isMobile ? 1 : 0} 1 auto`,
    marginTop: "9px",
    paddingLeft: isFirst ? null : padding,
    paddingRight: isMobile && isLast ? null : padding,
    textAlign: isMobile ? "center" : "left",
    borderLeft: isFirst ? null : `1px solid ${colors.GREY_DARK}`
  };
});

const TabNavButton = styled.button(({ isActive, isMobile }) => {
  const border = {
    borderBottom: isActive
      ? `4px solid ${colors.GREY_DARK}`
      : "4px solid transparent"
  };
  return {
    ...themeButtonReset,
    height: isMobile ? "40px" : "50px",
    fontSize: isMobile ? "18px" : "20px",
    fontWeight: 600,
    ...border,
    ":active": border,
    ":focus": border
  };
});

function ContentFilter({ id }) {
  const { isGTETabletP } = useViewerClient();
  const { modes, setMode } = useVersionedContent();
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = history;
  const { hash } = location;

  const mode = (modes && modes.faq) || "show";

  const handleOnSwitch = (newMode) => {
    setMode(id, newMode);
    if (hash) {
      location.hash = null;
      history.push({ ...location });
    }
  };

  return (
    <Comp>
      <TabsGroup role="tablist">
        {allModes.map((ms, index) => (
          <TabNavItem
            isMobile={!isGTETabletP}
            key={ms[0]}
            isFirst={index === 0}
            isLast={index === allModes.length - 1}
          >
            <TabNavButton
              role="tab"
              aria-selected={ms.includes(mode)}
              isActive={ms.includes(mode)}
              isMobile={!isGTETabletP}
              onClick={() => !ms.includes(mode) && handleOnSwitch(ms[0])}
            >
              {t(`contentFilter.${ms[0]}.headline`)}
            </TabNavButton>
          </TabNavItem>
        ))}
      </TabsGroup>
      {allModes[0].includes(mode) && <ContentFilterCompare id={id} />}
      {mode === "search" && <ContentFilterSearch id={id} />}
      {mode === "print" && <ContentFilterPrint id={id} />}
    </Comp>
  );
}

export default ContentFilter;
