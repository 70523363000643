import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { parseContent } from "../utils/content";
import { blogSpacing } from "../utils/theme";
import Abstract from "./Abstract";
import CroppedPicture from "./CroppedPicture";
import H1 from "./H1";

const Comp = styled.div(({ resolutionCoarse }) => ({
  paddingTop: blogSpacing.postHeadlineTop[resolutionCoarse]
}));

function BlogPostHeader({
  headline,
  abstract,
  layout,
  picture: postPicture,
  videos
}) {
  const { resolutionCoarse, isGTETabletP } = useViewerClient();
  const spacing = {
    paddingRight: isGTETabletP
      ? blogSpacing.postHeadlineIndent[resolutionCoarse]
      : 0
  };

  let picture;
  let link = null;
  if (videos && videos[0]) {
    picture = videos[0].picture;
    link = videos[0].link;
  } else {
    picture = postPicture;
  }

  return (
    <Comp resolutionCoarse={resolutionCoarse} styles={spacing}>
      <H1 styles={spacing}>{headline}</H1>
      <Abstract styles={spacing}>{parseContent(abstract)}</Abstract>
      <CroppedPicture picture={picture} layout={layout} link={link} />
    </Comp>
  );
}

export default BlogPostHeader;
