import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import {
  colors,
  fontStyleProcessHeadline,
  fontStyleProcessLabel
} from "../utils/theme";
import ProcessDesktop from "./ProcessDesktop";

const Comp = styled.div({});

const HeadlineWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px"
});

const Headline = styled.div(({ resolutionCoarse }) => {
  return {
    ...fontStyleProcessHeadline[resolutionCoarse],
    fontWeight: 600
  };
});

const GraphicBackground = styled.div(({ gradient }) => {
  return {
    height: "100%",
    background: gradient,
    backgroundAttachment: "fixed"
  };
});

const LabelWrapper = styled.div({
  marginTop: "30px",
  display: "flex",
  justifyContent: "space-between"
});

const Label = styled.div(({ resolutionCoarse, styles }) => {
  return {
    ...fontStyleProcessLabel[resolutionCoarse],
    ...styles
  };
});

function ProcessStepDesktop() {
  const { t } = useTranslation();
  const { resolutionCoarse } = useViewerClient();
  const { gradient } = useStyles();

  return (
    <Comp>
      <HeadlineWrapper>
        <Headline resolutionCoarse={resolutionCoarse}>
          {t("process.pre.headline")}
        </Headline>
        <Headline resolutionCoarse={resolutionCoarse}>
          {t("process.after.headline")}
        </Headline>
      </HeadlineWrapper>
      <GraphicBackground gradient={gradient}>
        <ProcessDesktop backgroundColor={colors.GREY_LIGHT} />
      </GraphicBackground>
      <LabelWrapper>
        <Label
          resolutionCoarse={resolutionCoarse}
          styles={{ textAlign: "left", flex: "0 0 20.5%" }}
        >
          <Trans i18nKey={"process.pre.text1"}>...</Trans>
        </Label>
        <Label
          resolutionCoarse={resolutionCoarse}
          styles={{ textAlign: "left", flex: "1 0 20%" }}
        >
          <Trans i18nKey={"process.pre.text2"}>...</Trans>
        </Label>
        <Label
          resolutionCoarse={resolutionCoarse}
          styles={{ textAlign: "right", flex: "0 0 20%" }}
        >
          <Trans i18nKey={"process.after.text1"}>...</Trans>
        </Label>
        <Label
          resolutionCoarse={resolutionCoarse}
          styles={{ textAlign: "right", flex: "0 0 19.5%" }}
        >
          <Trans i18nKey={"process.after.text2"}>...</Trans>
        </Label>
      </LabelWrapper>
    </Comp>
  );
}

export default ProcessStepDesktop;
