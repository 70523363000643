import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { colors, toaster } from "../utils/theme";
import { useViewerClient } from "../context/viewerClient";
import CheckIcon from "./CheckIcon";

const styles = {
  container: {
    opacity: 0.95,
    top: "50%",
    zIndex: 10001
  }
};

const ShowTempInfo = ({ showInfoHint, resetShowInfoHint, text }) => {
  const { resolution } = useViewerClient();

  useEffect(() => {
    if (showInfoHint) {
      toast.remove();
      toast(text, {
        icon: <CheckIcon fill={colors.WHITE} />,
        duration: 3500,
        style: {
          zIndex: 10001,
          borderRadius: "50px",
          background: colors.GREY_TOASTER,
          color: colors.WHITE,
          boxShadow: "16px",
          height: "60px",
          minWidth: toaster[resolution].width
        }
      });
    }

    return () => resetShowInfoHint(false);
  });

  return <Toaster containerStyle={styles.container} />;
};

export default ShowTempInfo;
