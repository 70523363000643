import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { parseContent } from "../utils/content";
import {
  colors,
  fontFamily,
  fontStyleText,
  textSpacing,
  nestedListIndent,
  secondNestedListIndent,
  linkStyles,
  containerTextBox,
  containerExpandableTextBox,
  containerTextBoxIndent,
  diffStyles,
  fontStyleH4
} from "../utils/theme";
import Column from "./Column";
import H4 from "./H4";
import PDFIcon from "./PDFIcon";
import TextBox from "./TextBox";
import CroppedPicture from "./CroppedPicture";

const ul = {
  mobile: {
    paddingInlineStart: "19px"
  },
  tablet: {
    paddingInlineStart: "19px"
  }
};

const li = {
  mobile: {
    fontWeight: 600,
    paddingInlineStart: 0
  },
  tablet: {
    fontWeight: 600,
    paddingInlineStart: 0
  },
  desktop: {
    fontWeight: 600
  }
};

const TextWrapper = styled.div(({ resolution, resolutionCoarse }) => {
  return {
    marginTop: textSpacing[resolutionCoarse],
    fontFamily: fontFamily.normal,
    fontWeight: "normal",
    ...fontStyleText[resolutionCoarse],
    ...linkStyles,
    p: {
      marginBlockStart: 0,
      marginBlockEnd: textSpacing[resolutionCoarse]
    },
    h4: {
      fontFamily: fontFamily.normal,
      fontWeight: 600,
      marginBlockEnd: 0,
      ...fontStyleH4[resolutionCoarse]
    },
    "ul li": {
      fontWeight: "600"
    },
    ul: ul[resolutionCoarse],
    "ul ul": {
      paddingInlineStart: resolutionCoarse === "desktop" ? "40px" : "0px",
      paddingLeft: nestedListIndent[resolution],
      listStyleType: "none"
    },
    "ul ul ul": {
      listStyleType: "none",
      paddingLeft: secondNestedListIndent[resolution]
    },
    "ul ul ul ul li::before": {
      content: "none"
    },
    li: li[resolutionCoarse],
    "li li": {
      fontWeight: "normal"
    },
    "li li::before": {
      content: '"-"',
      marginLeft: "-19px",
      paddingRight: "9px"
    },
    "li li li::before": {
      content: "'\\00B7'",
      marginLeft: "-16px"
    },
    ...diffStyles
  };
});

const Box = styled.div(
  ({ resolution, resolutionCoarse, isExpandable, indent = 0 }) => {
    let indentStlyes = {};
    if (
      containerTextBoxIndent[resolutionCoarse] &&
      containerTextBoxIndent[resolutionCoarse][`${indent}`]
    ) {
      indentStlyes = containerTextBoxIndent[resolutionCoarse][`${indent}`];
    }
    const containerStyles = isExpandable
      ? containerExpandableTextBox[resolution]
      : containerTextBox[resolutionCoarse];
    return {
      background: colors.GREY_LIGHT,
      ...containerStyles,
      ...indentStlyes,
      li: {
        fontWeight: "normal"
      }
    };
  }
);

const Author = styled.div(({ resolutionCoarse }) => ({
  ...fontStyleText[resolutionCoarse],
  paddingRight: resolutionCoarse === "tablet" ? "30px" : 0,
  paddingTop: "18px",
  paddingBottom: "48px",
  textAlign: "right",
  fontStyle: "italic",
  fontWeight: 600
}));
const MediaWrapper = styled.div(({ resolutionCoarse }) => ({
  ...fontStyleText[resolutionCoarse],
  ...linkStyles
}));

const FileLink = styled.a({});

const Paragraph = ({
  headline,
  text,
  anchor,
  type,
  sections,
  indent,
  media,
  videos,
  author,
  isExpanded = true
}) => {
  const { resolution, resolutionCoarse } = useViewerClient();
  const compProps = {};
  if (anchor) {
    compProps.id = anchor;
  }

  if (type === "textbox") {
    const isExpandable = sections || headline;
    const paragraphs = text ? [{ text, media, videos }] : null;

    return (
      <Box
        resolution={resolution}
        resolutionCoarse={resolutionCoarse}
        isExpandable={isExpandable}
        indent={indent}
        {...compProps}
      >
        {sections &&
          sections.map((section, index) => (
            <TextBox {...section} key={`textBox_${index}`} />
          ))}
        {headline ? (
          <TextBox headline={headline} paragraphs={paragraphs} />
        ) : (
          <Column content={{ paragraphs }} />
        )}
      </Box>
    );
  }
  return (
    <>
      <TextWrapper
        resolution={resolution}
        resolutionCoarse={resolutionCoarse}
        {...compProps}
      >
        {headline && <H4>{parseContent(headline)}</H4>}
        {text && parseContent(text, isExpanded)}
      </TextWrapper>
      {media && media.length > 0 && (
        <MediaWrapper resolutionCoarse={resolutionCoarse}>
          {media.map((m) => {
            if (m.mime === "application/pdf") {
              return (
                <FileLink
                  className="file-link"
                  href={m.url}
                  target="_blank"
                  key={m.hash}
                >
                  {parseContent(m.text)}
                  <PDFIcon />
                </FileLink>
              );
            }
            return (
              <CroppedPicture picture={m} key={m.hash} layout="paragraph" />
            );
          })}
        </MediaWrapper>
      )}
      {videos && videos.length > 0 && (
        <MediaWrapper resolutionCoarse={resolutionCoarse}>
          {videos.map(({ picture, link }) => (
            <CroppedPicture
              picture={picture}
              layout="paragraph"
              link={link}
              key={picture.hash}
            />
          ))}
        </MediaWrapper>
      )}
      {author && <Author resolutionCoarse={resolutionCoarse}>{author}</Author>}
    </>
  );
};

export default Paragraph;
