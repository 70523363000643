import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useContent } from "../context/content";
import { useViewerClient } from "../context/viewerClient";
import { routes } from "../utils/routes";
import {
  blogSpacing,
  containerPaddingsText,
  containerWidthsFull,
  containerWidthsTabs,
  fontStyleBlogLabel,
  indentAccordionLeftDesktop
} from "../utils/theme";
import ArrowLeftIcon from "./ArrowLeftIcon";
import BlogHeadline from "./BlogHeadline";
import BlogPostContent from "./BlogPostContent";
import BlogPostHeader from "./BlogPostHeader";
import BlogPostMeta from "./BlogPostMeta";
import Container from "./Container";
import GermanyMap from "./GermanyMap";
import ScrollLink from "./ScrollLink";
import ShareLinks from "./ShareLinks";

const Comp = styled.div(({ resolution }) => containerPaddingsText[resolution]);

const ContentWrapper = styled.div(({ resolutionCoarse }) => ({
  paddingTop: blogSpacing.postTop[resolutionCoarse]
}));

const ColumnsWrapper = styled.div({
  display: "flex"
});

const ControlsCol = styled.div(({ isGTEDesktop }) => ({
  width: isGTEDesktop ? "260px" : "221px",
  paddingRight: isGTEDesktop ? "70px" : "48px",
  flex: "0 0 auto"
}));

const ContentCol = styled.div(() => ({
  flex: "1 1 100%"
}));

const ControlsWrapper = styled.div(({ isGTEDesktop, styles }) => ({
  fontWeight: 600,
  width: isGTEDesktop ? "260px" : "221px",
  ...styles
}));

const Label = styled.div(({ resolution, styles }) => ({
  ...fontStyleBlogLabel[resolution],
  fontWeight: 600,
  ...styles
}));

const BlogPostFooter = styled.div(({ styles }) => ({
  display: "flex",
  justifyContent: "space-between",
  ...styles
}));

const FooterCol = styled.div(({ styles }) => ({
  flex: "0 0 auto",
  ...styles
}));

function BlogPost({ contentPath, id }) {
  const { t } = useTranslation();
  const {
    headerHeight,
    resolution,
    resolutionCoarse,
    isGTETabletP,
    isGTETabletL,
    isGTEDesktop,
    footerBndRect
  } = useViewerClient();
  const { contents, fetchContents } = useContent();
  const [limitToBottom, setLimitToBottom] = useState(false);

  const shouldFetch =
    fetchContents &&
    id &&
    !(contents && contents[contentPath] && contents[contentPath][id]);

  useEffect(() => {
    // get content from provider
    if (shouldFetch) {
      fetchContents([id], contentPath, true);
    }
  }, [contentPath, fetchContents, id, shouldFetch]);

  useEffect(() => {
    const footerRect =
      isGTETabletP || isGTETabletL
        ? footerBndRect + headerHeight / 3
        : footerBndRect;

    if (footerRect <= window.innerHeight) setLimitToBottom(true);
    else setLimitToBottom(false);
  }, [footerBndRect, headerHeight, isGTETabletL, isGTETabletP]);

  const post = shouldFetch ? null : contents[contentPath][id];

  if (!post) {
    return null;
  }

  return (
    <Comp resolution={resolution}>
      <Container fullWidth contentSpacings={containerWidthsFull}>
        <BlogHeadline type={post.isFeatured ? "featured" : "blog"} />
      </Container>
      <Container fullWidth contentSpacings={containerWidthsTabs}>
        <ContentWrapper resolutionCoarse={resolutionCoarse}>
          {isGTEDesktop ? (
            <ColumnsWrapper>
              <ControlsCol isGTEDesktop={isGTEDesktop}>
                <Label
                  resolution={resolution}
                  styles={{ paddingLeft: indentAccordionLeftDesktop }}
                >
                  {post.state}
                </Label>
                <GermanyMap
                  state={post.state}
                  styles={{
                    paddingLeft: indentAccordionLeftDesktop,
                    marginTop: "72px",
                    marginBottom: "272px"
                  }}
                />
                <ScrollLink
                  scrollOffset={headerHeight + 20}
                  limitToBottom={limitToBottom}
                >
                  {() => (
                    <ControlsWrapper
                      styles={{
                        a: {
                          display: "flex",
                          alignItems: "center"
                        }
                      }}
                    >
                      <Link to={`/${routes.aktuelles.path}`}>
                        <ArrowLeftIcon />
                        <Label
                          resolution={resolution}
                          styles={{ marginLeft: "12px" }}
                        >
                          {t("blog.overview")}
                        </Label>
                      </Link>
                      <ShareLinks />
                      <Label resolution={resolution}>{t("blog.share")}</Label>
                    </ControlsWrapper>
                  )}
                </ScrollLink>
              </ControlsCol>
              <ContentCol>
                <BlogPostMeta
                  layout="post"
                  category={post.category}
                  date={post.date}
                />
                <BlogPostHeader {...post} layout="post" />
                <BlogPostContent {...post} />
              </ContentCol>
            </ColumnsWrapper>
          ) : (
            <>
              <BlogPostMeta
                layout="post"
                category={post.category}
                date={post.date}
              />
              <BlogPostHeader {...post} layout="post" />
              {isGTETabletL ? (
                <ColumnsWrapper isGTEDesktop={isGTEDesktop}>
                  <ControlsCol isGTEDesktop={isGTEDesktop}>
                    <Label
                      resolution={resolution}
                      styles={{
                        marginTop: blogSpacing.postVerticalS.tablet,
                        marginLeft: "35px"
                      }}
                    >
                      {post.state}
                    </Label>
                    <GermanyMap
                      state={post.state}
                      styles={{ marginTop: "42px", marginBottom: "272px" }}
                    />
                    <ScrollLink
                      scrollOffset={headerHeight + 20}
                      limitToBottom={limitToBottom}
                    >
                      {() => (
                        <ControlsWrapper
                          styles={{
                            a: {
                              display: "flex"
                            }
                          }}
                        >
                          <ShareLinks />
                          <Label
                            resolution={resolution}
                            styles={{
                              marginBottom: "120px",
                              textAlign: "center"
                            }}
                          >
                            {t("blog.share")}
                          </Label>
                          <Link to={`/${routes.aktuelles.path}`}>
                            <ArrowLeftIcon style={{ marginTop: "6px" }} />
                            <Label
                              styles={{ marginLeft: "30px" }}
                              resolution={resolution}
                            >
                              {t("blog.overview")}
                            </Label>
                          </Link>
                        </ControlsWrapper>
                      )}
                    </ScrollLink>
                  </ControlsCol>
                  <ContentCol>
                    <BlogPostContent {...post} />
                  </ContentCol>
                </ColumnsWrapper>
              ) : (
                <>
                  <BlogPostContent {...post} />
                  <BlogPostFooter
                    styles={{
                      marginTop: isGTETabletP ? "72px" : "64px",
                      marginLeft: isGTETabletP ? "30px" : "40px",
                      marginRight: isGTETabletP ? "30px" : "40px"
                    }}
                  >
                    <FooterCol>
                      <Label resolution={resolution}>{t("blog.share")}</Label>
                    </FooterCol>
                    {isGTETabletP && (
                      <FooterCol>
                        <Label resolution={resolution}>{post.state}</Label>
                      </FooterCol>
                    )}
                  </BlogPostFooter>
                  <BlogPostFooter
                    styles={{
                      marginTop: isGTETabletP ? "32px" : "24px",
                      marginLeft: isGTETabletP ? 0 : "40px",
                      marginRight: isGTETabletP ? "30px" : "40px"
                    }}
                  >
                    <FooterCol
                      styles={{
                        flex: "1 1 auto",
                        a: { display: "flex", alignItems: "center" }
                      }}
                    >
                      <ShareLinks />
                      <Link to={`/${routes.aktuelles.path}`}>
                        <ArrowLeftIcon />
                        <Label
                          resolution={resolution}
                          styles={{ marginLeft: "12px" }}
                        >
                          {t("blog.overview")}
                        </Label>
                      </Link>
                    </FooterCol>
                    {isGTETabletP && (
                      <FooterCol>
                        <GermanyMap
                          state={post.state}
                          styles={{ marginTop: "42px" }}
                        />
                      </FooterCol>
                    )}
                  </BlogPostFooter>
                </>
              )}
            </>
          )}
        </ContentWrapper>
      </Container>
    </Comp>
  );
}

export default BlogPost;
