import React from "react";

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.949"
      height="35.135"
      viewBox="0 0 28.949 35.135"
    >
      <path
        d="M-16329.415-6791.166c.036.072,1.427,7.151,1.072,7.72-.715,1.144-3.288,1.786-3.431,2.93a14.535,14.535,0,0,0,3,7.648c1.858,2,4.359,4.86,5.574,4.86s3.646-3.074,4.5-2.931,5.932,4.574,6.218,5.218-.786,3.288-1.572,3.931-4.36,2.136-5.933,1.564a22.74,22.74,0,0,1-13.723-10.713c-5-8.506-4.646-11.293-4.646-11.293s-.214-4.646.857-6.361,2.431-3.359,4.36-3.574A5.5,5.5,0,0,1-16329.415-6791.166Z"
        transform="translate(16339.884 6793.732)"
        fill="none"
        className="stroke"
        strokeWidth="3"
      />
    </svg>
  );
}
export default PhoneIcon;
