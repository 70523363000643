import React from "react";
import Page from "../components/Page";
import Block from "../components/Block";
import content from "../locales/de/content";

const Home = () => {
  return (
    <Page withStage>
      <Block content={content.intro} prefix="intro" />
      <Block
        content={content.overview}
        prefix="overview"
        type="box"
        theme="grey"
        decorator="ArrowRight"
      />
      <Block content={content.contact} prefix="contact" type="cta" />
    </Page>
  );
};

export default Home;
