import { filter } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Autoplay
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import styled from "styled-components";
import { useContent } from "../context/content";
import OpenerStageBlogItem from "./OpenerStageBlogItem";
import { useViewerClient } from "../context/viewerClient";
import Slider from "./Slider";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Controller, Autoplay]);

const SwiperWrapper = styled.div(() => {
  return {
    height: "auto",
    position: "relative"
  };
});

const OpenerStage = ({ postFilter = { type: "blog" } }) => {
  const { contentLists, fetchContentList } = useContent();
  const { resolution, resolutionCoarse, isGTETabletP } = useViewerClient();
  const [slidersStopped, setSlidersStopped] = useState(false);
  const [sliderReset, setSliderReset] = useState(false);
  const swiperContainer = useRef();
  // keep the index of the item which is currently open, so that we can ensure
  // that only one item is open at a time
  const [openItem, setOpenItem] = useState(undefined);
  const blogContent = contentLists.blog;
  const posts = (blogContent ? filter(blogContent.posts, postFilter) : []).sort(
    (a, b) =>
      new Date(a.date) > new Date(b.date)
        ? -1
        : new Date(a.date) < new Date(b.date)
        ? 1
        : 0
  );
  const firstThreeNewFeatures = 3;
  const lastFourFeatures = 4;
  const newList = posts.slice(0, firstThreeNewFeatures);
  const oldList = posts.slice(
    firstThreeNewFeatures,
    firstThreeNewFeatures + lastFourFeatures
  );
  const shouldFetch = !blogContent;

  useEffect(() => {
    // get content from provider
    if (shouldFetch) {
      fetchContentList("blog");
    }
  }, [fetchContentList, shouldFetch]);

  useEffect(() => {
    if (sliderReset && !slidersStopped) setOpenItem(undefined);

    return () => setSliderReset(false);
  }, [slidersStopped, sliderReset]);

  const sliderHeight = "460px";
  const sliderHeightMobile = "400px";
  const sliderHeightMobileP = "365px";
  const positionSecondSlider = "-460px";

  // Both sliders for showing the new items and the old ones depend on the
  // current resolution for the number of slides to show, the size of the pictures...
  const openerPictureWidth = {
    mobileP: {
      new: { picture: "230px", slidesPerView: 1.4 },
      old: { picture: "210px", slidesPerView: 2 }
    },
    mobileL: {
      new: { picture: "270px", slidesPerView: 1.4 },
      old: { picture: "210px", slidesPerView: 2 }
    },
    tabletP: {
      new: { picture: "270px", slidesPerView: 1.4 },
      old: { picture: "210px", slidesPerView: 3 }
    },
    tabletL: {
      new: { picture: "270px", slidesPerView: 2 },
      old: { picture: "229px", slidesPerView: 3 }
    },
    desktop: {
      new: { picture: "315px", slidesPerView: 3 },
      old: { picture: "245px", slidesPerView: 4 }
    },
    wide: {
      new: { picture: "315px", slidesPerView: 3 },
      old: { picture: "245px", slidesPerView: 4 }
    }
  };
  const newPictureWidth = openerPictureWidth[resolution].new.picture;
  const oldPictureWidth = openerPictureWidth[resolution].old.picture;
  const newSlidesPerView = openerPictureWidth[resolution].new.slidesPerView;
  const oldSlidesPerView = openerPictureWidth[resolution].old.slidesPerView;

  const pictureRatio = 143;
  const startSwipers = () => {
    setSlidersStopped(false);
  };
  const stopSwipers = () => {
    setSlidersStopped(true);
  };
  const clickOnPicture = (index) => {
    setOpenItem(index);
    stopSwipers();
  };
  const clickOutside = () => {
    setSliderReset(true);
  };
  const showPicture = (index) => {
    return index !== openItem;
  };
  const clickOnWrapper = (e) => {
    if (e.target.tagName === "IMG") {
      return;
    }
    clickOutside();
  };
  const containerWidth = swiperContainer?.current
    ? swiperContainer?.current?.getBoundingClientRect()?.width
    : window.innerWidth;
  return (
    <SwiperWrapper ref={swiperContainer} onClick={clickOnWrapper}>
      {isGTETabletP && (
        <Slider
          items={oldList}
          itemOptions={{
            width: oldPictureWidth,
            disabled: true
          }}
          stopped={slidersStopped}
          nbSlidesPerView={oldSlidesPerView}
          containerWidth={containerWidth}
          animationDuration="70s"
          style={{
            height: sliderHeight
          }}
        />
      )}
      {isGTETabletP && (
        <Slider
          items={newList}
          itemOptions={{
            width: newPictureWidth,
            disabled: false,
            pictureRatio,
            clickOnPicture,
            clickOutside,
            showPicture,
            startSliders: startSwipers
          }}
          stopped={slidersStopped}
          nbSlidesPerView={newSlidesPerView}
          containerWidth={containerWidth}
          animationDuration="50s"
          style={{
            height: sliderHeight,
            marginTop: positionSecondSlider
          }}
        />
      )}
      {resolutionCoarse === "mobile" && (
        <Swiper
          id="openerStageNew"
          loop
          pagination={{
            "type": "fraction"
          }}
          slidesPerView={newSlidesPerView}
          speed={1000}
          style={{
            position: "relative",
            maxWidth: "100%",
            height:
              resolution === "mobileP"
                ? sliderHeightMobileP
                : sliderHeightMobile,
            display: "flex",
            justifyContent: "space-around"
          }}
          // eslint-disable-next-line no-return-assign
          onAfterInit={(swiper) =>
            (swiper.wrapperEl.style["transition-timing-function"] = "linear")
          }
        >
          {newList.map((post, index) => (
            <SwiperSlide
              key={index}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <OpenerStageBlogItem
                index={index}
                layout="opener"
                itemWidth={newPictureWidth}
                pictureRatio={pictureRatio}
                opacity={1}
                clickOnPicture={() => clickOnPicture(index)}
                clickOutside={clickOutside}
                showPicture={index !== openItem}
                startSwipers={startSwipers}
                {...post}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </SwiperWrapper>
  );
};

export default OpenerStage;
