import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect
} from "react";
import { debounce } from "lodash";
import {
  enrichContent,
  getAktuelles,
  sortParagraphs,
  sortSections
} from "../utils/content";

export const ContentContext = createContext({
  contents: {}
});

export const ContentProvider = ({ children }) => {
  const [contentLists, setContentLists] = useState({});
  const [hasAktuelles, setHasAktuelles] = useState({
    status: false,
    content: {}
  });
  const [contents, setContents] = useState({});
  const [newsticker, setNewsticker] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchListDebounced = useCallback(
    debounce(
      (key) =>
        fetch(
          `${process.env.REACT_APP_CONTENT_ENDPOINT}${process.env.REACT_APP_CONTENT_LIST_PATH}/${key}${process.env.REACT_APP_API_SUFFIX}`
          // eslint-disable-next-line no-use-before-define
        ).then(handleContentListResponse, handleContentListError),
      500,
      { leading: true }
    ),
    [contentLists]
  );

  const handleContentListResponse = useCallback(
    (response) => {
      fetchListDebounced.cancel();
      if (response.ok) {
        response
          .json()
          .then(({ key, ...cnt }) =>
            setContentLists({ ...contentLists, [key]: sortSections(cnt, true) })
          );
      }
    },
    [contentLists, fetchListDebounced]
  );

  useEffect(() => {
    if (contentLists) {
      const aktuellesContent = getAktuelles(contentLists?.blog?.posts);
      setHasAktuelles({
        status: !!aktuellesContent,
        content: { ...aktuellesContent }
      });
    }
  }, [contentLists]);

  const handleContentListError = useCallback(() => {
    fetchListDebounced.cancel();
  }, [fetchListDebounced]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchContentsDebounced = useCallback(
    debounce(
      async (keys, contentPath, setContent) => {
        return Promise.all(
          keys.map(async (key) => {
            if (!contents[contentPath] || !contents[contentPath][key]) {
              const response = await fetch(
                `${process.env.REACT_APP_CONTENT_ENDPOINT}${contentPath}/${key}${process.env.REACT_APP_API_SUFFIX}`
              );
              if (response.ok) {
                return response.json();
              }
            }
            return null;
          })
        ).then((jsons) => {
          // eslint-disable-next-line no-use-before-define
          const newContents = handleContentsResponse(jsons, contentPath);
          if (setContent) {
            setContents(newContents);
          }
          return newContents;
          // eslint-disable-next-line no-use-before-define
        }, handleContentsError);
      },
      1000,
      { leading: true }
    ),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchNewstickerContentsDebounced = useCallback(
    debounce(
      () =>
        fetch(
          `${process.env.REACT_APP_CONTENT_ENDPOINT}newsticker`
          // eslint-disable-next-line no-use-before-define
        ).then(handleNewstickerResponse, handleNewstickerError),
      500,
      { leading: true }
    ),
    []
  );

  const handleNewstickerError = useCallback(() => {
    fetchNewstickerContentsDebounced.cancel();
  }, [fetchNewstickerContentsDebounced]);

  const handleNewstickerResponse = useCallback(
    (response) => {
      fetchNewstickerContentsDebounced.cancel();
      if (response.ok) {
        response
          .json()
          .then((newstickerData) => setNewsticker({ ...newstickerData }));
      }
    },
    [fetchNewstickerContentsDebounced]
  );

  const fetchContentList = useCallback(
    (key) => fetchListDebounced(key),
    [fetchListDebounced]
  );

  const handleContentsError = useCallback(() => {
    fetchContentsDebounced.cancel();
  }, [fetchContentsDebounced]);

  const fetchContents = useCallback(
    (key, path, setContent) => fetchContentsDebounced(key, path, setContent),
    [fetchContentsDebounced]
  );

  const handleContentsResponse = useCallback(
    (jsons, contentPath) => {
      fetchContentsDebounced.cancel();
      const newContents = { ...contents };
      jsons.forEach((json) => {
        if (json) {
          const { id, ...cnt } = json;
          if (!newContents[contentPath]) {
            newContents[contentPath] = {};
          }
          newContents[contentPath][id] = sortParagraphs(cnt);
          enrichContent(newContents[contentPath][id]);
        }
      });
      return newContents;
    },
    [contents, fetchContentsDebounced]
  );

  const fetchNewstickerContents = useCallback(
    fetchNewstickerContentsDebounced,
    [fetchNewstickerContentsDebounced]
  );

  return (
    <ContentContext.Provider
      value={{
        contents,
        fetchContents,
        setContents,
        contentLists,
        hasAktuelles,
        fetchContentList,
        newsticker,
        fetchNewstickerContents
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = () => {
  return useContext(ContentContext);
};
