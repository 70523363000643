import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import {
  colors,
  fontStyleProcessHeadline,
  fontStyleProcessLabel,
  mobileGraphicHeight
} from "../utils/theme";

const tabletSpacingLeft = "22.35%";
const tabletSpacingRight = "24.35%";

const graphicStyles = {
  mobile: {
    flex: `0 0 ${mobileGraphicHeight}`
  },
  tablet: {}
};

const Comp = styled.div(({ isMedium }) => {
  return {
    paddingTop: "20px",
    paddingBottom: isMedium ? "40px" : "20px"
  };
});
const Headline = styled.div(({ resolutionCoarse, spacing }) => {
  return {
    ...spacing,
    ...fontStyleProcessHeadline[resolutionCoarse],
    fontWeight: 600
  };
});
const Content = styled.div(({ isMedium }) => {
  if (!isMedium) {
    return {
      display: "flex"
    };
  }
  return {};
});

const GraphicWrapper = styled.div(({ resolutionCoarse, spacing }) => {
  return {
    ...spacing,
    ...graphicStyles[resolutionCoarse]
  };
});
const GraphicBackground = styled.div(({ gradient }) => {
  return {
    height: "100%",
    background: gradient,
    backgroundAttachment: "fixed"
  };
});

const LabelWrapper = styled.div(({ isMedium, spacing }) => {
  if (isMedium) {
    return {
      ...spacing,
      paddingTop: "20px",
      display: "flex"
    };
  }
  return {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "6%"
  };
});
const Label = styled.div(({ resolutionCoarse, isMedium, isFirst }) => {
  if (isMedium) {
    return {
      ...fontStyleProcessLabel[resolutionCoarse],
      flex: `0 0 ${isFirst ? "52.6%" : "auto"}`
    };
  }
  return {
    ...fontStyleProcessLabel[resolutionCoarse]
  };
});

function ProcessStep({ Graphic, prefix }) {
  const { t } = useTranslation();
  const { isGTETabletP, resolutionCoarse } = useViewerClient();
  const { gradient } = useStyles();

  const useSpacingRight = prefix === "after";

  const spacing = {};
  const graphicSpacing = {};
  if (isGTETabletP) {
    if (useSpacingRight) {
      graphicSpacing.marginRight = tabletSpacingRight;
    } else {
      graphicSpacing.marginLeft = tabletSpacingLeft;
    }
    spacing.marginLeft = tabletSpacingLeft;
  }

  return (
    <Comp isMedium={isGTETabletP}>
      <Headline resolutionCoarse={resolutionCoarse} spacing={spacing}>
        {t(`process.${prefix}.headline`)}
      </Headline>
      <Content isMedium={isGTETabletP}>
        <GraphicWrapper
          resolutionCoarse={resolutionCoarse}
          spacing={graphicSpacing}
        >
          <GraphicBackground gradient={gradient}>
            <Graphic backgroundColor={colors.GREY_LIGHT} />
          </GraphicBackground>
        </GraphicWrapper>
        <LabelWrapper isMedium={isGTETabletP} spacing={spacing}>
          <Label
            resolutionCoarse={resolutionCoarse}
            isMedium={isGTETabletP}
            isFirst
          >
            <Trans i18nKey={`process.${prefix}.text1`}>...</Trans>
          </Label>
          <Label resolutionCoarse={resolutionCoarse} isMedium={isGTETabletP}>
            <Trans i18nKey={`process.${prefix}.text2`}>...</Trans>
          </Label>
        </LabelWrapper>
      </Content>
    </Comp>
  );
}

export default ProcessStep;
