import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { routes } from "../utils/routes";
import {
  colorSchemes,
  fontFamily,
  fontSizeH1,
  fontStyleContact
} from "../utils/theme";
import ArrowRightUpIcon from "./ArrowRightUpIcon";
import EmailIcon from "./EmailIcon";
import PhoneIcon from "./PhoneIcon";

const iconSizes = {
  mobile: [20, 28],
  tablet: [20, 28],
  desktop: [25, 30]
};
const Comp = styled.div({
  display: "flex",
  justifyContent: "center",
  ".fill": {
    fill: colorSchemes.light.color
  },
  ".stroke": {
    stroke: colorSchemes.light.color
  },
  marginTop: "50px",
  marginBottom: "50px"
});
const InnerWrapper = styled.div({
  flex: "0 1 auto"
});
const Headline = styled.h2(({ resolutionCoarse }) => {
  return {
    fontFamily: fontFamily.headline,
    fontWeight: "bold",
    fontSize: fontSizeH1[resolutionCoarse],
    marginBlockStart: "0.5em",
    marginBlockEnd: "0.5em"
  };
});
const ContactWrapper = styled.div(({ resolutionCoarse }) => {
  return {
    ...fontStyleContact[resolutionCoarse],
    display: "flex",
    alignItems: "center",
    marginBottom: "10px"
  };
});
const LinkText = styled.div(({ isMobile }) => {
  if (isMobile) {
    return {
      maxWidth: "9em",
      marginLeft: "0.5em"
    };
  }
  return {
    marginLeft: "0.5em"
  };
});

function Contact({ hideFaqLink }) {
  const { t } = useTranslation();
  const { resolutionCoarse, isGTETabletP } = useViewerClient();
  return (
    <Comp>
      <InnerWrapper>
        <Headline resolutionCoarse={resolutionCoarse}>
          {t("contact.headline")}
        </Headline>
        {!hideFaqLink && (
          <Link to={`/${routes.faq.path}`}>
            <ContactWrapper resolutionCoarse={resolutionCoarse}>
              <ArrowRightUpIcon
                size={iconSizes[resolutionCoarse][0]}
                width={iconSizes[resolutionCoarse][1]}
              />
              <LinkText>{t("contact.faq.text")}</LinkText>
            </ContactWrapper>
          </Link>
        )}
        <a href={`tel:${t("contact.tel.number")}`}>
          <ContactWrapper resolutionCoarse={resolutionCoarse}>
            <PhoneIcon />
            <LinkText isMobile={!isGTETabletP}>
              {t("contact.tel.text")}
            </LinkText>
          </ContactWrapper>
        </a>
        <a href={`mailto:${t("contact.email.text")}`}>
          <ContactWrapper resolutionCoarse={resolutionCoarse}>
            <EmailIcon />
            <LinkText isMobile={!isGTETabletP}>
              {t("contact.email.text")}
            </LinkText>
          </ContactWrapper>
        </a>
      </InnerWrapper>
    </Comp>
  );
}

export default Contact;
