import React from "react";

function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="26"
      viewBox="0 0 30 24"
    >
      <path
        d="M27,0H3C1.35,0.01,0.01,1.35,0.02,3L0,21c0,1.65,1.35,3,3,3h24
	c1.65,0,3-1.35,3-3V3C30,1.35,28.65,0,27,0z M27,21H3V6l12,7.5L27,6V21z M15,10.5L3,3h24L15,10.5z"
        fill="#1F1F1F"
        className="stroke"
        strokeWidth="0.1"
      />
    </svg>
  );
}

export default EmailIcon;
