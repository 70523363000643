import { filter } from "lodash";
import React from "react";
import styled from "styled-components";
import { useContent } from "../context/content";
import BlogHeadline from "../components/BlogHeadline";
import BlogPostTeaser from "../components/BlogPostTeaser";
import { blogSpacing } from "../utils/theme";

const Comp = styled.div(() => {
  return {
    paddingBottom: blogSpacing.grid,
    "a:hover": {
      textDecoration: "none"
    }
  };
});

function BlogOverviewHeader({ contentPath, postIndex, postFilter, layout }) {
  const { contentLists } = useContent();

  let post;
  if (
    contentLists &&
    contentLists[contentPath] &&
    contentLists[contentPath].posts &&
    postIndex !== undefined &&
    postIndex !== null
  ) {
    post = filter(contentLists[contentPath].posts, postFilter)[postIndex];
  }

  if (!post) {
    return null;
  }

  return (
    <Comp>
      <BlogHeadline type="featured" />
      <BlogPostTeaser layout={layout} {...post} />
    </Comp>
  );
}

export default BlogOverviewHeader;
