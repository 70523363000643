import React from "react";
import styled from "styled-components";

const Comp = styled.div(({ styles }) => ({
  flex: "0 1 320px",
  paddingRight: "50px",
  paddingTop: "10.8px",
  fontSize: "20px",
  ...styles,
  svg: {
    marginBottom: "-1px",
    marginLeft: "1em"
  }
}));

function MarginalCol({ children, styles }) {
  return <Comp styles={styles}>{children}</Comp>;
}

export default MarginalCol;
