import React from "react";
import styled from "styled-components";
import {
  colorSchemes,
  themeButtonReset,
  themeFontNavBar
} from "../utils/theme";

const Comp = styled.button(({ iconStyles }) => {
  const styles = {};
  return {
    ...themeButtonReset,
    ...themeFontNavBar,
    ...colorSchemes.light,
    border: `2px solid ${colorSchemes.light.color}`,
    ":hover": {
      border: `2px solid ${colorSchemes.light.color}`
    },
    ":active": {
      border: `2px solid ${colorSchemes.light.color}`
    },
    textDecoration: "none",
    borderRadius: "30px",
    width: "60px",
    height: "60px",
    svg: iconStyles,
    ".fill": {
      fill: colorSchemes.light.color
    },
    ".stroke": {
      stroke: colorSchemes.light.color
    },
    ...styles
  };
});

function CircleButton({ iconStyles = {}, children }) {
  return <Comp iconStyles={iconStyles}>{children}</Comp>;
}

export default CircleButton;
