import React from "react";
import styled from "styled-components";
import { colors } from "../utils/theme";

const dotRadius = 3;
const patternSize = dotRadius * 4;
const patternHeight = patternSize;

const Comp = styled.div(({ position }) => {
  const dotStyles = {
    position: "absolute",
    zIndex: 1,
    backgroundColor: "transparent",
    opacity: 1,
    backgroundImage: `radial-gradient(${colors.WHITE} ${dotRadius}px, transparent ${dotRadius}px)`
  };
  if (position === "left") {
    return {
      ...dotStyles,
      height: "100%",
      backgroundSize: `${patternHeight}px ${patternSize}px `,
      backgroundPositionX: "left",
      width: `${patternHeight}px`,
      left: `-${patternHeight / 2}px`
    };
  }
  if (position === "bottom") {
    return {
      ...dotStyles,
      width: "100%",
      backgroundSize: `${patternSize}px ${patternHeight}px`,
      backgroundPositionY: "bottom",
      height: `${patternHeight / 2}px`,
      top: 0
    };
  }
  return {
    ...dotStyles,
    width: "100%",
    backgroundSize: `${patternSize}px ${patternHeight}px`,
    height: `${patternHeight}px`,
    bottom: `-${patternHeight / 2}px`
  };
});

function DotSeparator({ position }) {
  return <Comp position={position} />;
}

export default DotSeparator;
