import React, { useMemo } from "react";
import Select from "react-select";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { useVersionedContent } from "../context/versionedContent";
import { colors, fontFamily, maxTextWidth, fontStyleH4 } from "../utils/theme";
import ChipButton from "./ChipButton";
import InputGroup from "./InputGroup";
import InputGroupLabel from "./InputGroupLabel";
import ControlsWrapper from "./ControlsWrapper";

const Comp = styled.div({
  maxWidth: maxTextWidth
});

const SelectWrapper = styled.div(({ isMobile }) => ({
  flex: "1 1 auto",
  fontFamily: fontFamily.normal,
  color: colors.GREY_MEDIUM,
  marginTop: "8.75px",
  marginBottom: "8.75px",
  padding: 0,
  fontSize: isMobile ? "22px" : "24px",
  textAlign: "center"
}));

const IndicatorSeparator = null;
const DropdownIndicator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="9"
    viewBox="0 0 18 9"
  >
    <path
      d="M9,13.5l9,9,9-9Z"
      transform="translate(-9 -13.5)"
      fill={colors.GREY_DARK}
    />
  </svg>
);

const TextWrapper = styled.div(({ resolutionCoarse }) => {
  return fontStyleH4[resolutionCoarse];
});

const MobileButtonWraper = styled.div({
  marginTop: "30px"
});

const Highlight = styled.span({
  background: colors.YELLOW_MEDIUM
});

const CompareInfo = styled.div({});

function ContentFilterCompare({ id }) {
  const { isGTEMobileL, isGTETabletP, resolutionCoarse } = useViewerClient();
  const {
    versionLists,
    selectedVersions,
    setCompareToVersion,
    contentLists,
    modes,
    setMode
  } = useVersionedContent();
  const { t } = useTranslation();

  const selectStyles = useMemo(
    () => ({
      container: (provided) => ({
        ...provided,
        color: colors.GREY_DARK,
        padding: 0
      }),
      control: (provided) => ({
        ...provided,
        border: "none",
        boxShadow: "none",
        cursor: "pointer",
        flexDirection: "row-reverse",
        padding: isGTETabletP ? "11.25px 35px" : "10px 15px"
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: colors.GREY_DARK,
        ":hover": {
          color: colors.GREY_DARK
        }
      }),
      input: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0
      }),
      menu: (provided) => ({
        ...provided,
        marginTop: isGTETabletP ? "11px" : "14px",
        boxShadow: `0px 4px 8px ${colors.GREY_MEDIUM_DARK}`,
        border: "none",
        borderRadius: 0,
        textAlign: "right"
      }),
      menuList: (provided) => ({
        ...provided,
        paddingTop: "8px",
        paddingBottom: "8px"
      }),
      option: (provided, { isFocused, isSelected }) => {
        const colorProps = {};
        if (isSelected) {
          colorProps.color = colors.WHITE;
          colorProps.background = colors.GREY_DARK;
        } else if (isFocused) {
          colorProps.background = colors.GREY_MEDIUM_LIGHT;
        }
        return {
          ...provided,
          cursor: "pointer",
          paddingRight: "35px",
          marginTop: "8px",
          marginBottom: "8px",
          lineHeight: "37px",
          ...colorProps
        };
      },
      singleValue: (provided) => ({
        ...provided,
        color: colors.GREY_DARK
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: "center",
        padding: 0
      })
    }),
    [isGTETabletP]
  );

  if (!versionLists || !versionLists[id]) {
    return null;
  }

  const versionList = versionLists[id].slice(1);
  const compareToVersion = selectedVersions[id][1] || selectedVersions[id][0];
  const showVersionList = versionList.length;
  const { sectionsDiffCount: count } = contentLists[id];

  return (
    <Comp>
      <InputGroup isMobile={!isGTETabletP}>
        <InputGroupLabel isMobile={!isGTETabletP}>
          {t(
            `contentFilter.compare.compareTo.${
              isGTEMobileL ? "desktop" : "mobile"
            }`
          )}
        </InputGroupLabel>
        <SelectWrapper isMobile={!isGTETabletP}>
          {showVersionList ? (
            <Select
              aria-label={t("contentFilter.compare.selectAria")}
              // isMobile={!isGTETabletP}
              styles={selectStyles}
              components={{ IndicatorSeparator, DropdownIndicator }}
              onChange={({ value }) => setCompareToVersion(id, value)}
              value={{
                value: compareToVersion.key,
                label:
                  compareToVersion.key === selectedVersions[id][0].key
                    ? t("contentFilter.compare.placeholder")
                    : compareToVersion.label
              }}
              options={versionList.map(({ key, label }) => ({
                value: key,
                label
              }))}
              isSearchable={false}
            />
          ) : (
            <>
              {compareToVersion.label}
              {t("contentFilter.compare.lastVersion")}
            </>
          )}
        </SelectWrapper>
      </InputGroup>
      {modes[id] === "compare" && count !== undefined && (
        <>
          <ControlsWrapper>
            <CompareInfo>
              <Highlight>
                &lt;&nbsp;
                <Trans i18nKey={"contentFilter.compare.count"} count={count}>
                  ...{{ count }}
                </Trans>
                &nbsp;&gt;
              </Highlight>
            </CompareInfo>
            {isGTETabletP && (
              <ChipButton onClick={() => setMode(id, "show")}>
                {t("contentFilter.compare.cancel")}
              </ChipButton>
            )}
          </ControlsWrapper>
          <TextWrapper resolutionCoarse={resolutionCoarse}>
            <Trans
              i18nKey={"contentFilter.compare.intro"}
              date={selectedVersions[id][0].label}
              dateOld={compareToVersion.label}
            >
              <strong>Sie vergleichen</strong> den derzeit aktuellen Stand der
              FAQ vom <strong>{{ date: selectedVersions[id][0].label }}</strong>{" "}
              mit dem letzen Stand vom{" "}
              <strong>{{ dateOld: compareToVersion.label }}</strong> als
              Vergleichsbasis. <Highlight>Inhaltliche Veränderungen</Highlight>{" "}
              werden hervorgehoben.
            </Trans>
          </TextWrapper>
          {!isGTETabletP && (
            <MobileButtonWraper>
              <ChipButton onClick={() => setMode(id, "show")}>
                {t("contentFilter.compare.cancel")}
              </ChipButton>
            </MobileButtonWraper>
          )}
        </>
      )}
    </Comp>
  );
}

export default ContentFilterCompare;
