import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouteSwitch from "./containers/RouteSwitch";
import { ContentProvider } from "./context/content";
import { StylesContextProvider } from "./context/styles";
import { VersionedContentProvider } from "./context/versionedContent";
import { ViewerClientProvider } from "./context/viewerClient";
import "./utils/i18n";

function App() {
  useEffect(() =>
    // eslint-disable-next-line no-console
    console.log(
      `${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_VERSION}`
    )
  );
  return (
    <StylesContextProvider>
      <ContentProvider>
        <VersionedContentProvider>
          <Router>
            <ViewerClientProvider>
              <RouteSwitch>
                <>Fallback</>
              </RouteSwitch>
            </ViewerClientProvider>
          </Router>
        </VersionedContentProvider>
      </ContentProvider>
    </StylesContextProvider>
  );
}

export default App;
