import React from "react";

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      viewBox="0 0 24 24"
      width="40"
    >
      <path d="M0 0h24v24H0z" fill="transparent" />
      <path
        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default CheckIcon;
