import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { columnGutter } from "../utils/theme";
import H3 from "./H3";
import Paragraph from "./Paragraph";

const Comp = styled.div(({ resolution, count, showInColums, styles }) => {
  if (showInColums) {
    return {
      flex: `0 1 ${100 / count}%`,
      paddingLeft: `${columnGutter[resolution]}px`,
      paddingRight: `${columnGutter[resolution]}px`,
      ...styles
    };
  }
  return styles;
});

const Column = ({
  count = 1,
  showInColums,
  content,
  columnStyles,
  isExpanded = true
}) => {
  const { resolution } = useViewerClient();
  const { headline, paragraphs, anchor } = content;
  const compProps = {};
  if (anchor) {
    compProps.id = anchor;
  }

  return (
    <Comp
      resolution={resolution}
      count={count}
      showInColums={showInColums}
      styles={columnStyles}
      {...compProps}
    >
      {headline && <H3>{headline}</H3>}
      {paragraphs &&
        paragraphs.map((paragraph, index) => {
          const key = `p_${index}`;
          return <Paragraph key={key} isExpanded={isExpanded} {...paragraph} />;
        })}
    </Comp>
  );
};

export default Column;
