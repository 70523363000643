import React from "react";
import { useViewerClient } from "../context/viewerClient";
import { blogSpacing, maxTextColumnWidth } from "../utils/theme";
import Column from "./Column";

function BlogPostContent({ paragraphs }) {
  const { resolutionCoarse, isGTETabletL } = useViewerClient();

  if (!paragraphs) {
    return null;
  }

  return (
    <Column
      content={{ paragraphs }}
      columnStyles={{
        maxWidth: isGTETabletL ? maxTextColumnWidth : "auto",
        paddingTop: blogSpacing.postContentTop[resolutionCoarse]
      }}
      showInColums={false}
    />
  );
}

export default BlogPostContent;
