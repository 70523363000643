import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontSizeH1 } from "../utils/theme";

const Comp = styled.h1(({ resolutionCoarse, styles }) => {
  return {
    fontFamily: fontFamily.headline,
    fontWeight: "bold",
    fontSize: fontSizeH1[resolutionCoarse],
    ...styles
  };
});

function H1({ children, styles }) {
  const { resolutionCoarse } = useViewerClient();
  return (
    <Comp resolutionCoarse={resolutionCoarse} styles={styles}>
      {children}
    </Comp>
  );
}

export default H1;
