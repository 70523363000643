import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { parseContent } from "../utils/content";
import {
  blogPreviewShadow,
  blogSpacing,
  colors,
  colorSchemes,
  fontStyleBlogListPostHeadline,
  fontStyleText,
  themeButtonReset,
  themeFontNavBar,
  transitionSpeed
} from "../utils/theme";
import Abstract from "./Abstract";
import ArrowRightIcon from "./ArrowRightIcon";
import ArrowRightLongIcon from "./ArrowRightLongIcon";
import CroppedPicture from "./CroppedPicture";
import BlogPostMeta from "./BlogPostMeta";
import H1 from "./H1";

const Comp = styled.div(({ layout, resolutionCoarse, resolution }) => {
  let shadowStyle = {};
  // if (layout === "preview" || layout === "teaser") {
  if (resolutionCoarse === "mobile") {
    shadowStyle = blogPreviewShadow;
  } else if (resolutionCoarse === "desktop") {
    shadowStyle = {
      boxShadow: "0 0 0",
      transition: `${transitionSpeed}`,
      ":hover": {
        textDecoration: "none",
        transition: `${transitionSpeed}`,
        ...blogPreviewShadow
      },
      ":active": {
        textDecoration: "none"
      }
    };
  }
  // }
  const spacing = {};
  if (layout === "teaser") {
    if (resolution === "tabletP") {
      spacing.marginTop = "60px";
      // spacing.marginLeft = `-${contentSpacing.tabletP}`;
      // spacing.marginRight = `-${contentSpacing.tabletP}`;
    } else if (resolutionCoarse !== "mobile") {
      spacing.marginTop = "32px";
    }
  }
  return {
    background: colors.WHITE,
    height: "100%",
    position: "relative",
    ...spacing,
    ...shadowStyle
  };
});

const TextWrapper = styled.div(({ layout, resolutionCoarse, resolution }) => {
  const spacing = {};
  if (layout === "preview") {
    spacing.paddingLeft = blogSpacing.horizontal[resolution];
    spacing.paddingRight = blogSpacing.horizontal[resolution];
    spacing.paddingBottom = blogSpacing.previewPaddingBottom[resolutionCoarse];
    if (resolutionCoarse === "mobile") {
      spacing.paddingTop = "16px";
    } else {
      spacing.paddingTop = "24px";
    }
  } else if (layout === "teaser") {
    if (resolutionCoarse === "tablet") {
      spacing.paddingLeft = blogSpacing.horizontal[resolution];
      spacing.paddingRight = blogSpacing.horizontal[resolution];
    }
    if (resolutionCoarse === "desktop") {
      spacing.paddingLeft = "19px";
      spacing.paddingRight = "19px";
    } else if (resolutionCoarse === "mobile") {
      spacing.paddingLeft = blogSpacing.horizontal[resolution];
      spacing.paddingRight = blogSpacing.horizontal[resolution];
      spacing.paddingBottom =
        blogSpacing.previewPaddingBottom[resolutionCoarse];
    }
    spacing.paddingTop = blogSpacing.teaserMetaPaddingTop[resolution];
  }
  return {
    ...spacing
  };
});

const PostHeadline = styled.h3(({ resolutionCoarse }) => ({
  fontWeight: 600,
  ...fontStyleBlogListPostHeadline[resolutionCoarse]
}));

const AbstractWrapper = styled.div(({ resolution }) => {
  const styles = {
    position: "relative"
  };
  if (resolution === "tabletP") {
    styles.paddingBottom = "72px";
  } else if (resolution === "tabletL" || resolution === "desktop") {
    styles.paddingBottom = "100px";
  } else {
    // wide
    styles.display = "flex";
    styles.justifyContent = "space-between";
    styles.alignItems = "center";
  }
  return styles;
});

const abstractStyles = {
  tabletP: {
    marginBlockEnd: "1em"
  },
  tabletL: {
    marginBlockEnd: "2em"
  },
  wide: {
    maxWidth: "930px"
  }
};

const Text = styled.div(({ resolutionCoarse }) => ({
  ...fontStyleText[resolutionCoarse],
  paddingBottom: "1em"
}));

const ContinueLink = styled.span(({ resolutionCoarse, resolution }) => ({
  ...fontStyleText[resolutionCoarse],
  position: "absolute",
  bottom: blogSpacing.previewPaddingBottom[resolutionCoarse],
  right: blogSpacing.previewLinkRight[resolution],
  fontWeight: 600
}));

const ContinueLinkButton = styled.button(
  ({ resolution, isGTETabletL, isGTEDesktop, isGTEWide }) => {
    const styles = {};
    if (!isGTEWide) {
      styles.position = "absolute";
      styles.right = blogSpacing.horizontal[resolution];
    } else {
      styles.height = "64px";
    }
    return {
      ...themeButtonReset,
      fontSize: isGTEDesktop ? "28px" : "24px",
      lineHeight: isGTEDesktop ? "32px" : "28px",
      ...themeFontNavBar,
      ...colorSchemes.light,
      border: `2px solid ${colorSchemes.light.color}`,
      textDecoration: "none",
      padding: isGTETabletL ? "14px 40px" : "16px 13px 12px 3px",
      borderRadius: isGTETabletL ? "4px" : "30px",
      svg: {
        marginLeft: "10px"
      },
      ".fill": {
        fill: colorSchemes.light.color
      },
      ".stroke": {
        stroke: colorSchemes.light.color
      },
      ...styles
    };
  }
);

/**
 *
 * @param {string} layout one of "preview" (grid), "teaser" (featured), "slider" (unused)
 * @returns
 */
function BlogPostTeaser({
  layout,
  headline,
  abstractShort,
  picture: postPicture,
  videos,
  category,
  path,
  date
}) {
  const {
    resolution,
    resolutionCoarse,
    isGTETabletP,
    isGTETabletL,
    isGTEDesktop,
    isGTEWide
  } = useViewerClient();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [hover, setHover] = useState(false);

  const handleOnMouseEnter = useCallback(() => {
    if (isGTEDesktop) {
      setHover(true);
    }
  }, [isGTEDesktop]);

  const handleOnMouseLeave = useCallback(() => {
    if (isGTEDesktop) {
      setHover(false);
    }
  }, [isGTEDesktop]);

  const showSpacer = isGTEDesktop && layout === "teaser";
  const showContinueLink =
    !isGTETabletP || (!isGTEDesktop && layout === "preview");
  const showContinueButton = isGTETabletP && layout === "teaser";

  let picture;
  if (videos && videos[0]) {
    picture = videos[0].picture;
  } else {
    picture = postPicture;
  }

  return (
    <Link to={`${match.path}/${path}`}>
      <Comp
        layout={layout}
        resolutionCoarse={resolutionCoarse}
        resolution={resolution}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <CroppedPicture picture={picture} layout={layout} hover={hover} />
        <TextWrapper
          layout={layout}
          resolutionCoarse={resolutionCoarse}
          resolution={resolution}
        >
          <BlogPostMeta
            layout={layout}
            showSpacer={showSpacer}
            category={category}
            date={date}
          />
          {layout === "teaser" ? (
            <>
              <H1>{headline}</H1>
              <AbstractWrapper resolution={resolution}>
                <Abstract styles={abstractStyles[resolution] || {}}>
                  {parseContent(abstractShort)}
                </Abstract>
                {showContinueButton && (
                  <>
                    <ContinueLinkButton
                      isGTETabletL={isGTETabletL}
                      isGTEDesktop={isGTEDesktop}
                      isGTEWide={isGTEWide}
                      resolution={resolution}
                    >
                      {isGTETabletL && t("blog.teaser.continue")}
                      {isGTETabletL ? (
                        <ArrowRightIcon />
                      ) : (
                        <ArrowRightLongIcon />
                      )}
                    </ContinueLinkButton>
                  </>
                )}
              </AbstractWrapper>
            </>
          ) : (
            <>
              <PostHeadline resolutionCoarse={resolutionCoarse} layout={layout}>
                {headline}
              </PostHeadline>
              <Text resolutionCoarse={resolutionCoarse} layout={layout}>
                {parseContent(abstractShort)}
              </Text>
            </>
          )}
        </TextWrapper>
        {showContinueLink && (
          <ContinueLink
            resolutionCoarse={resolutionCoarse}
            resolution={resolution}
          >
            {t("blog.preview.continue")}
          </ContinueLink>
        )}
      </Comp>
    </Link>
  );
}

export default BlogPostTeaser;
