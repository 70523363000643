import parse from "html-react-parser";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useVersionedContent } from "../context/versionedContent";
import { parseDate } from "../utils/content";
import { fontFamily } from "../utils/theme";

const Comp = styled.div({
  "@media screen": {
    display: "none",
    "@page": {
      marginTop: "2cm",
      marginBottom: "2cm"
    }
  }
});

const PrintWrapper = styled.div({
  paddingLeft: "15mm",
  paddingRight: "5mm",
  fontFamily: fontFamily.printSerif,
  fontSize: "10pt",
  lineHeight: "14pt",
  li: {
    fontWeight: "bold"
  },
  "li li": {
    fontWeight: "normal"
  },
  a: {
    textDecoration: "underline",
    textUnderlinePosition: "auto"
  }
});

const Section = styled.div({});

const H1 = styled.h1({
  fontFamily: fontFamily.printSans,
  fontSize: "12pt",
  fontWeight: "bold",
  marginBottom: "2em"
});
const H2 = styled.h2({
  fontFamily: fontFamily.printSans,
  fontWeight: "normal",
  fontSize: "12pt",
  marginBottom: "2.5em"
});
const H3 = styled.h3({
  fontFamily: fontFamily.printSans,
  fontSize: "10pt",
  fontWeight: "bold",
  marginBottom: "1.5em"
});
const H4 = styled.h4({
  fontFamily: fontFamily.printSerif,
  fontSize: "10pt",
  fontWeight: "bold"
});

// parse is throwing errors if text is not a string
// now with the video-element in faq it is possible that there is content without text
function parseContent(text) {
  if (!text) {
    return null;
  }
  return parse(text);
}

function PrintPage({ prefix }) {
  const { getCompleteContent } = useVersionedContent();
  const { t } = useTranslation();
  const content = getCompleteContent(prefix);

  return (
    <Comp>
      {content && content.sections && (
        <PrintWrapper>
          <H1>
            {t("print.date")} {parseDate(content.date)}
          </H1>
          {(content.sections || []).map(
            ({ headline, sections: subSections, paragraphs }, i) => (
              <Section key={`sec_${i}`}>
                <H2 dangerouslySetInnerHTML={{ __html: headline }} />
                {(paragraphs || []).map(
                  ({ headline: subSubHeadline, text }, k) => (
                    <Section key={`par${k}`}>
                      {subSubHeadline && (
                        <H4
                          dangerouslySetInnerHTML={{ __html: subSubHeadline }}
                        />
                      )}
                      {parseContent(text)}
                    </Section>
                  )
                )}
                {(subSections || []).map(
                  ({ headline: subHeadline, paragraphs: subParagraphs }, j) => (
                    <Section key={`subSec_${j}`}>
                      <H3 dangerouslySetInnerHTML={{ __html: subHeadline }} />
                      {(subParagraphs || []).map(
                        ({ headline: subSubHeadline, text }, k) => (
                          <Section key={`par${k}`}>
                            {subSubHeadline && (
                              <H4
                                dangerouslySetInnerHTML={{
                                  __html: subSubHeadline
                                }}
                              />
                            )}
                            {parseContent(text)}
                          </Section>
                        )
                      )}
                    </Section>
                  )
                )}
              </Section>
            )
          )}
        </PrintWrapper>
      )}
    </Comp>
  );
}

export default PrintPage;
