import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import { fontFamily, fontStyleH2 } from "../utils/theme";

const Comp = styled.h2(({ resolutionCoarse }) => {
  return {
    fontFamily: fontFamily.normal,
    fontWeight: 600,
    marginTop: "0.4em",
    marginBottom: "0.4em",
    ...fontStyleH2[resolutionCoarse]
  };
});

function H2({ children }) {
  const { resolutionCoarse } = useViewerClient();
  return <Comp resolutionCoarse={resolutionCoarse}>{children}</Comp>;
}

export default H2;
