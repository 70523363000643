import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { CONTENT_ANCHOR, scrollToAnchor } from "../utils/content";
import { themeStage, tickerHeight, tickerHeightMobile } from "../utils/theme";
import { useStyles } from "../context/styles";
import { useViewerClient } from "../context/viewerClient";
import ApplyButton from "./ApplyButton";
import ArrowDown from "./ArrowDownButton";
import Container from "./Container";
import DotSeparator from "./DotSeparator";
import Footer from "./Footer";
import Header from "./Header";
import StageContainer from "./StageContainer";
import OpenerStage from "./OpenerStage";
import { smoothVerticalScrolling } from "../utils/scrolling";

const Comp = styled.div({
  position: "relative",
  overflow: "hidden"
});

const ContentPane = styled.div(({ top }) => ({
  paddingTop: `${top}px`,
  position: "relative",
  zIndex: 1,
  "@media print": {
    paddingTop: 0
  }
}));

const ContentBackground = styled.div(
  ({ distanceTop, elementHeight, background, isFixed }) => ({
    background,
    backgroundAttachment: "fixed",
    paddingTop: `${elementHeight}px`,
    top: `${distanceTop}px`,
    position: isFixed ? "fixed" : "absolute",
    width: "100%",
    zIndex: 2
  })
);

const Content = styled.div({
  position: "relative",
  width: "100%",
  zIndex: 4
});

function Page({ children, withStage, noScrollNavigtion }) {
  const { resolution, isGTEDesktop, setFooterBoundingRect } = useViewerClient();
  const footerRef = useRef(null);
  const [showSmallHeader, setShowSmallHeader] = useState(!withStage);
  const [scrollStage, setScrollStage] = useState(false);
  const [whiteStage, setWhiteStage] = useState(false);
  const [isContentBackgroundFixed, setIsContentBackgroundFixed] =
    useState(false);
  const [aboveFooter, setAboveFooter] = useState(true);
  const { gradient } = useStyles();
  const location = useLocation();

  const heights = useMemo(() => {
    const {
      stageHeight: themeStageHeight,
      headerHeightDefault,
      headerHeightSticky
    } = themeStage[resolution];
    const stageHeight = withStage ? themeStageHeight : 0;

    const pause = isGTEDesktop ? 150 : 75;
    const pauseHeader = withStage ? pause : 0;
    const pauseColor = withStage ? pause : 0;
    const pauseScroll = withStage ? pause : 0;
    const animationPauseSpace = pauseHeader + pauseColor + pauseScroll;

    const ticker = isGTEDesktop ? tickerHeight : tickerHeightMobile;
    return {
      stageHeight,
      headerHeightDefault: withStage ? headerHeightDefault : headerHeightSticky,
      headerHeightSticky,
      fixedHeaderHeight: headerHeightSticky + ticker,
      stageTop: headerHeightDefault + ticker,
      whiteStage: stageHeight + pauseColor,
      scrollStage: stageHeight + pauseColor + pauseScroll,
      smallHeader:
        stageHeight +
        headerHeightDefault +
        ticker -
        headerHeightSticky +
        animationPauseSpace,
      contentStart:
        stageHeight * 2 +
        (withStage ? headerHeightDefault : headerHeightSticky) +
        ticker -
        (withStage ? headerHeightSticky : 0) +
        animationPauseSpace,
      animationPauseSpace
    };
  }, [resolution, isGTEDesktop, withStage]);

  const handleScrollDown = useMemo(() => {
    if (window) {
      return () => {
        if (window.pageYOffset >= heights.whiteStage) {
          smoothVerticalScrolling(
            heights.contentStart - heights.headerHeightSticky + 1,
            25
          );
        } else {
          smoothVerticalScrolling(
            heights.contentStart - heights.headerHeightSticky + 1,
            25
          );
        }
      };
    }
    return () => {};
  }, [heights]);

  useLayoutEffect(() => {
    if (window) {
      const handleScroll = () => {
        if (withStage) {
          if (!showSmallHeader) {
            if (window.pageYOffset >= heights.smallHeader) {
              setShowSmallHeader(true);
            }
          } else if (window.pageYOffset < heights.smallHeader) {
            setShowSmallHeader(false);
          }
          if (!whiteStage) {
            if (window.pageYOffset >= heights.whiteStage) {
              setWhiteStage(true);
            }
          } else if (window.pageYOffset < heights.whiteStage) {
            setWhiteStage(false);
          }
          if (!scrollStage) {
            if (window.pageYOffset >= heights.scrollStage) {
              setScrollStage(true);
            }
          } else if (window.pageYOffset < heights.scrollStage) {
            setScrollStage(false);
          }
          if (!isContentBackgroundFixed) {
            if (
              window.pageYOffset >= heights.stageHeight &&
              window.pageYOffset < heights.smallHeader
            ) {
              setIsContentBackgroundFixed(true);
            }
          } else if (
            window.pageYOffset < heights.stageHeight ||
            window.pageYOffset >= heights.smallHeader
          ) {
            setIsContentBackgroundFixed(false);
          }
        }

        if (!isGTEDesktop && footerRef.current) {
          const { y } = footerRef.current.getBoundingClientRect();
          if (y > window.innerHeight) {
            if (!aboveFooter) {
              setAboveFooter(true);
            }
          } else if (aboveFooter) {
            setAboveFooter(false);
          }
        }

        if (footerRef.current) {
          const { y } = footerRef.current.getBoundingClientRect();

          setFooterBoundingRect(y);
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  });

  useEffect(() => {
    if (window) {
      if (location.hash) {
        if (!noScrollNavigtion) {
          const anchor = location.hash.substr(1);
          scrollToAnchor(anchor, heights.fixedHeaderHeight);
        }
      } else {
        window.scrollTo(0, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const cbDistanceTop =
    heights.stageTop +
    (isContentBackgroundFixed ? 0 : heights.stageHeight) +
    (showSmallHeader ? heights.animationPauseSpace : 0);

  return (
    <Comp>
      <Header isFixed isSmall={showSmallHeader} />
      {withStage && (
        <StageContainer
          containerStyles={{
            position: "absolute",
            top: heights.stageTop,
            zIndex: 2
          }}
        >
          <OpenerStage />
        </StageContainer>
      )}
      <ContentPane top={heights.contentStart}>
        {withStage && (
          <>
            <ContentBackground
              distanceTop={cbDistanceTop}
              elementHeight={heights.stageHeight + heights.animationPauseSpace}
              background={gradient}
              isFixed={isContentBackgroundFixed}
            >
              <DotSeparator position="bottom" />
            </ContentBackground>
            <Container
              theme="transparent"
              containerStyles={{
                position: isContentBackgroundFixed ? "fixed" : "absolute",
                top: isContentBackgroundFixed
                  ? heights.stageTop
                  : cbDistanceTop,
                zIndex: 5
              }}
              contentStyles={{
                textAlign: "center",
                padding: "25px"
              }}
            >
              <ArrowDown onClick={handleScrollDown} />
            </Container>
          </>
        )}
        <Content id={CONTENT_ANCHOR}>{children}</Content>
      </ContentPane>
      <Footer fwRef={footerRef} />
      {!isGTEDesktop && (!withStage || scrollStage) && aboveFooter && (
        <ApplyButton resolution={resolution} />
      )}
    </Comp>
  );
}

export default Page;
