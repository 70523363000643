const SVS_B = (eAmt, where) => {
  if (where === "center" || where === "") window.scrollBy(0, eAmt);
  if (where === "top") window.scrollBy(0, eAmt);
};

// custom implementation for smooth vertical scrolling for safari engine support
export const smoothVerticalScrolling = (
  top,
  positionDivider,
  time = 550,
  where = "center"
) => {
  const eAmt = top / positionDivider;
  let curTime = 0;
  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / positionDivider;
  }
};
