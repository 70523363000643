import React from "react";

function ArrowRightIcon({ size = 18, fill }) {
  const pathProps = fill ? { fill } : {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
    >
      <g transform="translate(18 18) rotate(180)">
        <path
          d="M18,7.875H4.275l6.3-6.3L9,0,0,9l9,9,1.575-1.575-6.3-6.3H18Z"
          className="fill"
          {...pathProps}
        />
      </g>
    </svg>
  );
}
export default ArrowRightIcon;
