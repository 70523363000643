import React from "react";
import styled from "styled-components";
import { useViewerClient } from "../context/viewerClient";
import {
  colors,
  controlStyleAccordion,
  themeButtonReset
} from "../utils/theme";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowLeftIcon from "./ArrowLeftIcon";
import ScrollLink from "./ScrollLink";

const Comp = styled.div(() => {
  return {
    position: "absolute",
    height: "100%",
    right: 0,
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "22px"
  };
});

const AccControl = styled.button(
  ({ resolutionCoarse, level, highlight, useFilter }) => {
    let background;
    if (useFilter) {
      background = colors.BLUE_MEDIUM;
    } else if (highlight) {
      background = colors.YELLOW_MEDIUM;
    } else {
      background = "transparent";
    }
    return {
      ...themeButtonReset,
      ...controlStyleAccordion[level][resolutionCoarse],
      background,
      position: "relative",
      width: "22px",
      fontWeight: "normal"
    };
  }
);

const AccordionControl = ({
  isExpanded,
  sectionKey,
  level,
  onToggle,
  onScrollTo,
  highlight,
  useFilter
}) => {
  const { isGTETabletP, resolutionCoarse, headerHeight } = useViewerClient();

  return (
    <Comp
      isMobile={!isGTETabletP}
      resolutionCoarse={resolutionCoarse}
      level={level}
    >
      {isExpanded ? (
        <>
          {level ? (
            <ScrollLink rotateOnFix limitToBottom scrollOffset={headerHeight}>
              {(props) => (
                <AccControl
                  onClick={() =>
                    props.fixTop ? onScrollTo(sectionKey) : onToggle(sectionKey)
                  }
                  resolutionCoarse={resolutionCoarse}
                  level={level}
                  highlight={highlight}
                  useFilter={useFilter}
                  aria-expanded={isExpanded}
                  aria-labelledby={sectionKey}
                >
                  <ArrowDownIcon fill={colors.GREY_DARK} />
                </AccControl>
              )}
            </ScrollLink>
          ) : (
            <AccControl
              onClick={() => onToggle(sectionKey)}
              resolutionCoarse={resolutionCoarse}
              level={level}
              useFilter={useFilter}
              aria-expanded={isExpanded}
              aria-labelledby={sectionKey}
            >
              –
            </AccControl>
          )}
        </>
      ) : (
        <AccControl
          onClick={() => onToggle(sectionKey)}
          resolutionCoarse={resolutionCoarse}
          level={level}
          highlight={highlight}
          useFilter={useFilter}
          aria-expanded={isExpanded}
          aria-labelledby={sectionKey}
        >
          {level ? <ArrowLeftIcon fill={colors.GREY_DARK} /> : <>+</>}
        </AccControl>
      )}
    </Comp>
  );
};

export default AccordionControl;
