const translation = {
  blog: {
    headline: {
      blog: "Neueste Blogbeiträge",
      featured: "Neuigkeiten"
    },
    overview: "Zurück zur Übersicht",
    preview: {
      continue: "weiterlesen"
    },
    share: "Beitrag teilen",
    teaser: {
      continue: "Lesen",
      post: "<strong>Blog / </strong>Blogartikel",
      teaser: "<strong>Blog / </strong>Übersicht"
    },
    videoCaption:
      "Spielen Sie dieses Video direkt auf <strong>{{platform}}</strong> ab. Eventuell werden dort weitere persönliche Daten erhoben."
  },
  common: {
    register: "Anmelden",
    versionDate: "Aktueller Stand: <strong>{{date}}</strong>",
    versionDateOld: "Vergleichsbasis: <strong>{{date}}</strong>"
  },
  contentFilter: {
    compare: {
      cancel: "Vergleich zurücksetzen",
      compareTo: {
        desktop: "mit Stand vom:",
        mobile: "Stand vom:"
      },
      count: "<strong>{{count}}</strong> Fragen geändert",
      intro:
        "<strong>Sie vergleichen</strong> den derzeit aktuellen Stand der FAQ vom <strong>{{date}}</strong> mit dem Stand vom <strong>{{dateOld}}</strong> als Vergleichsbasis. <9>Inhaltliche Veränderungen</9> werden hervorgehoben.",
      lastVersion: " (Letzte Version)",
      placeholder: "Bitte wählen...",
      selectAria: "Auswahlfeld zum Vergleich der Versionshistorie"
    },
    print: {
      action: "Drucken",
      headline: "Drucken",
      intro:
        "Drucken Sie den aktuellen Stand des <strong>FAQ vom {{date}}</strong> im Volltext und ohne Markierungen aus."
    },
    search: {
      action: "Suchen",
      count: "<strong>{{count}}</strong> Suchtreffer",
      clearAria: "Leeren des Eingabefeldes der Suche",
      noMatch: {
        summary: "<strong>0</strong> Suchtreffer",
        intro:
          "<strong>Sie durchsuchen</strong> den derzeit aktuellen Stand der FAQ vom <strong>{{date}}.</strong> Ihr Suchbegriff <5>{{fullSearchString}}</5> wurde nicht im FAQ gefunden. Probieren Sie es mit einem anderen oder allgemeineren Suchbegriff."
      },
      headline: "Durchsuchen",
      inputAria: "Eingabefeld für den Suchbegriff zur Suche",
      intro:
        "<strong>Sie durchsuchen</strong> den derzeit aktuellen Stand der FAQ vom <strong>{{date}}.</strong> Es werden nur die Fragen im FAQ angezeigt, in denen Ihre Suchtreffer auftauchen. <5>Das Ergebnis Ihrer Suche</5> wird in den aufgeklappten Antworten der Fragen hervorgehoben.",
      placeholder: {
        desktop: "Die Fragen und Antworten der FAQ durchsuchen",
        mobile: "FAQ durchsuchen"
      },
      suggestionListAria: "Vorschlagsliste der Suchergebnisse"
    },
    show: {
      headline: "Vergleichen"
    }
  },
  contact: {
    faq: {
      text: "Weitere Details in den FAQ"
    },
    headline: "Noch weitere Fragen?",
    tel: {
      number: "+498006648430",
      text: "Service-Hotline 0800 6648430"
    },
    email: {
      text: "service@sonderfonds-kulturveranstaltungen.de"
    }
  },
  footer: {
    copyright: "© Sonderfonds des Bundes für Kulturveranstaltungen 2021",
    title: "Ein gemeinsames Angebot von"
  },
  header: {
    home: "zur Startseite",
    stageText1: "Wir haben zu",
    stageText2: "Wir machen auf",
    title: "Sonderfonds des Bundes für Kulturveranstaltungen",
    tickerContent:
      "Vorabprüfung von geplanten Verschiebungen oder Absagen Ihrer Tournee jetzt möglich "
  },
  link: {
    apply: {
      text: "Anmelden"
    }
  },
  nav: {
    accessiblity: "Barrierefreiheit",
    aktuelles: "Aktuelles",
    data: "Datenschutz",
    faq: "FAQ",
    legal: "Impressum",
    implementation: "Vollzugshinweise",
    menuClose: "Menü schließen",
    menuOpen: "Menü öffnen",
    overview: "Überblick",
    procedure: "Ablauf",
    subsidy: "Förderung"
  },
  print: {
    date: "FAQs Stand vom:"
  },
  process: {
    after: {
      headline: "Nach der Veranstaltung",
      text1: "Angaben<br/>vervollständigen",
      text2: "Antrag<br/>abschicken"
    },
    pre: {
      headline: "Vor der Veranstaltung",
      text1: "Anmelden",
      text2: "Veranstaltung<br/>registrieren"
    }
  },
  share: {
    facebook: "Auf Facebook teilen",
    instagram: "Auf Instagram teilen",
    copy: "In die Zwischenablage kopieren",
    twitter: "Auf Twitter teilen",
    showTempInfo: {
      mobileP: { copyInfo: "Artikellink kopiert" },
      mobileL: { copyInfo: "Artikellink kopiert" },
      tabletP: { copyInfo: "Artikellink wurde in Zwischenablage kopiert" },
      tabletL: { copyInfo: "Artikellink wurde in Zwischenablage kopiert" },
      desktop: { copyInfo: "Artikellink wurde in Zwischenablage kopiert" },
      wide: { copyInfo: "Artikellink wurde in Zwischenablage kopiert" }
    }
  }
};

export default translation;
