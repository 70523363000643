import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import ColumnsWithAPIContent from "../containers/ColumnsWithAPIContent";
import { useComponents } from "../context/components";
import { useViewerClient } from "../context/viewerClient";
import { parseContent } from "../utils/content";
import { contentSpacing, fontSizeFooter, themeHeadline } from "../utils/theme";
import Abstract from "./Abstract";
import Accordion from "./Accordion";
import ColumnsWrapper from "./ColumnsWrapper";
import H1 from "./H1";
import H2 from "./H2";
import Tabs from "./Tabs";

const Comp = styled.div(({ styles }) => {
  return {
    ...styles
  };
});

const HeadlineWrapper = styled.div(({ theme, styles }) => {
  const themeStyles = (theme && themeHeadline[theme]) || {};
  return {
    ...themeStyles,
    ...styles,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  };
});

const HeadlineInfo = styled.div(({ isMobile }) => ({
  display: isMobile ? "block" : "flex"
}));

const DateWrapper = styled.div(({ resolutionCoarse, isMobile }) => {
  return {
    marginLeft: "3em",
    lineHeight: "2em",
    fontWeight: 300,
    fontSize: fontSizeFooter[resolutionCoarse],
    strong: {
      fontWeight: 600
    },
    "&:nth-child(2)": {
      marginBottom: isMobile ? "10px" : "0px"
    }
  };
});

/**
 *
 * @param {object} section section object
 * @param {string} prefix prefix for id and child elements without anchors
 * @returns
 */
const Section = ({ section, prefix, contentProvider = () => ({}) }) => {
  const { resolution, resolutionCoarse, isGTETabletP } = useViewerClient();
  const { contentLists, fetchContentList, selectedVersions } =
    contentProvider();
  const components = useComponents();
  const {
    pageHeadline,
    abstract,
    headline,
    anchor,
    columns,
    sections = null,
    id,
    type,
    component,
    params = {}
  } = section;

  // const [sectionsContent, setSectionsContent] = useState(sections);

  const contentList = contentLists ? contentLists[id] : null;

  const shouldFetch = fetchContentList && !sections && id && !contentList;

  useEffect(() => {
    // get content from provider
    if (shouldFetch) {
      fetchContentList(id);
    }
  }, [fetchContentList, id, shouldFetch]);

  const sectionsContent =
    sections || (contentList && contentList.sections) || null;
  const displayParams = (contentList && contentList.displayParams) || {};

  const compProps = {};
  if (anchor) {
    compProps.id = anchor;
  }

  const ChildComponent =
    components && component !== undefined && component !== null
      ? components[component]
      : null;

  const useFullWidth = type === "accordion" && !isGTETabletP;
  const outerStyles = useFullWidth
    ? {
        marginLeft: `-${contentSpacing[resolution]}`,
        marginRight: `-${contentSpacing[resolution]}`
      }
    : {};
  const innerStyles = useFullWidth
    ? {
        paddingLeft: contentSpacing[resolution],
        paddingRight: contentSpacing[resolution]
      }
    : {};

  return (
    <Comp resolution={resolution} styles={outerStyles} {...compProps}>
      {pageHeadline && (
        <HeadlineWrapper styles={innerStyles}>
          <H1>{pageHeadline}</H1>
        </HeadlineWrapper>
      )}
      {abstract && (
        <Abstract styles={innerStyles}>{parseContent(abstract)}</Abstract>
      )}
      {headline && (
        <HeadlineWrapper theme="underline" styles={innerStyles}>
          <H2>{headline}</H2>
          {selectedVersions && selectedVersions[id] && (
            <HeadlineInfo isMobile={!isGTETabletP}>
              {selectedVersions[id][0] && (
                <DateWrapper
                  resolutionCoarse={resolutionCoarse}
                  isMobile={!isGTETabletP}
                >
                  <Trans
                    i18nKey="common.versionDate"
                    date={selectedVersions[id][0].label}
                  >
                    ...
                    <strong>{{ date: selectedVersions[id][0].label }}</strong>
                  </Trans>
                </DateWrapper>
              )}
              {selectedVersions[id][1] && (
                <DateWrapper
                  resolutionCoarse={resolutionCoarse}
                  isMobile={!isGTETabletP}
                >
                  <Trans
                    i18nKey="common.versionDateOld"
                    date={selectedVersions[id][1].label}
                  >
                    ...
                    <strong>{{ date: selectedVersions[id][1].label }}</strong>
                  </Trans>
                </DateWrapper>
              )}
            </HeadlineInfo>
          )}
        </HeadlineWrapper>
      )}
      {columns && (
        <>
          {type === "tabs" ? (
            <Tabs tabs={columns} prefix={anchor || prefix} />
          ) : (
            <ColumnsWrapper columns={columns} />
          )}
        </>
      )}
      {sectionsContent && (
        <>
          {type === "accordion" ? (
            <Accordion
              sections={sectionsContent}
              level={0}
              prefix={anchor || prefix}
              {...displayParams}
            />
          ) : (
            <ColumnsWithAPIContent
              sections={sectionsContent}
              prefix={anchor || prefix}
            />
          )}
        </>
      )}
      {ChildComponent && <ChildComponent {...params} />}
    </Comp>
  );
};

export default Section;
